// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// LOADERS
import ProcessLoader from "../preloader/processLoader";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";
import WeightInput from "../../components/htmlElements/weightInput";
// FUNCTIONS
import { resp } from "../../functions/responseHandler";
import * as api from "../../api_service/api";

const Model = {
  vehicle_type: "",
  max_vehicle_capacity: "",
  status: "active",
};

export default function Component(props) {
  // Defaults
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });
  // State
  const [state, setState] = useState(Model);
  const [record_id, setRecordId] = useState();

  useEffect(() => {
    handleUserAction(props);
  }, []);
  // <!--- BEGIN: FUNCTIONS -->
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setState({ ...Model });
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
    }
  }

  function mapData(data) {
    return data;
  }

  function prepareData(data) {
    return data;
  }
  // <!--- END: FUNCTIONS -->

  // <!--- BEGIN: FORM CHANGE FUNCTIONS -->
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // <!--- END: FORM CHANGE FUNCTIONS -->

  // <!--- BEGIN: API CALLS -->
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`master/vehicle_type/${id}`).then(
      async (result) => {
        const data = result.data;
        let mappedData = await mapData(data.data);
        console.log("data:", data);
        setState(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });

    api
      .postService("master/vehicle_type", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });
    api
      .putService(`master/vehicle_type/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }
  // <!--- END: API CALLS -->

  return (
    <div
      key="vehicle_type_modal"
      id="vehicle_type_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            id="vehicle_type-form"
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            <div className="col-span-12">
              <label htmlFor="name" className="form-label required">
                Type Name
              </label>
              <input
                type="text"
                className="form-control"
                id="vehicle_type"
                name="vehicle_type"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.vehicle_type}
                required
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="name" className="form-label required">
                Vehicle Capacity
              </label>
              <WeightInput
                type="number"
                className="form-control"
                id="max_vehicle_capacity"
                name="max_vehicle_capacity"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.max_vehicle_capacity}
                required
              />
            </div>

            <input type="submit" id="save_vehicle_type" className="hidden" />
            <input type="submit" id="update_vehicle_type" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_vehicle_type"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_vehicle_type"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Vehicle Type",
  action: "view",
  onSave() {},
  callBack() {},
};
