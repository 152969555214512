import React, { useState, useEffect } from "react";
import ProcessLoader from "../../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../../api_service/api";

const Model = {
  name: "",
  type: "",
  is_default: true,
  status: "active",
  ref: "",
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState(Model);
  const [record_id, setRecordId] = useState();
  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setDefaults();
        setViewMode(false);
        break;
      case "edit":
        setViewMode(false);
        loadDataById(props.id);
        break;
      case "view":
        setViewMode(true);
        loadDataById(props.id);
        break;
      default:
        setViewMode(true);
    }
  }

  async function setDefaults() {
    let newState = { ...Model };
    newState.type = "liability";
    newState.status = "active";
    setState(newState);
  }

  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`accounts/ledger_group/${id}`).then(
      async (result) => {
        const data = result.data;
        await setState(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };

    api
      .postService("accounts/ledger_group", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .putService(`accounts/ledger_group/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">Ledger Group</h2>
        </Modal.Header>
        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
            id="ledger-group-form"
          >
            <div className="flex flex-col sm:flex-row justify-center items-center mb-3">
              <div className="form-check mr-2">
                <input
                  id="type-liability"
                  className="form-check-input"
                  type="radio"
                  name="type"
                  value="liability"
                  disabled={viewMode}
                  checked={state?.type === "liability"}
                  onChange={changeInput}
                />{" "}
                <label className="form-check-label" htmlFor="type-liability">
                  Liability
                </label>{" "}
              </div>

              <div className="form-check mr-2">
                <input
                  id="type-asset"
                  className="form-check-input"
                  type="radio"
                  name="type"
                  value="asset"
                  disabled={viewMode}
                  checked={state?.type === "asset"}
                  onChange={changeInput}
                />{" "}
                <label className="form-check-label" htmlFor="type-asset">
                  Asset
                </label>{" "}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="name" className="form-label required">
                Ledger Group
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Eg: Captial Account"
                disabled={viewMode}
                onChange={changeInput}
                value={state.name}
                required
              />
            </div>
            <input type="submit" id="save_ledger_group" className="hidden" />
            <input type="submit" id="update_ledger_group" className="hidden" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>

          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary"
              htmlFor="save_ledger_group"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary"
              htmlFor="update_ledger_group"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Ledger Group",
  action: "view",
  callBack() {},
};
