import React, { useState, useEffect } from "react";
import download from "downloadjs";
import { useLocation } from "react-router";

// HTML Components
import UnauthorizedError from "../../components/error/unauthorized";
import ServerError from "../../components/error/error";
import Container from "../../components/container";
import Form from "../../components/form/custom";
import TruckNumberDisplay from "../../components/consignment/truckNumberDisplay";
import TyreNumberDisplay from "../../components/htmlElements/tyreNumberDisplay";
import DriversInList from "../../components/consignment/driversInList";
import ClientsInList from "../../components/consignment/clientsInList";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import {
  getFileName,
  localDate,
  getPrevNthDate,
  isDefined,
} from "../../functions/common";
import queryString from "query-string";

// Templates - Components
import PayablesTemplate from "../../components/reportTemplates/payables";
import ReceivablesTemplate from "../../components/reportTemplates/receivables";
import ReceivablesAdvanceTemplate from "../../components/reportTemplates/receivables_advance";
import TransactionTemplate from "../../components/reportTemplates/transaction";
import TruckRevenueTemplate from "../../components/reportTemplates/truck_revenue";
import TruckRevenueVehicleTemplate from "../../components/reportTemplates/truck_revenue_by_vehicle";
import InvoiceTemplate from "../../components/reportTemplates/invoice";
import OverdueInvoicesTemplate from "../../components/reportTemplates/overdue_invoices";
import ConsignmentReportTemplate from "../../components/reportTemplates/consignment";
import DriverPerformanceTemplate from "../../components/reportTemplates/driver_performance";
// Icons
import { ReactComponent as ReportIcon } from "../../assets/images/svg/report-templates.svg";
import { ReactComponent as ReportDashIcon } from "../../assets/images/svg/report-dash.svg";

// Templates - Components
import VehicleCardTemplate from "../../components/reportTemplates/vehicle_card";
import TripHistoryTemplate from "../../components/reportTemplates/trip_history";
import VehicleFuelHistoryTemplate from "../../components/reportTemplates/vehicle_fuel_history";
import TyreHistoryTemplate from "../../components/reportTemplates/tyreHistory";
import BalanceSheetTemplate from "../../components/reportTemplates/balance_sheet";
import ProfitAndLossTemplate from "../../components/reportTemplates/profit_and_loss";
import { storageServices } from "../../functions/storage";

const templateOptions = {
  // trip_history: {
  //   pageTitle: "Trip History Report",
  //   viewUrl: "report/trip_history",
  //   excelUrl: "report/trip_history/excel",
  //   pdfUrl: "report/trip_history/pdf",
  //   fileName: "Trip History Report",
  //   dateFilter: false,
  //   vehicleFilter: true,
  //   tyreFilter: false,
  // },
  balance_sheet: {
    pageTitle: "Balance Sheet",
    viewUrl: "report/balance_sheet",
    excelUrl: "report/balance_sheet/excel",
    pdfUrl: "report/balance_sheet/pdf",
    dateFilter: false,
    clientFilter: false,
    callUrl: true,
    fileName: "Balance Sheet",
  },
  overdue_invoices: {
    pageTitle: "Overdue Invoices",
    viewUrl: "report/overdue_invoices",
    excelUrl: "report/overdue_invoices/excel",
    pdfUrl: "report/overdue_invoices/pdf",
    dateFilter: false,
    clientFilter: true,
    callUrl: true,
    fileName: "Overdue Invoices",
  },
  receivables_advance: {
    pageTitle: "Client Advance",
    viewUrl: "report/receivables_advance",
    excelUrl: "report/receivables_advance/excel",
    pdfUrl: "report/receivables_advance/pdf",
    dateFilter: true,
    clientFilter: true,
    callUrl: true,
    fileName: "Client Advance",
  },
  driver_performance: {
    pageTitle: "Driver Performance",
    viewUrl: "report/driver_performance",
    excelUrl: "report/driver_performance/excel",
    pdfUrl: "report/driver_performance/pdf",
    dateFilter: true,
    driverFilter: true,
    clientFilter: true,
    callUrl: true,
    fileName: "Driver Performance",
  },
  payables_report: {
    pageTitle: "Payables Report",
    viewUrl: "report/payables",
    excelUrl: "report/payables/excel",
    pdfUrl: "report/payables/pdf",
    dateFilter: false,
    callUrl: true,
    fileName: "Payables Report",
  },
  receivables_report: {
    pageTitle: "Receivables Report",
    fileName: "Receivables Report",
    viewUrl: "report/receivables",
    excelUrl: "report/receivables/excel",
    pdfUrl: "report/receivables/pdf",
    dateFilter: true,
    callUrl: true,
  },
  consignment_report: {
    pageTitle: "Consignment Report",
    fileName: "Consignment Report",
    viewUrl: "report/consignment",
    excelUrl: "report/consignment/excel",
    pdfUrl: "report/consignment/pdf",
    dateFilter: true,
    driverFilter: true,
    clientFilter: true,
    vehicleFilter: true,
    vehicleOwnershipFilter: true,
    callUrl: true,
  },
  invoice_report: {
    pageTitle: "Invoice Report",
    fileName: "Invoice Report",
    viewUrl: "report/invoice",
    excelUrl: "report/invoice/excel",
    pdfUrl: "report/invoice/pdf",
    dateFilter: true,
    clientFilter: true,
    callUrl: true,
  },
  truck_revenue_report: {
    pageTitle: "Truck Revenue Report",
    fileName: "Truck Revenue Report",
    viewUrl: "report/truck_revenue",
    excelUrl: "report/truck_revenue/excel",
    pdfUrl: "report/truck_revenue/pdf",
    dateFilter: true,
    vehicleFilter: true,
    vehicleOwnershipFilter: true,
    callUrl: true,
  },
  truck_revenue_report_by_vehicle: {
    pageTitle: "Truck Revenue Report",
    fileName: "Truck Revenue Report",
    viewUrl: "report/revenue_report_by_vehicle",
    excelUrl: "report/revenue_report_by_vehicle/excel",
    pdfUrl: "report/revenue_report_by_vehicle/pdf",
    dateFilter: true,
    vehicleFilter: true,
    vehicleOwnershipFilter: true,
    callUrl: true,
  },
  transaction_report: {
    pageTitle: "Transactions Report",
    fileName: "Transactions Report",
    viewUrl: "report/transactions",
    excelUrl: "report/transactions/excel",
    pdfUrl: "report/transactions/pdf",
    dateFilter: true,
    callUrl: true,
  },
  vehicle_fuel_history: {
    pageTitle: "Vehicle Fuel History Report",
    viewUrl: "report/vehicle_fuel_history",
    excelUrl: "report/vehicle_card/excel",
    pdfUrl: "report/vehicle_fuel_history/pdf",
    fileName: "Vehicle Fuel History Report",
    dateFilter: true,
    vehicleFilter: true,
    tyreFilter: false,
    callUrl: true,
  },
  tyre_history: {
    pageTitle: "Tyre History Report",
    viewUrl: "report/tyre_history",
    excelUrl: "report/tyre_history/excel",
    pdfUrl: "report/tyre_history/pdf",
    fileName: "Tyre History Report",
    dateFilter: true,
    vehicleFilter: true,
    tyreFilter: true,
    callUrl: true,
  },
  vehicle_card: {
    pageTitle: "Vehicle Card",
    viewUrl: "report/vehicle_card",
    excelUrl: "report/vehicle_card/excel",
    pdfUrl: "report/vehicle_card/pdf",
    fileName: "Vehicle Card",
    dateFilter: true,
    vehicleFilter: true,
    tyreFilter: false,
    callUrl: true,
  },
  profit_and_loss: {
    pageTitle: "Profit and Loss",
    viewUrl: "report/profit_and_loss",
    excelUrl: "report/profit_and_loss/excel",
    pdfUrl: "report/profit_and_loss/pdf",
    fileName: "Profit and Loss",
    dateFilter: true,
    vehicleFilter: false,
    tyreFilter: false,
    callUrl: true,
  },
};

const templatesList = [
  // { name: "Trip History", value: "trip_history" },
  { name: "Balance Sheet", value: "balance_sheet" },
  { name: "Consignment Report", value: "consignment_report" },
  { name: "Invoice Report", value: "invoice_report" },
  { name: "Overdue Invoices", value: "overdue_invoices" },
  { name: "Truck Revenue Report", value: "truck_revenue_report" },
  { name: "Driver Performance", value: "driver_performance" },
  { name: "Vehicle Card", value: "vehicle_card" },
  { name: "Vehicle Fuel History", value: "vehicle_fuel_history" },
  { name: "Tyre History", value: "tyre_history" },

  { name: "Payables Report", value: "payables_report" },
  { name: "Receivables Report", value: "receivables_report" },
  { name: "Client Advance Report", value: "receivables_advance" },
  { name: "Transaction Report", value: "transaction_report" },
  { name: "P&L Report", value: "profit_and_loss" },
];

export default function View(props) {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  // const [queryParamsData, setQueryParamsData] = useState();
  const [templateName, setTemplateName] = useState();
  const [isUnAuthorized, setIsUnAuthorized] = useState(false);
  const [showError, SetShowError] = useState(false);
  const [showNoData, SetShowNoData] = useState(true);
  const [showReport, SetShowReport] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [pageTitle, setPageTitle] = useState();
  //State variables
  const [showOnScreenTemplate, setShowOnScreenTemplate] = useState();
  const location = useLocation();
  var queryParams = queryString.parse(location.search);

  const [state, setState] = useState({
    date_filter: "",
    date_type: "",
    start_date: queryParams?.start_date || "",
    end_date: queryParams?.end_date || "",
    template: "",
    show_report: false,
    download_option: "",
    isCustomReport: false,
    token: "",
    consignment: [],
    vehicle: [],
    tyre: [],
    driver: [],
    supplier: [],
    client: [],
    vehicle_ownership_type: "",
  });
  const [defaultState, setDefaultState] = useState({
    consignment: [],
    vehicle: [],
    tyre: [],
    driver: [],
    supplier: [],
    client: [],
  });

  const [reportData, setReportData] = useState();

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    let template_name = props?.match?.params?.template_name || "";
    //const queryParams = queryString.parse(location.search);
    setTemplateName(template_name);
    matchQueryParams(template_name);
  }, [props?.match?.params?.template_name, location?.search]);

  async function matchQueryParams(template_name) {
    //setQueryParamsData(queryParams);

    let prevState = { ...state };
    // Getting Token
    if (isDefined(queryParams?.token)) {
      prevState["token"] = queryParams?.token;
      setIsUnAuthorized(false);
      storageServices.setCurrentUser({ token: queryParams?.token });
      //SetErrorTemplate(<UnauthorizedError />);
    }
    if (isDefined(queryParams?.start_date)) {
      prevState.start_date = queryParams?.start_date;
    }
    if (isDefined(queryParams?.end_date)) {
      prevState.end_date = queryParams?.end_date;
    }

    if (isDefined(queryParams?.vehicle_ownership_type)) {
      prevState.vehicle_ownership_type = queryParams?.vehicle_ownership_type;
    }

    const array_filters_keys_check_list = [
      "client",
      "supplier",
      "driver",
      "tyre",
      "vehicle",
      "consignment",
    ];
    prevState = await matchFiltersArrayParams(
      array_filters_keys_check_list,
      queryParams,
      prevState
    );
    prevState.download_option = "show_on_screen";

    setState(prevState);
    loadReport(template_name, prevState);
  }

  function matchFiltersArrayParams(keys_check_list, queryParams, stateData) {
    for (var i in keys_check_list) {
      const key = keys_check_list[i];
      if (isDefined(queryParams[key])) {
        try {
          stateData[key] = queryParams[key].split(",");
        } catch (e) {}
      } else {
        stateData[key] = [];
      }
    }
    return stateData;
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // Date Filter Changes
  function changeDateFilter(event) {
    const { name, value } = event.target;

    let prevState = { ...state };
    prevState[name] = value;
    // setState((prevState) => ({ ...prevState, [name]: value }));

    // let prevState = state;
    // prevState.date_filter = value;
    var date = new Date();

    let startDate = date;
    let endDate = date;
    let dateType = "date";

    switch (value) {
      case "today":
        startDate = date;
        endDate = date;
        dateType = "date";
        break;

      case "yesterday":
        startDate = getPrevNthDate(1);
        endDate = getPrevNthDate(1);
        dateType = "date";
        break;

      case "this_week":
        startDate = getPrevNthDate(7);
        endDate = new Date();
        dateType = "date";
        break;

      case "this_month":
        startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        endDate = date;
        dateType = "date";
        break;

      case "last_month":
        startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "date";
        break;

      case "last_3_months":
        startDate = new Date(date.getFullYear(), date.getMonth() - 3, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "month";
        break;

      case "last_6_months":
        startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "month";
        break;

      case "current_financial_year":
        startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "month";
        break;

      // case "last_year":
      //   startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
      //   endDate = new Date(date.getFullYear(), date.getMonth(), 0);
      //   filter = { start_date: startDate, end_date: endDate };
      //   loadHighlights(filter);

      case "custom_date":
        break;

      default:
        break;
    }

    prevState.start_date = startDate;
    prevState.end_date = endDate;
    prevState.date_type = dateType;

    setState(prevState);
  }

  // Input Change
  function changeTypeHeadInputMultiple(selected, name) {
    setState((prevState) => ({ ...prevState, [name]: selected }));
    // let prevState = { ...state };
    // if (selected.length != 0) {
    //   let data = selected[0];
    //   prevState[name] = data;
    // } else {
    //   prevState[name] = "";
    // }
    // setState(prevState);
  }

  function getQuery(stateData, template_name = templateName) {
    let response = {};
    // Checking Date Filter
    response["start_date"] = stateData?.start_date;
    response["end_date"] = stateData?.end_date;

    // if (templateOptions[templateName]?.dateFilter) {
    //   response["start_date"] = state?.start_date;
    //   response["end_date"] = state?.end_date;
    // }
    // Checking Client Filter
    if (templateOptions[template_name]?.clientFilter) {
      const client_id_array = stateData?.client;
      if (client_id_array.length > 0) response["client"] = client_id_array;
    }

    // Checking Vehicle Filter
    if (templateOptions[template_name]?.vehicleFilter) {
      const vehicle_id_array = stateData?.vehicle;

      if (vehicle_id_array.length > 0) response["vehicle"] = vehicle_id_array;
    }

    // Checking Vehicle Ownership Filter
    if (
      templateOptions[template_name]?.vehicleOwnershipFilter &&
      isDefined(stateData?.vehicle_ownership_type)
    ) {
      response["vehicle_ownership_type"] = stateData?.vehicle_ownership_type;
    }

    // Checking Driver Filter
    if (templateOptions[template_name]?.driverFilter) {
      const driver_id_array = stateData?.driver;

      if (driver_id_array.length > 0) response["driver"] = driver_id_array;
    }

    // Checking Supplier Filter
    if (templateOptions[template_name]?.supplierFilter) {
      const supplier_id_array = stateData?.supplier;
      if (supplier_id_array.length > 0)
        response["supplier"] = supplier_id_array;
    }

    // Checking Tyre Filter
    if (templateOptions[template_name]?.tyreFilter) {
      const tyre_id_array = stateData?.tyre;

      if (tyre_id_array.length > 0) response["tyre"] = tyre_id_array;
    }

    return response;
  }

  // Form Actions
  function loadReport(template_name, stateData = state) {
    //event.preventDefault();

    let template_option = templateOptions[template_name];
    let url = template_option?.viewUrl;
    if (template_option?.callUrl) {
      setIsProcessing(true);
      let query = getQuery(stateData, template_name);

      //console.log("query:", query);

      api
        .reportPostService(url, query, stateData?.token)
        .then(async (response) => {
          var data = response?.data?.data;
          console.log("data:", data);
          setReportData(data);
          // handlResponse(data);
          await handleShowOnScreen(template_name, data);
          SetShowReport(true);
          setIsUnAuthorized(false);
          SetShowError(false);
          SetShowNoData(false);
          setIsProcessing(false);
          // RenderTemplate({ render: true, filter: query, data: data });
        })
        .catch((error) => {
          console.log(error);

          if (error?.response?.status === 401) {
            setIsUnAuthorized(true);
            SetShowReport(false);
            SetShowError(false);
            SetShowNoData(false);
            //SetErrorTemplate(<UnauthorizedError />);
          } else if (error?.response?.status === 404) {
            SetShowNoData(true);
            setIsUnAuthorized(false);
            SetShowReport(false);
            SetShowError(false);
            //SetErrorTemplate(<UnauthorizedError />);
          } else {
            SetShowError(true);
            SetShowNoData(false);
            setIsUnAuthorized(false);
            SetShowReport(false);
          }
          setIsProcessing(false);

          //resp.ErrorHandler(error);
        });
    }
  }

  function handlResponse(data) {
    handleShowOnScreen(data);
  }

  async function downloadPdf(event) {
    event.preventDefault();
    setIsProcessing(true);
    let url = templateOptions[templateName]?.pdfUrl;
    let query = getQuery();
    const option = { responseType: "blob" };
    api
      .reportsPostService(url, query, option)
      .then(async (response) => {
        let fileName = getFileName(
          templateOptions[templateName]?.fileName,
          "pdf"
        );
        await downloadFile(response, fileName);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function downloadExcel(event) {
    event.preventDefault();
    setIsProcessing(true);
    let url = templateOptions[templateName]?.excelUrl;
    let query = getQuery();
    const option = { responseType: "blob" };
    api
      .reportsPostService(url, query, option)
      .then(async (response) => {
        let fileName = getFileName(
          templateOptions[templateName]?.fileName,
          "xlsx"
        );
        await downloadFile(response, fileName);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function downloadFile(response, fileName, type) {
    const content = response.headers["content-type"];
    return download(response.data, fileName, content);
  }

  async function handleShowOnScreen(template_name, data) {
    setPageTitle(templateOptions[template_name]?.pageTitle);
    let screenTemplate = await getReportTemplate(template_name, data);
    setShowOnScreenTemplate(screenTemplate);
    setState((prevState) => ({ ...prevState, show_report: true }));
  }

  async function getReportTemplate(template_name, data) {
    let template = <></>;
    switch (template_name) {
      case "profit_and_loss":
        template = (
          <ProfitAndLossTemplate data={data} pdfFunction={downloadPdf} />
        );
        break;
      case "balance_sheet":
        template = (
          <BalanceSheetTemplate data={data} pdfFunction={downloadPdf} />
        );
        break;

      case "tyre_history":
        template = (
          <TyreHistoryTemplate data={data} pdfFunction={downloadPdf} />
        );
        break;

      case "vehicle_fuel_history":
        template = (
          <VehicleFuelHistoryTemplate data={data} pdfFunction={downloadPdf} />
        );
        break;

      case "trip_history":
        template = (
          <TripHistoryTemplate data={data} pdfFunction={downloadPdf} />
        );
        break;

      case "vehicle_card":
        template = (
          <VehicleCardTemplate data={data} pdfFunction={downloadPdf} />
        );
        break;
      case "payables_report":
        template = (
          <PayablesTemplate
            data={data}
            pdfFunction={downloadPdf}
            onRowClick={handlePayablesClick}
          />
        );
        break;

      case "receivables_report":
        template = <ReceivablesTemplate data={data} />;
        break;

      case "receivables_advance":
        template = <ReceivablesAdvanceTemplate data={data} filter={state} />;
        break;

      case "transaction_report":
        template = <TransactionTemplate data={data} filter={state} />;
        break;

      case "consignment_report":
        template = <ConsignmentReportTemplate data={data} filter={state} />;
        break;

      case "driver_performance":
        template = <DriverPerformanceTemplate data={data} filter={state} />;
        break;

      case "invoice_report":
        template = <InvoiceTemplate data={data} filter={state} />;
        break;

      case "overdue_invoices":
        template = <OverdueInvoicesTemplate data={data} filter={state} />;
        break;

      case "truck_revenue_report":
        template = (
          <TruckRevenueTemplate
            data={data}
            filter={state}
            token={queryParams?.token}
          />
        );
        break;

      case "truck_revenue_report_by_vehicle":
        template = (
          <TruckRevenueVehicleTemplate
            data={data}
            filter={state}
            token={queryParams?.token}
            vehicle={queryParams?.vehicle}
          />
        );
        break;

      default:
        break;
    }
    return template;
  }

  async function loadDefaultMetrics(event, key, reload = false) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (defaultState[key].length === 0 || reload) {
      switch (key) {
        case "client":
          url = "client/status/active";
          await fetchDefaultMetrics(url, key);
          break;
        case "consignment":
          url = "consignment/status/active/all";
          await fetchDefaultMetrics(url, key);
          break;
        case "driver":
          url = "driver/valid";
          await fetchDefaultMetrics(url, key);
          break;
        case "vehicle":
          url = "vehicle/all/all";
          await fetchDefaultMetrics(url, key);
          break;

        case "supplier":
          url = "master/supplier/type/vehicle_supplier/active";
          await fetchDefaultMetrics(url, key);
          break;

        case "tyre":
          url = "tyre";
          await fetchDefaultMetrics(url, key);
          break;

        default:
          break;
      }
    }
  }
  function mapDefaultStateData(key, data) {
    // Re-constructing Vehicle Data
    if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
    }
    return data;
  }
  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function handleDateRangeFilter(ranges) {
    console.log(ranges);
    const selection = ranges["selection"];
    let prevState = { ...state };
    prevState["startDate"] = selection["startDate"];
    prevState["endDate"] = selection["endDate"];
    setState(prevState);
  }

  // <!-- BEGIN: CHILD COMPONENTS FUNCTIONS -->
  function fetchApiCustomReport(url) {
    setIsProcessing(true);
    let query = { start_date: state?.start_date, end_date: state?.end_date };
    api
      .reportsPostService(url, query)
      .then((response) => {
        var data = response?.data?.data;

        setReportData(data);
        handlResponse(data);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function handlePayablesClick(data) {
    // Setting Reports as Custom
    // setState((prevState) => ({ ...prevState, isCustomReport: true }));
    // let prevState = {...customReportOptions};
    // prevState['pageTitle'] = `Payables Report - ${data?.supplier?.name}`;
    // prevState['viewUrl'] = `report/payables/supplier/view/${data?.supplier?._id}`;
    // prevState['excelUrl'] = `report/payables/supplier/excel/${data?.supplier?._id}`;
    // prevState['pdfUrl'] = `report/payables/supplier/pdf/${data?.supplier?._id}`;
    // prevState['fileName'] = 'Payables Report';
    // setCustomReportOptions(prevState);
    // let screenTemplate = await getReportTemplate(templateName, data);
    // setShowOnScreenTemplate(screenTemplate);
    // setState((prevState) => ({ ...prevState, show_report: true }));
  }

  // <!-- END: CHILD COMPONENTS FUNCTIONS -->

  /////////// FUNCTIONS DECLARATION ///////////

  const ReportsListItem = (props) => {
    const { id, name, active, text, value, icon, ...rest } = props;
    const activeClassName =
      "cursor-pointer flex text-base w-full items-center p-1 my-2 bg-gray-400 rounded-md text-black";
    const inActiveClassName =
      "cursor-pointer flex text-base w-full items-center p-1 my-2";
    return (
      <a
        id={id}
        href={void 0}
        className={active ? activeClassName : inActiveClassName}
        onClick={(e) => {
          changeTemplate(value);
        }}
      >
        <ReportIcon className="w-4 h-4 mr-2" fill="currentColor" />
        {text}
      </a>
    );
  };

  function changeTemplate(value) {
    setState((prevState) => ({ ...prevState, template: value }));
  }

  const ReportPlaceHolderElement = () => {
    return (
      <div class="h-full flex items-center">
        <div class="mx-auto text-center">
          <div class="w-16 h-16 flex-none image-fit rounded-full overflow-hidden mx-auto">
            <ReportDashIcon
              className="w-16 h-16 bg-gray-500"
              fill="currentColor"
            />
          </div>
          <div class="mt-3">
            <div class="font-medium">Load Reports</div>
            <div class="text-gray-600 mt-1">
              Please select a report to start reporting.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const NoDataHolderElement = () => {
    return (
      <div class="h-full flex items-center">
        <div class="mx-auto text-center">
          <div class="mt-3">
            <div class="font-medium">No data found</div>
          </div>
        </div>
      </div>
    );
  };

  const ConditionalFiltersHTML = (
    <>
      {/* <!--BEGIN : Conditional Fiels --> */}
      {templateOptions[templateName]?.vehicleFilter && (
        <Typeahead
          multiple
          clearButton
          id="vehicle"
          name="vehicle"
          className=""
          key="vehicle"
          filterBy={["registration_number", "vehicleType"]}
          onFocus={(event) => {
            loadDefaultMetrics(event, "vehicle");
          }}
          onChange={(selected) => {
            changeTypeHeadInputMultiple(selected, "vehicle");
          }}
          options={defaultState?.vehicle}
          labelKey={"registration_number"}
          selected={state?.vehicle}
          placeholder="Filter vehicle"
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem option={result} position={index} key={index}>
                  <TruckNumberDisplay
                    data={result}
                    key={"vehicle_" + result?._id}
                    id={"vehicle_" + result?._id}
                    hideOwnership={true}
                  />
                </MenuItem>
              ))}
            </Menu>
          )}
        />
      )}

      {templateOptions[templateName]?.vehicleOwnershipFilter && (
        <select
          className="form-select form-select-sm w-36 text-sm"
          name="vehicle_ownership_type"
          id="vehicle_ownership_type"
          onChange={changeInput}
          value={state?.vehicle_ownership_type}
        >
          <option value="all" id="all-vehicles">
            All Vehicles
          </option>
          <option value="own" id="own-vehicles">
            Own Vehicles
          </option>
          <option value="supplier" id="own-vehicles">
            Supplier Vehicles
          </option>
        </select>
      )}

      {templateOptions[templateName]?.clientFilter && (
        <Typeahead
          multiple
          clearButton
          id="client"
          name="client"
          className=""
          key="client"
          filterBy={["name", "contact_number"]}
          onFocus={(event) => {
            loadDefaultMetrics(event, "client");
          }}
          onChange={(selected) => {
            changeTypeHeadInputMultiple(selected, "client");
          }}
          options={defaultState?.client}
          labelKey={"name"}
          // labelKey={(option) =>
          //   `${option?.registration_number || ""}`
          // }
          selected={state?.client}
          // inputProps={{ required: true }}
          placeholder="Filter Client"
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem option={result} position={index} key={index}>
                  <ClientsInList
                    data={result}
                    id={"client_" + index}
                    display={["phone"]}
                  />
                </MenuItem>
              ))}
            </Menu>
          )}
        />
      )}

      {templateOptions[templateName]?.driverFilter && (
        <Typeahead
          multiple
          clearButton
          id="driver"
          name="driver"
          className=""
          key="driver"
          filterBy={["name", "license_no", "contact_number"]}
          onFocus={(event) => {
            loadDefaultMetrics(event, "driver");
          }}
          onChange={(selected) => {
            changeTypeHeadInputMultiple(selected, "driver");
          }}
          options={defaultState?.driver}
          labelKey={"name"}
          // labelKey={(option) =>
          //   `${option?.registration_number || ""}`
          // }
          selected={state?.driver}
          // inputProps={{ required: true }}
          placeholder="Filter Driver"
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem option={result} position={index} key={index}>
                  <DriversInList
                    id={"driver_" + index}
                    data={result}
                    display={["phone", "license_no", "license_expiry"]}
                  />
                </MenuItem>
              ))}
            </Menu>
          )}
        />
      )}

      {templateOptions[templateName]?.supplierFilter && (
        <Typeahead
          multiple
          clearButton
          className=""
          id="supplier"
          name="supplier"
          key="supplier"
          filterBy={["name", "contact_number"]}
          onFocus={(event) => {
            loadDefaultMetrics(event, "supplier");
          }}
          onChange={(selected) => {
            changeTypeHeadInputMultiple(selected, "supplier");
          }}
          options={defaultState?.supplier}
          labelKey={"name"}
          // labelKey={(option) =>
          //   `${option?.registration_number || ""}`
          // }
          selected={state?.supplier}
          // inputProps={{ required: true }}
          placeholder="Filter Supplier"
        />
      )}

      {templateOptions[templateName]?.tyreFilter && (
        <Typeahead
          multiple
          clearButton
          id="tyre"
          name="tyre"
          className=""
          key="tyre"
          filterBy={["serial_no", "brand", "sub_brand"]}
          onFocus={(event) => {
            loadDefaultMetrics(event, "tyre");
          }}
          onChange={(selected) => {
            changeTypeHeadInputMultiple(selected, "tyre");
          }}
          options={defaultState?.tyre}
          labelKey={"serial_no"}
          selected={state?.tyre}
          placeholder="Filter Tyre"
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem option={result} position={index} key={index}>
                  <TyreNumberDisplay
                    data={result}
                    key={"tyre_" + result?._id}
                    id={"tyre_" + result?._id}
                  />
                </MenuItem>
              ))}
            </Menu>
          )}
        />
      )}
      {/* <!--END : Conditional Fiels --> */}
    </>
  );

  if (isUnAuthorized) {
    return <UnauthorizedError />;
  } else {
    return (
      <div className="h-full w-full">
        {isProcessing ? <ProcessLoader /> : null}
        <div className="chat grid grid-cols-12">
          <div className="col-span-12">
            <div className="chat__box box">
              {/* {!state?.show_report && <ReportPlaceHolderElement />}
               */}

              {showNoData && <NoDataHolderElement />}
              {showError && <ServerError />}
              {showReport && (
                <>
                  {/* <!-- BEGIN: Active Report --> */}
                  <div className="h-full flex flex-col">
                    {/* <!-- BEGIN: Header --> */}
                    <div className="flex flex-col sm:flex-row border-b border-gray-200 dark:border-dark-5 px-5 py-4">
                      <div className="flex items-center">
                        <div className="mr-auto">
                          <div
                            className="font-medium text-base"
                            id="report-title"
                          >
                            {pageTitle}
                          </div>
                          <div
                            className="text-gray-600 text-xs sm:text-sm"
                            id="report-date"
                          >
                            <i
                              class="fa fa-calendar mr-2"
                              aria-hidden="true"
                            ></i>

                            {`${localDate(state?.start_date)} to ${localDate(
                              state?.end_date
                            )}`}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- END: Header --> */}

                    {/* <!-- BEGIN: Content --> */}
                    <div className="overflow-y-scroll scrollbar-hidden flex-1">
                      {showOnScreenTemplate}
                    </div>
                    {/* <!-- END: Content --> */}
                  </div>
                  {/* <!-- END: Active Report --> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
