import React, { useState, useEffect } from "react";
import ProcessLoader from "../../preloader/processLoader";
import PropTypes from "prop-types";

// HTML ELEMENTS
import CurrencyInput from "../../htmlElements/currencyInput";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
// FUNCTIONAL ELEMENTS
import { resp } from "../../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../../api_service/api";
import {
  formatTypeSearch,
  isDefined,
  htmlDate,
} from "../../../functions/common";

const Model = {
  name: "",
  group: "",
  opening_balance: "",
  opening_balance_date: "",
  type: "",
  ref_name: "",
  remarks: "",
  user: "",
  vehicle: "",
  is_default: false,
  status: "active",
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  const [defaultState, setDefaultState] = useState({
    group: [],
    user: [],
    client: [],
    driver: [],
    vehicle: [],
    supplier: [],
  });
  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setDefaults();
        setViewMode(false);
        break;
      case "edit":
        setViewMode(false);
        loadDataById(props.id);
        break;
      case "view":
        setViewMode(true);
        loadDataById(props.id);
        break;
      default:
        setViewMode(true);
    }
  }

  async function setDefaults() {
    let newState = { ...Model };
    newState.opening_balance_date = htmlDate(new Date());
    newState.status = "active";
    setState(newState);
  }

  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`accounts/ledger_master/${id}`).then(
      async (result) => {
        const data = result.data;
        let newData = mapData(data?.data);
        await setState(newData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  function mapData(data) {
    data["opening_balance_date"] = htmlDate(data["opening_balance_date"]);
    return data;
  }

  function prepareData(data) {
    let groupObj = data["group"];
    data["type"] = groupObj?.type;
    data["group"] = groupObj?._id;

    // Checking Vehicle
    if (data?.ref_name === "vehicle" && isDefined(data?.vehicle)) {
      data.vehicle = data?.vehicle?._id;
    } else {
      delete data.vehicle;
    }

    // Checking User
    if (data?.ref_name === "user" && isDefined(data?.user)) {
      data.user = data?.user?._id;
    } else {
      delete data.user;
    }
    return data;
  }
  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });

    console.log("query:", query);

    api
      .postService("accounts/ledger_master", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .putService(`accounts/ledger_master/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  async function changeTypeHeadInputGroup(selected, name) {
    if (selected.length != 0) {
      let selected_data = selected[0];
      setState((prevState) => ({ ...prevState, [name]: selected_data }));
      setState((prevState) => ({
        ...prevState,
        type: selected_data?.type || "",
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
      setState((prevState) => ({
        ...prevState,
        type: "",
      }));
    }
  }

  // FETCH MASTER DATA

  async function loadDefaultMetrics(event, key, reload = false) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (defaultState[key].length === 0 || reload) {
      switch (key) {
        case "group":
          url = "accounts/ledger_group";
          await fetchDefaultMetrics(url, key);
          break;
        case "client":
          url = "client/status/active";
          await fetchDefaultMetrics(url, key);
          break;
        case "user":
          url = "user";
          await fetchDefaultMetrics(url, key);
          break;
        case "vehicle":
          url = "vehicle/all/own";
          await fetchDefaultMetrics(url, key);
          break;
        case "driver":
          url = "driver/valid";
          await fetchDefaultMetrics(url, key);
          break;
        case "supplier":
          url = "master/supplier/type/vehicle_supplier/active";
          await fetchDefaultMetrics(url, key);
          break;

        default:
          break;
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        console.log("data:", data);
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function mapDefaultStateData(key, data) {
    // Re-constructing Vehicle Data
    if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
    }
    return data;
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">Ledger Master</h2>
        </Modal.Header>
        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
            id="ledger-master-form"
            className="grid grid-cols-12 gap-4 gap-y-3"
          >
            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="name" className="form-label required">
                Ledger Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.name}
                required
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="group" className="form-label required">
                Group
              </label>
              <div className="input-group">
                <Typeahead
                  clearButton
                  id="group"
                  name="group"
                  onFocus={(event) => {
                    loadDefaultMetrics(event, "group");
                  }}
                  onChange={(selected) => {
                    changeTypeHeadInputGroup(selected, "group");
                  }}
                  options={defaultState?.group}
                  labelKey={(option) => `${option?.name || ""}`}
                  selected={formatTypeSearch(state?.group || "")}
                  inputProps={{ required: true }}
                  placeholder="Select Group"
                  disabled={viewMode}
                />
              </div>
            </div>

            {/* <!-- BEGIN: FIXED ASSET -->*/}
            <>
              {state?.group?.ref === "fixed_assets" && (
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="ref_name" className="form-label required">
                    Asset Type
                  </label>
                  <select
                    className="form-select"
                    id="ref_name"
                    name="ref_name"
                    placeholder=""
                    disabled={viewMode}
                    onChange={changeInput}
                    value={state?.ref_name}
                    required
                  >
                    <option value=""></option>
                    <option value="vehicle">Vehicle</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              )}

              {state?.ref_name === "vehicle" && (
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="vehicle" className="form-label required">
                    Vehicle
                  </label>
                  <div className="input-group">
                    <Typeahead
                      clearButton
                      id="vehicle"
                      name="vehicle"
                      onFocus={(event) => {
                        loadDefaultMetrics(event, "vehicle");
                      }}
                      onChange={(selected) => {
                        changeTypeHeadInputGroup(selected, "vehicle");
                      }}
                      options={defaultState?.vehicle}
                      labelKey={(option) =>
                        `${option?.registration_number || ""}`
                      }
                      selected={formatTypeSearch(state?.vehicle || "")}
                      inputProps={{ required: true }}
                      placeholder="Select Vehicle"
                      disabled={viewMode}
                    />
                  </div>
                </div>
              )}
            </>
            {/* <!-- END: FIXED ASSET -->*/}

            {/* <!-- BEGIN: LOANS & ADVANCES -->*/}
            <>
              {state?.group?.ref === "loans_and_advances" && (
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="ref_name" className="form-label required">
                    To
                  </label>
                  <select
                    className="form-select"
                    id="ref_name"
                    name="ref_name"
                    placeholder=""
                    disabled={viewMode}
                    onChange={changeInput}
                    value={state?.ref_name}
                    required
                  >
                    <option value=""></option>
                    <option value="user">Employee</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              )}

              {state?.ref_name === "user" && (
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="user" className="form-label required">
                    Employee
                  </label>
                  <div className="input-group">
                    <Typeahead
                      clearButton
                      id="user"
                      name="user"
                      onFocus={(event) => {
                        loadDefaultMetrics(event, "user");
                      }}
                      onChange={(selected) => {
                        changeTypeHeadInputGroup(selected, "user");
                      }}
                      options={defaultState?.user}
                      labelKey={(option) => `${option?.fullname || ""}`}
                      selected={formatTypeSearch(state?.user || "")}
                      inputProps={{ required: true }}
                      placeholder="Select Employee"
                      disabled={viewMode}
                    />
                  </div>
                </div>
              )}
            </>
            {/* <!-- END: LOANS & ADVANCES -->*/}

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="opening_balance" className="form-label">
                Opening Balance
              </label>
              <CurrencyInput
                type="number"
                id="opening_balance"
                name="opening_balance"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state?.opening_balance}
                required={true}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="opening_balance_date" className="form-label">
                Opening Balance Date
              </label>
              <input
                type="date"
                className="form-control"
                id="opening_balance_date"
                name="opening_balance_date"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state?.opening_balance_date}
                max={htmlDate(new Date())}
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="remarks" className="form-label">
                Remarks
              </label>
              <textarea
                type="text"
                id="remarks"
                name="remarks"
                className="form-control"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state?.remarks}
              />
            </div>

            <input type="submit" id="save_ledger_master" className="hidden" />
            <input type="submit" id="update_ledger_master" className="hidden" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>

          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary"
              htmlFor="save_ledger_master"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary"
              htmlFor="update_ledger_master"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Ledger Master",
  action: "view",
  callBack() {},
};
