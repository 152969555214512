import React, { useState, useEffect } from "react";
import { matchData, formatCurrency } from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

//import "bootstrap/dist/css/bootstrap.min.css";

export default function Template(props) {
  const Title = "Balance Sheet";
  const tableRef = React.createRef();
  const { data, onRowClick } = props;
  const [state, setState] = useState({
    liabilities: { total: 0, data: [] },
    assets: { total: 0, data: [] },
  });
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    loadData(data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);
    let newData = await matchData(state, data);
    console.log("newData:", newData);
    setState(newData);
    setIsProcessing(false);
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Liabilities",
        field: "date",
        vertAlign: "middle",
        headerFilter: true,
      },
      {
        title: "Assets",
        field: "driver",
        vertAlign: "middle",
        headerFilter: true,
      },
      {
        title: "Opening Balance",
        field: "opening_balance",
        vertAlign: "middle",
        headerFilter: true,
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(row) {}

  const ChildElement = (props) => {
    let local_data = props?.data;
    let result = local_data?.map((item, index) => {
      return (
        <>
          <tr index={"child-" + index} key={index}>
            <th class="border-b dark:border-dark-5">{item?.particulars}</th>
            <th class="border-b dark:border-dark-5">
              {formatCurrency(item?.opening_balance)}
            </th>
            <th class="border-b dark:border-dark-5">
              {formatCurrency(item?.payable)}
            </th>
            <th class="border-b dark:border-dark-5">
              {formatCurrency(item?.payments)}
            </th>
            <th class="border-b dark:border-dark-5">
              {formatCurrency(item?.receivable)}
            </th>
            <th class="border-b dark:border-dark-5">
              {formatCurrency(item?.receipts)}
            </th>
            <th class="border-b dark:border-dark-5">
              {formatCurrency(item?.closing_balance)}
            </th>
            <th class="border-b dark:border-dark-5"></th>
          </tr>

          {item?.data && item?.data?.length > 0 && (
            <NestedChildElement data={item?.data} />
          )}
        </>
      );
    });

    return result;
  };

  const NestedChildElement = (props) => {
    let result = props?.data?.map((item, index) => {
      return (
        <>
          <tr index={"nested-child-" + index} key={index}>
            <td class="border-b dark:border-dark-5">{item?.particulars}</td>
            <td class="border-b dark:border-dark-5">
              {formatCurrency(item?.opening_balance)}
            </td>
            <th class="border-b dark:border-dark-5">
              {formatCurrency(item?.payable)}
            </th>
            <td class="border-b dark:border-dark-5">
              {formatCurrency(item?.payments)}
            </td>
            <th class="border-b dark:border-dark-5">
              {formatCurrency(item?.receivable)}
            </th>
            <td class="border-b dark:border-dark-5">
              {formatCurrency(item?.receipts)}
            </td>
            <td class="border-b dark:border-dark-5">
              {formatCurrency(item?.closing_balance)}
            </td>
            <td class="border-b dark:border-dark-5"></td>
          </tr>
        </>
      );
    });

    return result;
  };

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        <div className="grid grid-cols-6 gap-5 mt-5 p-3">
          {/* !<--BEGIN: LIABILITY TABLE --> */}
          <div id="liability" className="col-span-6">
            <h3 className="text-lg font-medium mb-2">Liability</h3>
            <table id="liabilities-table" class="table">
              <thead>
                <tr class="bg-theme-1 text-white">
                  <th class="whitespace-nowrap">Particulars</th>
                  <th class="whitespace-nowrap">Opening Balance</th>
                  <th class="whitespace-nowrap">Payable</th>
                  <th class="whitespace-nowrap">Payments</th>
                  <th class="whitespace-nowrap">Receivable</th>
                  <th class="whitespace-nowrap">Receipts</th>
                  <th class="whitespace-nowrap">Closing Balance</th>
                  <th class="whitespace-nowrap">Total</th>
                </tr>
              </thead>

              {state?.liabilities?.data.map((item, index) => {
                return (
                  <>
                    <thead id={"liability-parent-head-" + index}>
                      <tr
                        id={"liability-parent-row-" + index}
                        class="bg-gray-300 dark:bg-dark-1"
                      >
                        <th colSpan={7} class="whitespace-nowrap">
                          {item?.category}
                        </th>
                        <th class="whitespace-nowrap">
                          {formatCurrency(item?.closing_balance)}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ChildElement data={item?.data} />
                    </tbody>
                  </>
                );
              })}

              <tfoot>
                <tr class="">
                  <th colSpan={7} class="whitespace-nowrap text-lg">
                    Total
                  </th>
                  <th class="whitespace-nowrap">
                    {formatCurrency(state?.liabilities?.total)}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          {/* !<--END: LIABILITY TABLE --> */}

          {/* !<--BEGIN: Asset TABLE --> */}
          <div id="asset" className="col-span-6">
            <h3 className="text-lg font-medium mb-2">Asset</h3>
            <table id="asset-table" class="table">
              <thead>
                <tr class="bg-theme-1 text-white">
                  <th class="whitespace-nowrap">Particulars</th>
                  <th class="whitespace-nowrap">Opening Balance</th>
                  <th class="whitespace-nowrap">Payable</th>
                  <th class="whitespace-nowrap">Payments</th>
                  <th class="whitespace-nowrap">Receivable</th>
                  <th class="whitespace-nowrap">Receipts</th>
                  <th class="whitespace-nowrap">Closing Balance</th>
                  <th class="whitespace-nowrap">Total</th>
                </tr>
              </thead>

              {state?.assets?.data.map((item, index) => {
                return (
                  <>
                    <thead id={"asset-parent-head-" + index}>
                      <tr
                        id={"asset-parent-row-" + index}
                        class="bg-gray-300 dark:bg-dark-1"
                      >
                        <th colSpan={7} class="whitespace-nowrap">
                          {item?.category}
                        </th>
                        <th class="whitespace-nowrap">
                          {formatCurrency(item?.closing_balance)}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ChildElement data={item?.data} />
                    </tbody>
                  </>
                );
              })}

              <tfoot>
                <tr class="">
                  <th colSpan={7} class="whitespace-nowrap text-lg">
                    Total
                  </th>
                  <th class="whitespace-nowrap">
                    {formatCurrency(state?.assets?.total)}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          {/* !<--END: LIABILITY TABLE --> */}
        </div>
      </div>
    </>
  );
}
