import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default function Component(props) {
  // id should be unique and mandatory
  const {
    className,
    type,
    id,
    name,
    onClick,
    phoneNumber,
    tooltipText,
    size,
    ...rest
  } = props;

  return (
    <div className={className}>
      <a
        className="cursor-pointer"
        data-tip
        data-for={id}
        href={`tel:${phoneNumber}`}
      >
        <div
          className={"btn btn-success-soft " + (size === "sm" ? "btn-sm" : "")}
        >
          <i class="fa fa-phone" aria-hidden="true"></i>
          {props?.children && <span className="ml-2">{props?.children}</span>}
        </div>
      </a>

      <ReactTooltip id={id} aria-haspopup="true" role="call">
        {tooltipText}
      </ReactTooltip>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  phoneNumber: PropTypes.string,
  tooltipText: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  size: "",
  className: "",
  type: "",
  id: "call-button",
  name: "",
  text: "",
  phoneNumber: "",
  tooltipText: "",
  onClick() {},
};
