import React, { useState, useEffect } from "react";
import FeatherIcons from "../../assets/js/feather";
import PropTypes from "prop-types";
import "react-tabulator/lib/styles.css"; // default theme
// import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";

import { ReactTabulator } from "react-tabulator";
export default function CustomDataTable(props) {
  const tableRef = React.createRef();
  const {
    header,
    content,
    onRowClick,
    onRowDblClick,
    options,
    layout,
    responsiveLayout,
    className,
    printTable,
    printHeader,
    printFooter,
    exportTable,
    search,
    filter,
    filterFields,
    downloadFileName,
    title,
    selectableRows,
    selectableRowsHighlight,
    onSelectedRowsChange,
    groupBy,
    footerFormatter
  } = props;

  useEffect(() => {
    FeatherIcons();
  }, []);

  return (
    <>
      {/* <!-- BEGIN: HTML Table Data --> */}
      <div className="box mt-2">
        <div className="overflow-x-auto overflow-y-auto scrollbar-hidden">
          {/*  <!-- BEGIN: Data Table -->  */}
          <ReactTabulator
            ref={tableRef}
            columns={header}
            data={content}
            options={{
              //Selectable Rows
              selectable: selectableRows,
              selectableCheck: selectableRowsHighlight,
              // selectableCheck: true,
              rowSelectionChanged: onSelectedRowsChange,

              movableRows: false,
              movableColumns: true,
              placeholder: "No records found",
              layout: layout,
              groupBy: groupBy,
              // layout:"fitDataFill",
              responsiveLayout: responsiveLayout,
              pagination: "local",
              paginationSize: 10,
              paginationSizeSelector: [10, 20, 30, 40],
              downloadDataFormatter: (data) => data,
              downloadReady: (fileContents, blob) => blob,
              printAsHtml: true,
              printCopyStyle: true,
              printStyled: true,
              printFooter: printFooter,
              printHeader: `<h3>${printHeader}</h3>`,
              footerFormatter:footerFormatter,
              height: '100%',
            }}
            rowClick={onRowClick}
            rowDblClick={onRowDblClick}
            className={className}
            tooltips={true}
            placeholder={"No data found."}
          />

          {/*  <!-- END: Data Table -->  */}
        </div>
      </div>
      {/* <!-- END: HTML Table Data --> */}
    </>
  );
}

CustomDataTable.propTypes = {
  header: PropTypes.array,
  content: PropTypes.array,
  filter: PropTypes.bool,
  printTable: PropTypes.bool,
  downloadFileName: PropTypes.string,
  printHeader: PropTypes.string,
  printFooter: PropTypes.string,
  exportTable: PropTypes.bool,
  filterFields: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  layout: PropTypes.string,
  groupBy: PropTypes.string,
  responsiveLayout: PropTypes.string,
  options: PropTypes.object,
  onRowClick: PropTypes.func,
  onRowDblClick: PropTypes.func,
  tableRef: PropTypes.any,
  selectableRows: PropTypes.bool,
  selectableRowsHighlight: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  footerFormatter: PropTypes.func,
};

CustomDataTable.defaultProps = {
  header: [],
  content: [],
  filter: true,
  search: true,
  printTable: false,
  selectableRows: false,
  exportTable: false,
  filterFields: [],
  title: "",
  printHeader: "",
  groupBy: "",
  downloadFileName: "data",
  layout: "fitColumns", // fitColumns / fitDataFill
  responsiveLayout: "collapse",
  printFooter: "",
  className: "",
  onRowClick() {},
  onRowDblClick() {},
  selectableRowsHighlight() {
    return true;
  },
  onSelectedRowsChange() {},
  footerFormatter() {},
  tableRef: React.createRef(),
};
