import React, { useState, useEffect } from "react";
// Components
// import Error from "../../../components/error/error";
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import FormMaster from "../../../components/master/vehicle_type";
import AddNewButton from "../../../components/htmlElements/addNewButton";
import StatusBadge from "../../../components/pageElements/statusBadge";
import ActionButtons from "../../../components/pageElements/actionButtons";
import StageTab from "../../../components/tabs/stageTab";
// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import { htmlToString, navToPage } from "../../../functions/common";
// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";

import { reactFormatter } from "react-tabulator";

export default function Grid() {
  const ModuleName = "Vehicle Type";
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));
  // Data

  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    setIsLoaded(true);
    loadData();
  }, []);

  // <!--- BEGIN: API CALLS -->

  // Fetching Grid Data From API
  async function loadData() {
    setIsProcessing(true);
    api.getService("master/vehicle_type").then(
      async (result) => {
        const data = result.data;
        console.log("data:", data);
        let tableData = await mapTableData(data.data);
        setTableData(tableData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // <!--- END: API CALLS -->

  // <!--- BEGIN: FUNCTIONS -->
  // Mapping Grid Data From API
  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Vehicle Type",
        field: "vehicle_type",
        responsive: 0,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Capacity",
        field: "max_vehicle_capacity",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Status",
        field: "status",
        headerSort: false,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",

        formatter: function (cell) {
          return htmlToString(<StatusBadge text={cell?.getValue()} />);
        },
      },

      {
        title: "Actions",
        field: "",
        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(
          <ActionButtons
            editButton={{
              show: true,
              onClick: (cellData) => {
                editRecord(cellData);
              },
            }}
            suspendButton={{
              show: true,
              // show: status == "active" ? true : false,
              // disabled: cell.getData()?.default,

              onClick: (cellData) => {
                deleteRecord(cellData);
              },
            }}
            activateButton={{
              show: false,
              // show: status == "inactive" ? true : false,
              onClick: (cellData) => {
                activateRecord(cellData);
              },
            }}
          />
        ),
        cellClick: function (e) {
          console.log("cell click");
          // e.preventDefault();
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [
        { label: "Vehicle Type", value: "vehicle_type" },
        { label: "Capacity", value: "max_vehicle_capacity" },
      ],
    };
    return response;
  }

  // <!--- END: FUNCTIONS -->

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData();
  }
  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add Type`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(data, row) {
    let newState = {
      show: true,
      title: `View Type`,
      action: "view",
      id: data?._row?.data?._id,
    };
    setModal(newState);
  }
  // Edit Record
  async function editRecord(data) {
    let newState = {
      show: true,
      title: `Edit Type`,
      action: "edit",
      id: data?._id,
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to suspend this vehicle type?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }
  // RE-ACTIVATION
  function activateRecord(data) {
    alert.confirmActivation({
      text: "Are you sure want to activate this vehicle type?",
      onConfirm: function () {
        onActivate(data);
      },
    });
  }
  ////// COMMON FUNCTIONS //////

  function onActivate(data) {
    setIsProcessing(true);
    var query = {
      status: "active",
    };
    api
      .putService(`master/vehicle_type/${data.row._id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`master/vehicle_type/${data.row._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  return (
    <>
      {/* <!-- BEGIN: Modal --> */}
      {modal.show && (
        <FormMaster
          show={modal.show}
          action={modal.action}
          title={modal.title}
          onHide={closeModal}
          callBack={reloadData}
          id={modal.id}
        />
      )}
      {/* <!-- END: Modal --> */}
      <Container>
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title className="text-lg font-medium">
            Vehicle Type
          </Container.Title>

          {/* <!-- BEGIN: Stage Tabs --> */}
          <div className="ml-auto flex">
            <StageTab>
              <StageTab.Tab
                id="vehicle-stage-vehicle"
                active={false}
                onClick={(e) => navToPage("/admin#/master/vehicle")}
                width={"w-32"}
              >
                Vehicle
              </StageTab.Tab>{" "}
              <StageTab.Tab
                id="vehicle-stage-vehicle_type"
                active={true}
                onClick={(e) => navToPage("/admin#/master/vehicle_type")}
                width={"w-32"}
              >
                Vehicle Type
              </StageTab.Tab>
            </StageTab>
          </div>
          {/* <!-- END: Stage Tabs --> */}

          <Container.HeaderActions>
            {" "}
            <AddNewButton id="add-master" onClick={addRecord} />
          </Container.HeaderActions>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/*  <!-- BEGIN: Data Table -->  */}
        {isProcessing && <ProcessLoader />}

        <CustomDataTable
          header={tableData.header}
          content={tableData.content}
          onRowClick={(e, row) => {
            viewRecord(row);
          }}
          filter={true}
          filterFields={tableData.filterFields}
        />

        {/*  <!-- END: Data Table -->  */}
      </Container>
    </>
  );
}
