import React, { Component } from "react";

class Error extends Component {
  render() {
    return (
      <div className="h-screen">
        <div className="intro-y box py-10 sm:py-20 mt-5  w-3/4  text-center my-auto mx-auto ">
          <div className="">
            <h3 className="text-uppercase text-lg mb-5">Unauthorized! </h3>
            <p className="text-gray-500 mt-30 mb-30">
              You are not authorzied to access this page.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Error;
