import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Loaders
import ProcessLoader from "../preloader/processLoader";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeAhAddNewButton from "../htmlElements/typeAhAddNewButton";
import CurrencyInput from "../htmlElements/currencyInput";
import EditButton from "../htmlElements/editButton";

// External Components
import LedgerMasterForm from "../master/accounts/ledger_master";
import CashMasterForm from "../master/accounts/cash";
import BankMasterForm from "../master/accounts/bank";

// Functions
import {
  isDateExpired,
  isDefined,
  htmlDate,
  matchData,
  formatTypeSearch,
} from "../../functions/common";
import { resp } from "../../functions/responseHandler";
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";

const Model = {
  date: "",
  transaction_type: "",
  transaction_context: "ledger",
  payment_mode: "",
  payment_from_type: "",
  payment_to_type: "",
  payment_reference_number: "",
  transaction_amount: "",
  remarks: "",
  ledger_master: "",
  cash: "",
  bank: "",
  status: "active",
};

const apiEndpoint = "accounts/transactions";
export default function Component(props) {
  const [pageTitle, setPageTitle] = useState("Voucher");
  const [isProcessing, setIsProcessing] = useState(false);

  const [localUser, setLocalUser] = useState();
  const [mode, setMode] = useState({ view: false, edit: false, create: false });
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [defaultState, setDefaultState] = useState({
    ledger_master: [],
    bank: [],
    cash: [],
  });
  const [record_id, setRecordId] = useState();

  const [LedgerMasterModal, setLedgerMasterModal] = useState({
    show: false,
    title: "Add Ledger Master",
    action: "create",
    id: "",
  });

  const [cashMasterModal, setCashMasterModal] = useState({
    show: false,
    title: "Add Cash Book",
    action: "create",
    id: "",
  });

  const [bankMasterModal, setBankMasterModal] = useState({
    show: false,
    title: "Add Bank Account",
    action: "create",
    id: "",
  });

  // <!-- BEGIN: Ledger Master Modal -- >
  const openLedgerMasterModal = () =>
    setLedgerMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeLedgerMasterModal = () =>
    setLedgerMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadLedgerMasterData(data) {
    closeLedgerMasterModal();
    let event = {};
    loadDefaultMetrics(event, "ledger_master", true);
  }
  // <!-- END: Ledger Master  Modal -- >

  // <!-- BEGIN: Cash Master Modal -- >
  const openCashMasterModal = () =>
    setCashMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeCashMasterModal = () =>
    setCashMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadCashMasterData(data) {
    closeCashMasterModal();
    let event = {};
    loadDefaultMetrics(event, "cash", true);
  }
  // <!-- END: Cash Master Modal -- >

  // <!-- BEGIN: Bank Master Modal -- >
  const openBankMasterModal = () =>
    setBankMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeBankMasterModal = () =>
    setBankMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadBankMasterData(data) {
    closeBankMasterModal();
    let event = {};
    loadDefaultMetrics(event, "bank", true);
  }
  // <!-- END: Bank Master Modal -- >

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    handleUserAction();
  }, []);
  async function handleUserAction() {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setDefaults(props);
        setPageTitle("Create Voucher");
        setMode((prevState) => ({ ...prevState, create: true }));
        break;
      case "edit":
        setPageTitle("Edit Voucher");
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setPageTitle("View Voucher");
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  async function setDefaults(props) {
    const transaction_type = props?.transaction_type;

    console.log("transaction_type:", transaction_type);

    let newState = { ...Model };
    newState.date = htmlDate(new Date());
    newState.transaction_context = "ledger";
    newState.payment_mode = "cash";
    newState.status = "active";
    newState.transaction_type = transaction_type;
    if (transaction_type === "payable") newState["payment_to_type"] = "ledger";
    if (transaction_type === "receivable")
      newState["payment_from_type"] = "ledger";

    setState(newState);
  }

  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`${apiEndpoint}/${id}`).then(
      async (result) => {
        const data = result.data;
        console.log("data:", data);
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  async function mapData(data) {
    let matchedData = await matchData(state, data);
    matchedData["date"] = htmlDate(matchedData["date"]);
    setState(matchedData);
  }

  function prepareData() {
    let data = { ...state };

    // Payment Mode
    // Cash
    if (data.payment_mode === "cash" && isDefined(data?.cash)) {
      data["payment_cash"] = data?.cash?._id;
    }
    // Bank
    if (data.payment_mode === "bank" && isDefined(data?.bank)) {
      data["payment_bank"] = data?.bank?._id;
    }

    // Ledger Master
    if (isDefined(data?.ledger_master)) {
      data["ledger_master"] = data?.ledger_master?._id;
    }

    const transaction_type = data?.transaction_type;

    console.log("transaction_type: pas", transaction_type);

    if (transaction_type === "payable")
      data["payment_from_type"] = data["payment_mode"];
    if (transaction_type === "receivable")
      data["payment_to_type"] = data["payment_mode"];

    return data;
  }
  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    // setIsProcessing(true);
    var query = await prepareData();
    console.log("query:", query);

    api
      .postService(apiEndpoint, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = await prepareData();
    api
      .putService(`${apiEndpoint}/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  async function loadDefaultMetrics(event, key, reload = false) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (defaultState[key].length === 0 || reload) {
      switch (key) {
        case "cash":
          url = "master/cash";
          await fetchDefaultMetrics(url, key);
          break;

        case "bank":
          url = "master/bank";
          await fetchDefaultMetrics(url, key);
          break;

        case "ledger_master":
          url = "accounts/ledger_master";
          await fetchDefaultMetrics(url, key);
          break;

        default:
          break;
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        console.log("data:", response?.data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: response?.data?.data,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function changeTypeHeadInput(selected, name) {
    if (selected.length !== 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function enableEdit() {
    setUserAction("edit");
    setPageTitle("Edit Ledger");
    setMode((prevState) => ({ ...prevState, view: false }));
    setMode((prevState) => ({ ...prevState, edit: true }));
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {/* <!--BEGIN: External Components -->*/}

      {!mode.view && LedgerMasterModal.show && (
        <LedgerMasterForm
          show={LedgerMasterModal.show}
          action={LedgerMasterModal.action}
          title={LedgerMasterModal.title}
          onHide={closeLedgerMasterModal}
          callBack={reloadLedgerMasterData}
        />
      )}

      {!mode.view && cashMasterModal.show && (
        <CashMasterForm
          show={cashMasterModal.show}
          action={cashMasterModal.action}
          title={cashMasterModal.title}
          onHide={closeCashMasterModal}
          callBack={reloadCashMasterData}
        />
      )}

      {!mode.view && bankMasterModal.show && (
        <BankMasterForm
          show={bankMasterModal.show}
          action={bankMasterModal.action}
          title={bankMasterModal.title}
          onHide={closeBankMasterModal}
          callBack={reloadBankMasterData}
        />
      )}

      {/* <!--END: External Components -->*/}
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">
            {pageTitle}
            {mode?.view && <EditButton onClick={enableEdit} />}
          </h2>
        </Modal.Header>
        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
            id="income-form"
            className="grid grid-cols-12 gap-4 gap-y-3"
          >
            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="date" className="form-label required">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.date}
                required
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="ledger_master" className="form-label required">
                Ledger
              </label>
              <Typeahead
                id="ledger_master"
                onFocus={(event) => {
                  loadDefaultMetrics(event, "ledger_master");
                }}
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "ledger_master");
                }}
                options={defaultState?.ledger_master}
                labelKey={(option) => `${option?.name}`}
                selected={formatTypeSearch(state?.ledger_master)}
                inputProps={{ required: true }}
                placeholder="Select Ledger"
                disabled={mode.view}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    <TypeAhAddNewButton
                      onClick={openLedgerMasterModal}
                    ></TypeAhAddNewButton>
                    {results.map((result, index) => (
                      <MenuItem option={result} position={index} key={index}>
                        {result?.name}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
            </div>

            {/* <!-- BEGIN: Payment Mode --> */}
            <div className="col-span-12">
              <div className="mt-3">
                <label>
                  {" "}
                  {state?.transaction_type === "receivable"
                    ? "Received Via "
                    : "Payment Mode"}
                </label>
                <div className="flex flex-col sm:flex-row mt-2">
                  <div className="form-check mr-2">
                    <input
                      id="payment_mode_cash"
                      className="form-check-input"
                      type="radio"
                      name="payment_mode"
                      value="cash"
                      disabled={mode?.view}
                      checked={state?.payment_mode === "cash"}
                      onChange={changeInput}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="payment_mode_cash"
                    >
                      Cash
                    </label>{" "}
                  </div>

                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="payment_mode_bank"
                      className="form-check-input"
                      type="radio"
                      name="payment_mode"
                      disabled={mode?.view}
                      value="bank"
                      checked={state?.payment_mode === "bank"}
                      onChange={changeInput}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="payment_mode_bank"
                    >
                      Bank
                    </label>{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END: Payment Mode --> */}

            {state?.payment_mode === "cash" && (
              <div className="col-span-12">
                <label htmlFor="cash" className="form-label">
                  Cash Book
                </label>
                <Typeahead
                  id="cash"
                  onFocus={(event) => {
                    loadDefaultMetrics(event, "cash");
                  }}
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "cash");
                  }}
                  options={defaultState?.cash}
                  labelKey={(option) => `${option?.name}`}
                  selected={formatTypeSearch(state?.cash)}
                  placeholder="Select Cash Book..."
                  disabled={mode.view}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      <TypeAhAddNewButton
                        onClick={openCashMasterModal}
                      ></TypeAhAddNewButton>
                      {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                          {result?.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                />
              </div>
            )}

            {state?.payment_mode === "bank" && (
              <div className="col-span-12">
                <label htmlFor="bank_master" className="form-label">
                  Bank Account
                </label>
                <Typeahead
                  id="bank_master"
                  onFocus={(event) => {
                    loadDefaultMetrics(event, "bank");
                  }}
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "bank");
                  }}
                  options={defaultState?.bank}
                  labelKey={(option) => `${option?.name}`}
                  selected={formatTypeSearch(state?.bank)}
                  placeholder="Select Bank A/C..."
                  disabled={mode.view}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      <TypeAhAddNewButton
                        onClick={openBankMasterModal}
                      ></TypeAhAddNewButton>
                      {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                          {result?.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                />
              </div>
            )}

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="transaction_amount" className="form-label">
                Amount
              </label>
              <CurrencyInput
                type="number"
                className="form-control"
                id="transaction_amount"
                name="transaction_amount"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.transaction_amount}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="payment_reference_number" className="form-label">
                {state?.payment_mode === "cash"
                  ? "Voucher No."
                  : state.payment_mode === "bank"
                  ? "Transaction Id"
                  : "Reference No."}
              </label>
              <input
                type="text"
                className="form-control"
                id="payment_reference_number"
                name="payment_reference_number"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.payment_reference_number}
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="remarks" className="form-label">
                Remarks
              </label>
              <textarea
                type="text"
                className="form-control"
                id="remarks"
                name="remarks"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.remarks}
              />
            </div>

            <input type="submit" id="save_voucher_form" className="hidden" />
            <input type="submit" id="update_voucher_form" className="hidden" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>

          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary"
              htmlFor="save_voucher_form"
            >
              Create
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary"
              htmlFor="update_voucher_form"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Voucher",
  action: "view",
  callBack() {},
};
