import React, { useState, useEffect } from "react";
import {
  formatCurrency,
  localDate,
  trimConsNum,
  getFileName,
} from "../../functions/common";
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import ConsStatusBadge from "../../components/consignment/statusBadge";
import BackButton from "../pageElements/backButton";
import { storageServices } from "../../functions/storage";
import Card from "../card/custom";

import { ReactComponent as OwnTruckIcon } from "../../assets/images/svg/truck.svg";
import { ReactComponent as SupplierTruckIcon } from "../../assets/images/svg/supplier_truck.svg";

import { useLocation } from "react-router-dom";
import { reactFormatter } from "react-tabulator";
import queryString from "query-string";
import download from "downloadjs";

export default function Template(props) {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const Title = "Consignments";
  const consTableRef = React.createRef();
  const expenseTableRef = React.createRef();
  const incomeTableRef = React.createRef();
  const { data, vehicle } = props;
  const [state, setState] = useState({
    own_vehicle_profit: 0,
    supplier_vehicle_profit: 0,
    registration_number: "",
    vehicle_type: "",
    ownership_type: "",
    consignments: [],
    other_expenses: [],
    other_incomes: [],
    vehicle_id: "",
    total_freight_amount: 0,
    total_freight_addition: 0,
    total_freight_profit: 0,
    total_freight_deduction: 0,
    total_other_expenses: 0,
    total_other_incomes: 0,
    total_income: 0,
    total_expense: 0,
    profit: 0,
    consignments_count: 0,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [consTableData, setConsTableData] = useState([]);
  const [expenseTableData, setExpenseTableData] = useState([]);
  const [incomeTableData, setIncomeTableData] = useState([]);

  useEffect(() => {
    loadData(data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);
    let mappedData = await mapData(data);
    let cons_table_data = await mapConsTableData(
      mappedData?.consignments || []
    );
    setConsTableData(cons_table_data);

    //Expense
    let expense_table_data = await mapExpenseTableData(
      mappedData?.other_expenses || []
    );
    setExpenseTableData(expense_table_data);
    //Income
    let income_table_data = await mapIncomeTableData(
      mappedData?.other_incomes || []
    );
    setIncomeTableData(income_table_data);

    setIsProcessing(false);
  }

  function mapData(data) {
    setState(data);
    return data;
  }

  const footerFormatter = (cell) => {
    const value = cell.getValue();
    return `Rs. ${value}`;
  };

  function mapConsTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "consignment_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
        width: 90,
        sorter: "date",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Cons. No.",
        field: "consignment_id",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 75,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        // formatter: reactFormatter(<ConsNumberCell onClick={editRecord}/>),

        formatter: function (cell) {
          return `<div class="text-theme-1 font-medium">${trimConsNum(
            cell?.getValue()
          )}</div>
         `;
        },
      },
      {
        title: "Client",
        field: "client",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 150,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        // formatter: "textarea",
      },
      {
        title: "Origin",
        field: "origin",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        // width: 150,
        // formatter: "textarea",
      },
      {
        title: "Destination",
        field: "destination",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        // width: 150,
        // formatter: "textarea",
      },
      {
        title: "Status",
        field: "status",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        download: false,
        formatter: reactFormatter(<ConsStatusBadge module="tabulator" />),
        reponsive: 2,
        cellClick: function (e) {
          e.stopPropagation();
        },

        footerContent: "Total",
      },
      {
        title: "Freight Amount",
        field: "freight_amount",
        headerSort: true,
        vertAlign: "middle",
        bottomCalc: "sum",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Charges",
        field: "freight_addition",
        headerSort: true,
        vertAlign: "middle",
        bottomCalc: "sum",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Expenses",
        field: "freight_deduction",
        headerSort: true,
        vertAlign: "middle",
        bottomCalc: "sum",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Profit",
        field: "freight_profit",
        headerSort: true,
        vertAlign: "middle",
        bottomCalc: "sum",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function mapExpenseTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Particulars",
        field: "particulars",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Amount",
        field: "amount",
        headerSort: true,
        vertAlign: "middle",
        bottomCalc: "sum",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function mapIncomeTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Particulars",
        field: "particulars",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Amount",
        field: "amount",
        headerSort: true,
        vertAlign: "middle",
        bottomCalc: "sum",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(row) {}

  async function downloadPdf(event) {
    event.preventDefault();
    setIsProcessing(true);
    let url = "report/revenue_report_by_vehicle/pdf";
    let query = queryParams;
    const option = { responseType: "blob" };
    api
      .reportsPostService(url, query, option)
      .then(async (response) => {
        let fileName = getFileName("Truck Revenue Report", "pdf");
        await downloadFile(response, fileName);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function downloadExcel(event) {
    event.preventDefault();
    setIsProcessing(true);
    let url = "report/revenue_report_by_vehicle/excel";
    let query = queryParams;
    const option = { responseType: "blob" };
    api
      .reportsPostService(url, query, option)
      .then(async (response) => {
        let fileName = getFileName("Truck Revenue Report", "xlsx");
        await downloadFile(response, fileName);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function downloadFile(response, fileName, type) {
    const content = response.headers["content-type"];
    return download(response.data, fileName, content);
  }

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        <div className="w-full py-2 flex justify-start items-center mb-3">
          <BackButton />
          <span className="text-lg ml-1">{state?.registration_number}</span>
          <span className="ml-1 text-gray-600">• {state?.vehicle_type}</span>
          <div className="ml-auto flex gap-2" id="download_buttons">
            {/* <!-- BEGIN: Excel Button--> */}
            <button class="btn btn-success-soft w-32" onClick={downloadExcel}>
              <i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i>
              Excel{" "}
            </button>
            {/* <!-- END: Excel Button--> */}

            {/* <!-- BEGIN: Pdf Button--> */}
            <button class="btn btn-danger-soft w-32" onClick={downloadPdf}>
              <i class="fa fa-file-pdf-o mr-1" aria-hidden="true"></i>
              Pdf{" "}
            </button>
            {/* <!-- END: Excel Button--> */}
          </div>
        </div>
        {/* <!-- BEGIN: Sum Values --> */}
        <div id="sum_values" className="w-full flex justify-end space-x-4">
          {/* <!-- BEGIN: Total Trips--> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-gray-200 w-1/2 md:w-1/4">
            <div class="ml-4 mr-auto">
              <div class="font-medium">Total Consignments</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {state?.consignments_count || 0}
            </div>
          </div>
          {/* <!-- END: Total Trips --> */}
          {/* <!-- BEGIN: Total Income--> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-gray-200 w-1/2 md:w-1/4">
            <div class="ml-4 mr-auto">
              <div class="font-medium">Total Income</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.total_income)}
            </div>
          </div>
          {/* <!-- END: Total Income --> */}

          {/* <!-- BEGIN: Total Expense--> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-gray-200 w-1/2 md:w-1/4">
            <div class="ml-4 mr-auto">
              <div class="font-medium">Total Expense</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.total_expense)}
            </div>
          </div>
          {/* <!-- END: Total Expense --> */}

          {/* <!-- BEGIN: Total Profit--> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-gray-200 w-1/2 md:w-1/4">
            <div class="ml-4 mr-auto">
              <div class="font-medium">Profit</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.profit)}
            </div>
          </div>
          {/* <!-- END: Total Expense --> */}
        </div>

        {/* <!-- END: Sum Values --> */}

        {/* <!-- BEGIN:Trip Data Table --> */}
        <Card className={"mb-3"}>
          <Card.Header>
            <Card.Title>Consignments</Card.Title>
          </Card.Header>
          <Card.Body className={"p-0"}>
            <ReportsDataTable
              title={Title}
              header={consTableData.header}
              content={consTableData.content}
              onRowClick={(e, row) => {
                viewRecord(row);
              }}
              footerFormatter={footerFormatter}
              downloadFileName={Title}
              tableRef={consTableRef}
            />
          </Card.Body>
        </Card>
        {/* <!-- END:Trip Data Table --> */}

        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-12 md:col-span-6">
            {/* <!-- BEGIN:Other Expense Data Table --> */}
            <Card>
              <Card.Header>
                <Card.Title>Other Expenses</Card.Title>
              </Card.Header>
              <Card.Body className={"p-0"}>
                <ReportsDataTable
                  title={"Other Expenses"}
                  header={expenseTableData.header}
                  content={expenseTableData.content}
                  footerFormatter={footerFormatter}
                  tableRef={expenseTableRef}
                />
              </Card.Body>
            </Card>
            {/* <!-- END:Other Expenses Data Table --> */}
          </div>
          <div className="col-span-12 md:col-span-6">
            {" "}
            {/* <!-- BEGIN:Other Incomes Data Table --> */}
            <Card>
              <Card.Header>
                <Card.Title>Other Incomes</Card.Title>
              </Card.Header>
              <Card.Body className={"p-0"}>
                <ReportsDataTable
                  title={"Other Incomes"}
                  header={incomeTableData.header}
                  content={incomeTableData.content}
                  footerFormatter={footerFormatter}
                  tableRef={incomeTableRef}
                />
              </Card.Body>
            </Card>
            {/* <!-- END:Other Incomes Data Table --> */}
          </div>
        </div>
      </div>
    </>
  );
}
