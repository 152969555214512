import React, { useState, useEffect } from "react";
// HTML Elements
import ProcessLoader from "../../preloader/processLoader";
import ImageNFileInput from "../../inputs/imagenfile";
import ValidationError from "../../error/validationError";
import KmInput from "../../htmlElements/kmInput";
import LitreInput from "../../htmlElements/litreInput";
import PhoneInput from "../../htmlElements/phoneInput";
import CleanTab from "../../tabs/cleanTab";
import { Modal } from "react-bootstrap";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeAhAddNewButton from "../../htmlElements/typeAhAddNewButton";

import Form from "../../form/custom";
import PercentageInput from "../../htmlElements/percentageInput";
import MileageInput from "../../htmlElements/mileageInput";

// Add-on Components
import VehicleTypeForm from "../vehicle_type";

// Functions
import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";
import {
  htmlDate,
  matchData,
  JsonToFormData,
  formatTypeSearch,
  isDefined,
} from "../../../functions/common";
import * as validation from "../../../functions/validation";

// Models
import { Model, ValidationErrorModel } from "./model";

const fileBaseURL = process.env.REACT_APP_VEHICLE_URL;

const noOfTyresInput = [
  { label: "4", value: 4 },
  { label: "6", value: 6 },
  { label: "8", value: 8 },
  { label: "10", value: 10 },
  { label: "12", value: 12 },
  { label: "14", value: 14 },
  { label: "18", value: 18 },
  { label: "22", value: 22 },
];
export default function VehicleForm(props) {
  const [saveToDB, setSaveToDB] = useState(true);
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
    approve: false,
  });

  // State
  const [state, setState] = useState({ ...Model });
  const [currentTab, setCurrentTab] = useState("basic_details");
  const [validationError, setValidationError] = useState({
    ...ValidationErrorModel,
  });

  //////////// External Modal States   ////////////
  const [vehicleTypeMasterModal, setVehicleTypeMasterModal] = useState({
    show: false,
    title: "Add Vehicle Type",
    action: "create",
    id: "",
  });

  //////////// External Modal States   ////////////

  const [record_id, setRecordId] = useState();
  const [defaultState, setDefaultState] = useState({
    vehicle_type: [],
  });
  const [pageTitle, setPageTitle] = useState("Vehicle Form");
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    handleUserAction(props);
  }, []);

  // <!-- BEGIN: Vehicle Type Master Modal -- >
  const openVehicleTypeMasterModal = () =>
    setVehicleTypeMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeVehicleMasterModal = () =>
    setVehicleTypeMasterModal((prevState) => ({ ...prevState, show: false }));

  function reloadVehicleTypeData() {
    closeVehicleMasterModal();
    loadDefaultMetrics(true);
  }
  // <!-- END: Vehicle Type Master Modal -- >

  async function handleUserAction(props) {
    let action = props.action;
    let id = props.id;

    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    switch (action) {
      case "create":
        setPageTitle("Add vehicle");
        setMode((prevState) => ({ ...prevState, create: true }));
        setDefaults(props);
        // await loadDefaultMetrics();

        break;
      case "view":
        setPageTitle("View vehicle");
        setMode((prevState) => ({ ...prevState, view: true }));
        // await loadDefaultMetrics();
        loadDataById(id);
        break;
      case "edit":
        setPageTitle("Edit vehicle");
        setMode((prevState) => ({ ...prevState, edit: true }));
        // await loadDefaultMetrics();
        loadDataById(id);
        break;
      case "approve":
        setPageTitle("Approve vehicle");
        setMode((prevState) => ({ ...prevState, approve: true }));
        // await loadDefaultMetrics();
        loadDataById(id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };

    if (props?.registration_number)
      prevState["registration_number"] = props?.registration_number;

    prevState.ownership_type = "own";
    prevState["isNew"] = true;
    prevState["odometer_reading"] = 0;
    setState(prevState);
    setIsProcessing(false);
    return null;
  }
  // Default Metrics
  async function loadDefaultMetrics(reload = false) {
    if (defaultState?.vehicle_type.length === 0 || reload) {
      setIsProcessing(true);
      await api
        .getService("master/vehicle_type")
        .then(async (response) => {
          await setDefaultState((prevState) => ({
            ...prevState,
            vehicle_type: response?.data?.data,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    }
  }

  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`vehicle/${id}`).then(
      async (result) => {
        const data = result.data;
        console.log("data:", data);
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let matchedData = await matchData(state, data);

    // Setting Conditional Buttons
    // * Owner Details
    if (
      isDefined(matchedData?.owner_name) ||
      isDefined(matchedData?.owner_contactNo) ||
      isDefined(matchedData?.pan_number)
    ) {
      matchedData.owner_details = true;
    }

    // * Engine Details
    if (
      isDefined(matchedData?.RC_chasisNo) ||
      isDefined(matchedData?.RC_engineNo)
    ) {
      matchedData.engine_details = true;
    }

    // * Validity Details
    if (
      isDefined(matchedData?.FC_valid_to) ||
      isDefined(matchedData?.Insurance_valid_to) ||
      isDefined(matchedData?.Goods_valid_to)
    ) {
      matchedData.validity_details = true;
    }

    // * Document Details
    if (
      isDefined(matchedData?.tds_doc) ||
      matchedData?.tds_percentage > 0 ||
      isDefined(matchedData?.vehicle_docs)
    ) {
      matchedData.documents_details = true;
    }

    // * GPS Details
    if (
      isDefined(matchedData?.gps_enabled) &&
      isDefined(matchedData?.gps_service_provider)
    ) {
      matchedData.gps_details = true;
    }

    // matchedData.vehicle_type = matchedData?.vehicle_type?.vehicle_type;
    matchedData.FC_valid_from = htmlDate(matchedData.FC_valid_from);
    matchedData.FC_valid_to = htmlDate(matchedData.FC_valid_to);
    matchedData.Insurance_valid_from = htmlDate(
      matchedData.Insurance_valid_from
    );
    matchedData.Insurance_valid_to = htmlDate(matchedData.Insurance_valid_to);
    matchedData.Goods_valid_from = htmlDate(matchedData.Goods_valid_from);
    matchedData.Goods_valid_to = htmlDate(matchedData.Goods_valid_to);

    await setState(matchedData);
  }

  function goBack() {
    window.location.href = "/app/master/vehicle";
  }

  // Input Change
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    formValidation(name, selected);
  }

  function changeInput(event) {
    const { name, value, required } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  function changeCheckBox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  async function changeInputImage(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImage(files[0]);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  async function changeInputFile(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImageNFile(files[0], 5);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  function deleteInputImage(event, name) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [name]: "" }));
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = validationError;
    switch (name) {
      case "registration_number":
        errors[name] = validation.validateRegistrationNo(value);
        break;

      // FC Dates
      case "FC_valid_from":
        errors[name] = validation.validateFromDate(value, state.FC_valid_to);
        errors["FC_valid_to"] = validation.validateToDate(
          state.FC_valid_to,
          value
        );
        break;
      case "FC_valid_to":
        errors[name] = validation.validateToDate(value, state.FC_valid_from);
        errors["FC_valid_from"] = validation.validateFromDate(
          state.FC_valid_from,
          value
        );
        break;
      // Insurance Dates
      case "Insurance_valid_from":
        errors[name] = validation.validateFromDate(
          value,
          state.Insurance_valid_to
        );
        errors["Insurance_valid_to"] = validation.validateToDate(
          state.Insurance_valid_to,
          value
        );
        break;
      case "Insurance_valid_to":
        errors[name] = validation.validateToDate(
          value,
          state.Insurance_valid_from
        );
        errors["Insurance_valid_from"] = validation.validateFromDate(
          state.Insurance_valid_from,
          value
        );
        break;
      // Goods Permit Dates
      case "Goods_valid_from":
        errors[name] = validation.validateFromDate(value, state.Goods_valid_to);
        errors["Goods_valid_to"] = validation.validateToDate(
          state.Goods_valid_to,
          value
        );
        break;
      case "Goods_valid_to":
        errors[name] = validation.validateToDate(value, state.Goods_valid_from);
        errors["Goods_valid_from"] = validation.validateFromDate(
          state.Goods_valid_from,
          value
        );
        break;

      // PAN Number
      case "pan_number":
        errors[name] = validation.validatePAN(value);
        break;
      // Vehicle Type
      case "vehicle_type":
        errors[name] = validation.validateTypeAhead(value);
        break;
      default:
        break;
    }
    // setValidationError(errors);
  }

  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  async function prepareData(data) {
    let newData = data;
    newData.vehicle_type = newData?.vehicle_type?._id || null;
    newData["odometer_reading"] = newData["odometer_reading"] || 0;
    newData["fuel_tank_capacity"] = newData["fuel_tank_capacity"] || "";
    newData["fuel_balance"] = newData["fuel_balance"] || "";
    newData["avg_mileage"] = newData["avg_mileage"] || "";

    // if (!isDefined(newData["gps_service_provider"]))
    //   newData.gps_enabled = false;

    // newData = await removeNullvalues(newData);
    return newData;
  }

  function removeNullvalues(data) {
    let keys = Object.keys(data);
    for (var i in keys) {
      if (data[i] === null || data[i] === "null") {
        data[i] = "";
      }
    }
    return data;
  }
  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));

      let data = await prepareData({ ...state });
      data["isNew"] = true;
      data["approve"] = true;

      if (saveToDB) {
        setIsProcessing(true);
        var query = await JsonToFormData(data);
        api
          .DatapostService("vehicle", query)
          .then((response) => {
            setIsProcessing(false);
            resp.Success(response?.data?.message);
            return props.callBack(data, event);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        return props.callBack(data, event);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = await prepareData({ ...state });
      var query = await JsonToFormData(data);

      api
        .DataputService(`vehicle/${record_id}`, query)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onApprove(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let newData = { ...state };
      newData.approved = true;
      let data = await prepareData(newData);
      var query = await JsonToFormData(data);
      api
        .DataputService(`vehicle/${record_id}`, query)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function switchBoolean(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  function onChangeTab(value) {
    setCurrentTab(value);
  }

  return (
    <div
      key="vehicle_modal"
      id="vehicle_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* EXTERNAL COMPONENTS */}
      {!mode?.view && vehicleTypeMasterModal.show && (
        <VehicleTypeForm
          show={vehicleTypeMasterModal.show}
          action={vehicleTypeMasterModal.action}
          title={vehicleTypeMasterModal.title}
          onHide={closeVehicleMasterModal}
          callBack={reloadVehicleTypeData}
        />
      )}
      {/* EXTERNAL COMPONENTS */}

      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="vehicle-modal"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
        </Modal.Header>

        {/* <!-- BEGIN: Modal Body --> */}
        <Modal.Body>
          {isProcessing && <ProcessLoader />}

          {/* <!-- BEGIN: Form --> */}
          <Form
            id="vehicle-form"
            name="vehicle-form"
            onSubmit={
              mode.create
                ? onSubmit
                : mode.edit
                ? onUpdate
                : mode.approve
                ? onApprove
                : (e) => e.preventDefault()
            }
          >
            {/* <!-- BEGIN: Tab Switcher --> */}
            <div className="box p-2 mb-3 shadow">
              <CleanTab>
                <CleanTab.Tab
                  id="basic_details"
                  active={currentTab === "basic_details" ? true : false}
                  onClick={(e) => onChangeTab("basic_details")}
                >
                  Basic
                </CleanTab.Tab>
                <CleanTab.Tab
                  id="fuel_history"
                  active={currentTab === "engine_details" ? true : false}
                  onClick={(e) => onChangeTab("engine_details")}
                >
                  Engine
                </CleanTab.Tab>
                <CleanTab.Tab
                  id="owner_details"
                  active={currentTab === "owner_details" ? true : false}
                  onClick={(e) => onChangeTab("owner_details")}
                >
                  Owner
                </CleanTab.Tab>
                <CleanTab.Tab
                  id="validity_details"
                  active={currentTab === "validity_details" ? true : false}
                  onClick={(e) => onChangeTab("validity_details")}
                >
                  Validity
                </CleanTab.Tab>
                <CleanTab.Tab
                  id="gps_details"
                  active={currentTab === "gps_details" ? true : false}
                  onClick={(e) => onChangeTab("gps_details")}
                >
                  GPS
                </CleanTab.Tab>
                <CleanTab.Tab
                  id="document_details"
                  active={currentTab === "document_details" ? true : false}
                  onClick={(e) => onChangeTab("document_details")}
                >
                  Docs
                </CleanTab.Tab>
              </CleanTab>
            </div>
            {/* <!-- END: Tab Switcher --> */}

            <div className="grid grid-cols-12 gap-4 gap-y-3">
              {/* <!-- BEGIN : Basinc Details -- > */}
              {currentTab === "basic_details" && (
                <>
                  <div className="col-span-12 flex justify-center text-center my-2">
                    <div className="flex flex-col sm:flex-row">
                      <div className="form-check">
                        {" "}
                        <input
                          id="supplier"
                          className="form-check-input"
                          type="radio"
                          name="ownership_type"
                          value="supplier"
                          checked={state?.ownership_type === "supplier"}
                          onChange={changeInput}
                        />{" "}
                        <label className="form-check-label" htmlFor="supplier">
                          Supplier Vehicle
                        </label>{" "}
                      </div>
                      <div className="form-check ml-2">
                        {" "}
                        <input
                          id="own"
                          className="form-check-input"
                          type="radio"
                          name="ownership_type"
                          value="own"
                          checked={state?.ownership_type === "own"}
                          onChange={changeInput}
                        />{" "}
                        <label className="form-check-label" htmlFor="own">
                          Own Vehicle
                        </label>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="required">
                      Registraion Number{" "}
                    </Form.Label>
                    <Form.Input
                      type="text"
                      className="form-control text-uppercase"
                      id="registration_number"
                      name="registration_number"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.registration_number}
                      required
                    />
                    <ValidationError
                      msg={validationError.registration_number}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label>Vehicle Type</Form.Label>
                    <Typeahead
                      clearButton
                      id="vehicle_type"
                      name="vehicle_type"
                      onFocus={loadDefaultMetrics}
                      onChange={(selected) => {
                        changeTypeHeadInput(selected, "vehicle_type");
                      }}
                      labelKey={(option) => `${option?.vehicle_type}`}
                      options={defaultState?.vehicle_type}
                      selected={formatTypeSearch(state?.vehicle_type)}
                      placeholder="Search vehicle type..."
                      disabled={mode.view}
                      renderMenu={(results, menuProps) => (
                        <Menu {...menuProps}>
                          <TypeAhAddNewButton
                            onClick={openVehicleTypeMasterModal}
                          ></TypeAhAddNewButton>
                          {results.map((result, index) => (
                            <MenuItem
                              option={result}
                              position={index}
                              key={index}
                            >
                              {result?.vehicle_type}
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    />
                    <ValidationError msg={validationError.vehicle_type} />
                  </div>

                  {/* <!--BEGIN : Fleet Fields --> */}
                  {state?.ownership_type === "own" && (
                    <>
                      <div className="col-span-12 sm:col-span-6">
                        <Form.Label className="">No. of Tyres</Form.Label>
                        <select
                          id="no_of_tyres"
                          className="form-select"
                          name="no_of_tyres"
                          value={state?.no_of_tyres}
                          disabled={mode?.view}
                          onChange={changeInput}
                        >
                          <option value="">Select</option>
                          {noOfTyresInput.map((item, index) => {
                            return (
                              <option id={"tyre_" + index} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="col-span-12 sm:col-span-6">
                        <Form.Label className="">No. of Axles</Form.Label>
                        <select
                          id="no_of_axles"
                          className="form-select"
                          name="no_of_axles"
                          value={state?.no_of_axles}
                          disabled={mode?.view}
                          onChange={changeInput}
                        >
                          <option value="">Select</option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>

                      <div className="col-span-12 sm:col-span-6">
                        <Form.Label className="">Fuel Tank Capacity</Form.Label>
                        <LitreInput
                          id="fuel_tank_capacity"
                          className="form-control"
                          name="fuel_tank_capacity"
                          value={state?.fuel_tank_capacity}
                          disabled={mode?.view}
                          onChange={changeInput}
                        />
                      </div>
                      <div className="col-span-12 sm:col-span-6">
                        <Form.Label className="">Fuel Balance</Form.Label>
                        <LitreInput
                          id="fuel_balance"
                          className="form-control"
                          name="fuel_balance"
                          value={state?.fuel_balance}
                          disabled={mode?.view}
                          onChange={changeInput}
                        />
                      </div>

                      <div className="col-span-12 sm:col-span-6">
                        <Form.Label className="">
                          Current Odometer Reading
                        </Form.Label>
                        <KmInput
                          id="odometer_reading"
                          className="form-control"
                          name="odometer_reading"
                          value={state?.odometer_reading}
                          disabled={mode?.view}
                          onChange={changeInput}
                        />
                        <ValidationError
                          msg={validationError.odometer_reading}
                        />
                      </div>

                      <div className="col-span-12 sm:col-span-6">
                        <Form.Label className="">Avg. Mileage</Form.Label>
                        <MileageInput
                          id="avg_mileage"
                          className="form-control"
                          name="avg_mileage"
                          value={state?.avg_mileage}
                          disabled={mode?.view}
                          onChange={changeInput}
                        />
                        <ValidationError msg={validationError.avg_mileage} />
                      </div>
                    </>
                  )}
                  {/* <!--END : Fleet Fields --> */}
                </>
              )}
              {/* <!-- BEGIN: Owner Details --> */}
              {currentTab === "owner_details" && (
                <>
                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label>Vehicle Owner Name</Form.Label>
                    <Form.Input
                      type="text"
                      className="form-control"
                      id="owner_name"
                      name="owner_name"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.owner_name}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label>Owner Contact No.</Form.Label>
                    <PhoneInput
                      type="number"
                      className="form-control"
                      id="owner_contactNo"
                      name="owner_contactNo"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.owner_contactNo}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">PAN Number</Form.Label>
                    <Form.Input
                      type="text"
                      className="form-control text-uppercase"
                      id="pan_number"
                      name="pan_number"
                      disabled={mode.view}
                      onChange={changeInput}
                      maxLength="10"
                      value={state.pan_number}
                    />
                    <ValidationError msg={validationError.pan_number} />
                  </div>
                </>
              )}

              {/* <!-- END: Owner Details --> */}

              {/* <!-- BEGIN: Owner Details --> */}
              {currentTab === "engine_details" && (
                <>
                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">Chassis Number</Form.Label>
                    <Form.Input
                      type="text"
                      className="form-control"
                      id="RC_chasisNo"
                      name="RC_chasisNo"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.RC_chasisNo}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">Engine Number</Form.Label>
                    <Form.Input
                      type="text"
                      className="form-control"
                      id="RC_engineNo"
                      name="RC_engineNo"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.RC_engineNo}
                    />
                  </div>
                </>
              )}
              {/* <!-- END: Owner Details --> */}

              {/* <!-- BEGIN: GPS Details --> */}
              {currentTab === "gps_details" && (
                <>
                  <div className="col-span-12 flex justify-center items-center">
                    <Form.Label className="">Is GPS Enabled?</Form.Label>
                    <Form.Input
                      id="gps_enabled"
                      name="gps_enabled"
                      className="ml-3 form-check-switch "
                      type="checkbox"
                      onChange={changeCheckBox}
                      checked={state?.gps_enabled}
                      value={state?.gps_enabled}
                      disabled={mode?.view}
                    />
                  </div>

                  <div className="col-span-12 flex justify-center items-center mt-2 bg-gray-200 p-2">
                    <span>
                      YOUR API KEY: <b>CORELOGIS_GPS_6372</b>
                    </span>
                  </div>

                  <div className="col-span-12 flex justify-center items-center mt-2">
                    <p>
                      Please follow the instructions to integrate your GPS with
                      the application.
                    </p>
                  </div>

                  <div className="col-span-12 flex justify-center items-center mt-2">
                    <a
                      className="cursor-pointer btn btn-primary"
                      href="https://corelogis.in/docs/gps-integration"
                      target={"_blank"}
                    >
                      View Instructions
                      <i
                        class="fa fa-external-link ml-2"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </>
              )}
              {/* <!-- END: GPS Details --> */}

              {/* <!-- BEGIN: Validity Details --> */}
              {currentTab === "validity_details" && (
                <>
                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">FC Valid From</Form.Label>
                    <Form.Input
                      type="date"
                      className="form-control"
                      id="FC_valid_from"
                      name="FC_valid_from"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.FC_valid_from}
                      max={htmlDate(new Date())}
                    />
                    <ValidationError msg={validationError.FC_valid_from} />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">FC Valid To</Form.Label>
                    <Form.Input
                      type="date"
                      className="form-control"
                      id="FC_valid_to"
                      name="FC_valid_to"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.FC_valid_to}
                      min={htmlDate(state?.FC_valid_from)}
                    />
                    <ValidationError msg={validationError.FC_valid_to} />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">Insurance Valid From</Form.Label>
                    <Form.Input
                      type="date"
                      className="form-control"
                      id="Insurance_valid_from"
                      name="Insurance_valid_from"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.Insurance_valid_from}
                      max={htmlDate(new Date())}
                    />
                    <ValidationError
                      msg={validationError.Insurance_valid_from}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">Insurance Valid To</Form.Label>
                    <Form.Input
                      type="date"
                      className="form-control"
                      id="Insurance_valid_to"
                      name="Insurance_valid_to"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.Insurance_valid_to}
                      min={htmlDate(state?.Insurance_valid_from)}
                    />
                    <ValidationError msg={validationError.Insurance_valid_to} />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">
                      Goods Permit Valid From
                    </Form.Label>
                    <Form.Input
                      type="date"
                      className="form-control"
                      id="Goods_valid_from"
                      name="Goods_valid_from"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.Goods_valid_from}
                      max={htmlDate(new Date())}
                    />
                    <ValidationError msg={validationError.Goods_valid_from} />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label className="">Goods Permit Valid To</Form.Label>
                    <Form.Input
                      type="date"
                      className="form-control"
                      id="Goods_valid_to"
                      name="Goods_valid_to"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.Goods_valid_to}
                      min={htmlDate(state?.Goods_valid_from)}
                    />
                    <ValidationError msg={validationError.Goods_valid_to} />
                  </div>
                </>
              )}
              {/* <!-- END: Validity Details --> */}

              {/* <!-- BEGIN: Document Details --> */}
              {currentTab === "document_details" && (
                <>
                  <div className="col-span-12 sm:col-span-6">
                    <Form.Label>Documents</Form.Label>

                    <ImageNFileInput
                      mode={mode}
                      state={state?.vehicle_docs}
                      name="vehicle_docs"
                      error_msg={validationError.vehicle_docs}
                      onDelete={deleteInputImage}
                      onChange={changeInputFile}
                      baseURL={fileBaseURL}
                    />
                  </div>

                  {state?.tds && (
                    <div className="col-span-12 sm:col-span-6">
                      <Form.Label className="">TDS %</Form.Label>
                      <PercentageInput
                        type="number"
                        className="form-control"
                        id="tds_percentage"
                        name="tds_percentage"
                        placeholder=""
                        disabled={mode.view}
                        onChange={changeInput}
                        value={state.tds_percentage}
                        aria-describedby="inputGroupPrepend"
                      />
                    </div>
                  )}

                  {!state?.tds && (
                    <div className="col-span-12 sm:col-span-6">
                      <Form.Label className="required">
                        Non Deduction Image
                      </Form.Label>
                      <ImageNFileInput
                        mode={mode}
                        state={state?.tds_doc}
                        name="tds_doc"
                        error_msg={validationError.tds_doc}
                        onDelete={deleteInputImage}
                        onChange={changeInputImage}
                        required={true}
                      />
                    </div>
                  )}

                  <div className="col-span-12 sm:col-span-6 flex flex-wrap content-end">
                    <div className="form-check">
                      <input
                        id="tds"
                        className="form-check-input"
                        type="checkbox"
                        name="tds"
                        onChange={changeCheckBox}
                        checked={state.tds}
                        disabled={mode.view}
                      />
                      <label className="form-check-label" htmlFor="tds">
                        TDS Deduction
                      </label>
                    </div>
                    {!mode.view && (
                      <p className="text-gray-700">
                        * Uncheck the check box for Non-TDS deduction.
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>

            <input type="submit" id="save_vehicle" className="hidden" />
            <input type="submit" id="update_vehicle" className="hidden" />
            <input type="submit" id="approve_vehicle" className="hidden" />
          </Form>
          {/* <!-- END: Form --> */}

          {/* <!-- END: Card --> */}
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}

        <Modal.Footer className="justify-end">
          <div className="">
            <div className="mr-2">
              <ValidationError
                msg={!isValidForm.valid ? isValidForm.msg : ""}
                notify={true}
              />
            </div>
            <label
              type="button"
              className="btn btn-secondary mr-2 "
              onClick={props.onHide}
            >
              Cancel
            </label>

            {mode.create && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="save_vehicle"
              >
                Save
              </label>
            )}
            {mode.edit && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="update_vehicle"
              >
                Update
              </label>
            )}
            {mode.approve && (
              <label
                type="button"
                className="btn btn-success mr-2 "
                htmlFor="approve_vehicle"
              >
                Approve
              </label>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
