export function Model() {
  return {
    // _id: "",
    consignment_id: "",
    client: "",
    supplier: "",
    consignment_date: "",
    origin: "",
    origin_details: "",
    destination: "",
    destination_details: "",
    //
    freight_amount: "",
    hold_amount: "",
    journal: "",
    total_amount: "",
    amount_payable: "",
    //
    status: "",
    driver: "",
    vehicle: "",
    vehicle_type: "",
    custom_vehicle_ownership_type: "",
    vehicle_details: {},
    consignment: [""],
    // approval_status: "",
    approval_at: "",
    completed_at: "",
    acknowledged_at: "",
    created_by: "",
    booking_agent_name: "",
    booking_contact_no: "",
    total_invoice_weight: 0,
    total_invoice_weight_unit: "kg",
    total_no_Of_packages: 0,

    total_invoice_weight_received: 0,
    total_invoice_weight_received_unit: "kg",
    total_no_Of_packages_received: 0,

    tds: false,
    tds_percentage: 0,
    tds_amount: 0,
    transits: [
      // {
      //   place: "",
      //   remark: "",
      //   time: "",
      // },
    ],

    load_receipts: [
      // {
      //   consignee: {
      //     name: "",
      //     address: "",
      //     gst: "",
      //   },
      //   consigner: {
      //     name: "",
      //     address: "",
      //     gst: "",
      //   },
      //   lr_date: new Date(),
      //   lr_number: "",
      //   in_time: "",
      //   out_time: "",
      //   invoice_number: "",
      //   po_number: "",
      //   eway_bill_no: "",
      //   goods: [
      //     {
      //       material_description: "",
      //       number_of_unit: "",
      //       weight_per_unit: "",
      //       billable_weight: "",
      //       billable_value: "",
      //     },
      //   ],
      //   unloading_goods: [
      //     {
      //       material_description: "",
      //       number_of_unit: "",
      //       weight_per_unit: "",
      //       billable_weight: "",
      //       billable_value: "",
      //     },
      //   ],
      // },
    ],
    loading_point: {
      in_time: "",
      out_time: "",
      no_of_days: "",
      loading_charges: "",
      other_charges: "",
      remarks: "",
    },
    unloading_point: {
      in_time: "",
      out_time: "",
      no_of_days: "",
      unloading_charges: "",
      other_charges: "",
      remarks: "",
    },
    //Other Params
    consignment_type: "single",

    transactions: {
      payables: [],
      receivables: [],
    },

    // CHARGES

    charges: {
      _id: "",
      // Supplier
      consignment_billing_unit: "",
      consignment_unit_price: "",
      consignment_total_units: "",
      consignment_freight: "",
      consignment_addition: [],
      consignment_deduction: [],
      consignment_total_amount: 0,
      consignment_total_payable: 0,

      consignment_hold_amount: "",
      consignment_amount_payable: "",
      consignment_tds: false,
      consignment_tds_percentage: 0,
      consignment_tds_amount: 0,

      //---Client---
      client_billing_unit: "",
      client_unit_price: "",
      client_total_units: "",
      client_freight: "",
      client_addition: [],
      client_deduction: [],
      client_total_amount: 0,
    },

    additions: [],
    deductions: [],

    supplier_balance: "",
    client_balance: "",

    // Billing Type
    billing_unit: "",
    unit_price: "",
    total_units: "",

    supplier_billing_unit: "",
    supplier_unit_price: "",
    supplier_total_units: "",
    truck_hire_amount: "",

    primary_lr_number: "",
    material: "",
    remarks: "",

    created_business_type: "", // broker or transporter
    vehicle_ownership_type: "", // own or supplier
    start_time: "", // Trip Start Time
    end_time: "", // Trip End Time

    max_vehicle_capacity: "",
    load_shortage_weight: "",
    load_shortage_weight_unit: "kg",
    load_shortage_package: "",
    load_shortage_amount: "",
    action: "",

    start_odometer_reading: "",
    end_odometer_reading: "",

    opening_fuel_balance: "",
    closing_fuel_balance: "",
    vehicle_odometer_reading: "",
    // Calculated Fields
    fuel_filled: "",
    trip_distance: "",
    trip_mileage: "",
    trip_duration: "",
    
    expected_delivery_time: "",
    total_material_value_loading: "",
    total_material_value_unloading: "",
    delay_duration:""
  };
}
export function ValidationErrorModel() {
  return {
    max_vehicle_capacity: "",
    client: "",
    supplier: "",
    consignment_date: "",
    origin: "",
    destination: "",
    hold_amount: "",
    amount_payable: "",
    driver: "",
    vehicle: "",

    total_invoice_weight_received: "",
    total_no_Of_packages_received: "",

    load_receipts: [
      {
        consignee: {
          name: "",
          address: "",
          gst: "",
        },

        consigner: {
          name: "",
          address: "",
          gst: "",
        },

        lr_number: "",
        in_time: "",
        out_time: "",
        invoice_number: "",
        po_number: "",
        eway_bill_no: "",

        goods: [
          {
            material_description: "",
            number_of_unit: "",
            weight_per_unit: "",
            billable_weight: "",
            billable_value: "",
          },
        ],
        unloading_goods: [
          {
            material_description: "",
            number_of_unit: "",
            weight_per_unit: "",
            billable_weight: "",
            billable_value: "",
          },
        ],
      },
    ],
    loading_point: {
      in_time: "",
      out_time: "",
      no_of_days: "",
      loading_charges: "",
      other_charges: "",
      remarks: "",
    },
    unloading_point: {
      in_time: "",
      out_time: "",
      no_of_days: "",
      unloading_charges: "",
      other_charges: "",
      remarks: "",
    },

    //Other Params
    consignment_type: "",

    // Charges

    charges: {
      _id: "",
      // Supplier
      supplier_billing_unit: "",
      supplier_unit_price: "",
      supplier_total_units: "",
      consignment_freight: "",
      consignment_addition: [],
      consignment_deduction: [],

      consignment_hold_amount: "",
      consignment_amount_payable: "",

      //Client
      client_billing_unit: "",
      client_unit_price: "",
      client_total_units: "",
      client_freight: "",
      client_addition: [],
      client_deduction: [],
    },
    start_odometer_reading: "",
    end_odometer_reading: "",
    opening_fuel_balance: "",
    closing_fuel_balance: "",
    vehicle_odometer_reading: "",
    trip_mileage: "",
    expected_delivery_time: "",
    total_material_value_loading: "",
    total_material_value_unloading: "",
 
  };
}
export function ErrorMsg() {}
