import React, { useState, useEffect, useRef } from "react";
import { Route, HashRouter } from "react-router-dom";
//import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// <!--------------PAGES----------------->
import ReportsView from "../view/ReportsView/index";
// <!--------------PAGES----------------->

// <!--------------COMPONENTS----------------->
import Wrapper from "../components/wrapper";
// <!--------------COMPONENTS----------------->

// Cookies & Storage
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Reports(props) {
  const [accessList, setAccessList] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {}, []);

  return (
    <HashRouter>
      <div className="body-content main">
        {/* <!-- BEGIN: ROUTES --> */}
        {/* <Wrapper> */}
          <Route path="/:template_name" component={ReportsView} />
          {/* <Route path="" component={NotFound} exact={true} /> */}
        {/* </Wrapper> */}
        {/* <!-- END: ROUTES --> */}
      </div>
    </HashRouter>
  );
}
