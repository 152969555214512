import React from "react";
import PropTypes from "prop-types";

import { formatCurrency, localDate } from "../../functions/common";

export default function Component(props) {
  const { data, className, id, ...rest } = props;

  return (
    <div className={className} id={id}>
      <table class="table">
        <thead>
          <tr class="bg-gray-200 text-gray-700">
            <th class="border border-b-2 dark:border-dark-5 ">#</th>
            <th class="border border-b-2 dark:border-dark-5 ">Date</th>
            <th class="border border-b-2 dark:border-dark-5 ">Vehicle</th>
            <th class="border border-b-2 dark:border-dark-5 ">Origin</th>
            <th class="border border-b-2 dark:border-dark-5 ">Destination</th>
            <th class="border border-b-2 dark:border-dark-5 ">Client</th>
            <th class="border border-b-2 dark:border-dark-5 ">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr>
              <td class="border dark:border-dark-5 break-all">{index + 1}</td>
              <td class="border dark:border-dark-5 break-all">
                {localDate(item?.consignment_date)}
              </td>
              <td class="border dark:border-dark-5 break-all">
                {item?.vehicle?.registration_number || ""}
              </td>
              <td class="border dark:border-dark-5 break-all">
                {item?.origin?.name || ""}
              </td>
              <td class="border dark:border-dark-5 break-all">
                {item?.destination?.name || ""}
              </td>
              <td class="border dark:border-dark-5 break-all">
                {item?.client?.name || ""}
              </td>
              <td class="border dark:border-dark-5 break-all">
                {formatCurrency(item?.charges?.consignment_total_amount || 0)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.array,
};

Component.defaultProps = {
  className: "",
  id: "saved_consignments",
  data: [],
};
