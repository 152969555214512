import React, { useState, useEffect } from "react";
// import { mapData } from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
export default function Component(props) {
  const Title = "P&L Report";
  const { data, onRowClick } = props;
  const [state, setState] = useState({
    vehicle_hiring_amount: 0,
    consignment_addition: 0,
    consignment_deduction: 0,
    client_freight: 0,
    client_addition: 0,
    client_deduction: 0,
    other_income: 0,
    other_expense: 0,
    other_income_list: [],
    other_expense_list: [],
    total_income: 0,
    total_expense: 0,
    profit_loss_amount: 0,
    profit_loss: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    // const getUser = async () => {
    //   // Getting Local User Data
    //   let localUserData = await storageServices.getCurrentUser();
    //   setLocalUser(localUserData);
    // };
    // getUser();
    loadData(data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);
    setState(data);

    // let tableData = await mapTableData(data);
    // console.log("tableData:", tableData);
    // setTableData(tableData);
    // await calculateTotal(data);
    setIsProcessing(false);
  }

  function viewRecord(row) {}

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Content --> */}

        <div className="mt-5 p-5 flex flex-col justify-center items-center">
          <table className="table w-1/2">
            <thead>
              <tr className="bg-theme-1 text-white">
                <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Particulars
                </th>
                <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Amount
                </th>
                <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- BEGIN: Income */}
              <tr>
                <td className="border">Client Freight</td>
                <td className="border">{state?.client_freight}</td>
                <td className="border"></td>
              </tr>
              <tr>
                <td className="border">Supplier Deductions</td>
                <td className="border">{state?.consignment_deduction}</td>
                <td className="border"></td>
              </tr>
              <tr>
                <td className="border">Client Charges</td>
                <td className="border">{state?.client_addition}</td>
                <td className="border"></td>
              </tr>
              <tr>
                <td className="border">Other Incomes</td>
                <td className="border">{state?.other_income}</td>
                <td className="border"></td>
              </tr>
              <tr className="bg-gray-100 dark:bg-dark-1">
                <td className="border font-medium text-lg">Total Income</td>
                <td className="border"></td>
                <td className="border font-medium text-lg">
                  {state?.total_income}
                </td>
              </tr>
              {/* <!-- END: Income */}

              {/* <!-- BEGIN: Expenses */}
              <tr>
                <td className="border">Vehicle Hiring Amount</td>
                <td className="border">{state?.vehicle_hiring_amount}</td>
                <td className="border"></td>
              </tr>
              <tr>
                <td className="border">Consignment Expenses</td>
                <td className="border">{state?.consignment_addition}</td>
                <td className="border"></td>
              </tr>
              <tr>
                <td className="border">Client Deductions</td>
                <td className="border">{state?.client_deduction}</td>
                <td className="border"></td>
              </tr>
              <tr>
                <td className="border">Other Expenses</td>
                <td className="border">{state?.other_expense}</td>
                <td className="border"></td>
              </tr>
              <tr className="bg-gray-100 dark:bg-dark-1">
                <td className="border font-medium text-lg">Total Expense</td>
                <td className="border"></td>
                <td className="border font-medium text-lg">
                  {state?.total_expense}
                </td>
              </tr>
              {/* <!-- END: Expense */}
              <tr className="">
                <td className="border font-bold text-lg">Net Profit </td>
                <td className="border"></td>
                <td
                  className={
                    state?.profit_loss === "Loss"
                      ? "border font-bold text-lg text-theme-6"
                      : "border font-bold text-lg text-green-500"
                  }
                >
                  {state?.profit_loss_amount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <!-- END: Content --> */}
      </div>
    </>
  );
}
