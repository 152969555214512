import React from "react";
import moment from "moment";
import ReactDOMServer from "react-dom/server";

import * as api from "../api_service/api";
import axios from "axios";
import { isValidPhoneNumber } from "react-phone-number-input";

// import {
//   geocodeByAddress,
//   geocodeByPlaceId,
//   getLatLng,
// } from "react-places-autocomplete";

const _ = require("lodash");

const fileNamePrefix = "CL";

const consignmentRegexFormula = /CMT[0-9]{4}[A-Z]{2}19/g;
const invoiceRegexFormula = /IVC[0-9]{4}[A-Z]{2}29/g;

const consNumberRegexFormula = /CMT[0-9]{4}[A-Z]{2}19(.*)/i;
const invoiceNumberRegexFormula = /IVC[0-9]{4}[A-Z]{2}29(.*)/i;

// GPS Data

// Google Address Fetch
// function getAddressDetails(address) {
//   geocodeByAddress(address)
//     .then((results) => getLatLng(results[0]))
//     .then((latLng) => console.log("Success", latLng))
//     .catch((error) => console.error("Error", error));
// }

export function getUserAccessList(localUserData) {
  let list = ["home"];
  const allPermissionsList = [
    "home",
    "consignments",
    "invoices",
    "reports",
    "accounts",
    "master",
    "business_party",
    "settings",
    "user_management",
    "branch_management",
    "business_rules",
  ];

  let isOwner = localUserData?.role?.is_owner || false;
  let isAdmin = localUserData?.role?.is_admin || false;
  // Checking for organization - First Time Login Check
  if (isEmpty(localUserData?.organization_id)) {
    list = ["home", "branch_management", "user_management"];
    return list;
  }
  // Checking if owner
  if (isOwner) {
    return allPermissionsList;
  }
  list = localUserData?.role?.module_access; // Array
  if (isAdmin) {
    list.push("user_management", "branch_management");
  }
  return list;
}

export function getPrevNthDate(n) {
  var d = new Date();
  return d.setDate(d.getDate() - n);
}

export function getNextNthDate(n) {
  var d = new Date();
  return d.setDate(d.getDate() + n);
}

export function validatePhone(string) {
  return isValidPhoneNumber(string);
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export async function forwardGeocode(address) {
  const API_KEY = "v6q-jUkswsk4ehGAOV6ifM-9jGySnaT3naBOQjIOD-Q";
  const URL = `https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=${API_KEY}`;

  return new Promise(async (resolve, reject) => {
    return await axios
      .get(URL)
      .then(async (response) => {
        let data = response?.data;
        let formattedData = await formatGpsAddress(data);
        resolve(formattedData);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
  // const URL = `http://api.positionstack.com/v1/forward?access_key=${API_KEY}&query=${address}`;
  // const options = {
  //   // rejectUnauthorized: false,
  //   // mode: "cors",
  //   // headers: {
  //   //   "Access-Control-Allow-Origin": "*",
  //   // },
  // };
  // try {
  //   const response = await fetch(URL, options);
  //   console.log("response:", response);
  //   const json = await response.json();
  //   let formattedData = await formatGpsAddress(json?.data);
  //   return formattedData;
  // } catch (err) {
  //   throw err;
  //   console.log(err);
  //   return;
  // }

  // let url = `http://api.positionstack.com/v1/forward?access_key=${API_KEY}&query=${address}`;
  // await api
  //   .apiGetService(url)
  //   .then(async (results) => {
  //     const data = results.data.data;
  //     let formattedData = await formatGpsAddress(data);
  //     console.log("formattedData:", formattedData);
  //     return formattedData;
  //   })
  //   .catch((error) => {
  //     console.error("Error", error);
  //     return;
  //   });
}

export async function reverseGeocode(latlng) {
  const API_KEY = "v6q-jUkswsk4ehGAOV6ifM-9jGySnaT3naBOQjIOD-Q";
  const URL = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latlng}&lang=en-US&apiKey=${API_KEY}`;

  return new Promise(async (resolve, reject) => {
    return await axios
      .get(URL)
      .then(async (response) => {
        let data = response?.data;
        let formattedData = await formatGpsAddress(data);
        resolve(formattedData);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
export function trimInvoiceNum(string) {
  let number = string?.match(invoiceNumberRegexFormula);

  // return string.replace(invoiceRegexFormula, "");
  return number?.[1];
}

export function trimConsNum(string) {
  let number = string?.match(consNumberRegexFormula);
  let value = number?.[1] || "NOT ASSIGNED";

  // return string.replace(consignmentRegexFormula, "");
  return value;
}

// export function trimConsNum(string) {
//   return string.replace(consignmentRegexFormula, "");
// }

export function formatConsignmentHtml(string) {
  const formula = /(\s*CMT[0-9]{4}[A-Z]{2}19)/;
  let splitString = string.split(formula);
  let consEntity = isDefined(splitString[1]) ? splitString[1] : "";
  let consNo = isDefined(splitString[2]) ? splitString[2] : "";
  return (
    <div className="text-lg">
      <span className="text-gray-700">{consEntity}</span>
      <span className="text-theme-1 font-medium font-bold">{consNo}</span>
    </div>
  );
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export async function formatGpsAddress(recData) {
  let data = recData["items"];

  let response = {};
  // data is in Array
  if (isDefined(data) && data.length > 0) {
    let addressData = data[0];
    response = addressData["address"];
    // delete response.name;
    response["latitude"] = addressData["position"]["lat"];
    response["longitude"] = addressData["position"]["lng"];
    response["country_code"] = response["countryCode"];
    response["country"] = response["countryName"];
    response["state_code"] = response["stateCode"];
    response["postal_code"] = response["postalCode"];
    // response["confidence"] = addressData["scoring"]["queryScore"] || 0;
  }

  return response;
}

// export async function formatGpsAddress(data) {
//   console.log("data:", data);
//   let response = {};
//   // data is in Array
//   if (isDefined(data) && data.length > 0) {
//     response = data[0];
//     // delete response.name;
//     response["city"] = response["county"];
//     response["district"] = response["county"];
//     response["state"] = response["region"];
//     response["state_code"] = response["region_code"];
//   }
//   return response;
// }

export function formatHours(value) {
  return moment().startOf("day").add(value, "hours").format("HH:mm");
}

export function metreToKm(string) {
  return (string / 1000).toFixed(1);
}
export function cleanHtml(someText) {
  return someText.replace(/(\r\n|\n|\r)/gm, "");
}
export function getFileName(name, format) {
  let timestamp = getTimeStamp();
  let file_name = `${fileNamePrefix} ${name} ${timestamp}.${format}`;
  return file_name;
}

export function cloneObject(data) {
  return Object.assign({}, data);
}

export function getInvoiceStage(status) {
  let stepper = 0;
  switch (status) {
    case "waiting_for_approval":
      stepper = 1;
      break;

    case "approved":
      stepper = 2;
      break;

    case "invoiced":
      stepper = 3;
      break;

    case "submitted":
      stepper = 4;
      break;
    case "closed":
      stepper = 5;
      break;
    case "overdue":
      stepper = 6;
      break;
    case "cancelled":
      stepper = 9;
      break;

    default:
      break;
  }
  return stepper;
}

export function getConsignmentStage(status) {
  let stepper = 0;
  switch (status) {
    case "drafted":
      stepper = 0;
      break;

    case "pending":
      stepper = 1;
      break;

    case "in_progress":
      stepper = 2;
      break;

    case "completed":
      stepper = 3;
      break;

    case "acknowledged":
      stepper = 3;
      break;

    case "invoiced":
      stepper = 4;
      break;

    default:
      break;
  }
  return stepper;
}

export function isDateExpired(dateString) {
  var varDate = new Date(dateString); //dd-mm-YYYY
  var today = new Date();

  if (varDate < today) {
    return true;
  } else {
    return false;
  }
}

export function formatPhoneNumber(string) {
  return string;
}

export function standardCase(string) {
  string = _.startCase(_.toLower(string || ""));
  return string;
}

export function deleteObjFromArrayByIndex(array, arrayIndex) {
  // const index = array.indexOf(arrayIndex);
  // if (index !== -1) array.splice(index, 1);
  return array.splice(arrayIndex, 1);
}

export function deleteObjFromArray(array, obj, key) {
  var index = array.findIndex(function (o) {
    return o[key] === obj[key];
  });
  if (index !== -1) array.splice(index, 1);
  return array;
}

export function upsertObjInArray(array, element, key) {
  // (1)
  const i = array.findIndex((_element) => _element[key] === element[key]);
  if (i > -1) array[i] = element;
  // (2)
  else array.push(element);
  return array;
}

export function getAddressString(data) {
  let address_line_1 = isDefined(data?.address_line_1)
    ? `${data?.address_line_1},`
    : "";
  let address_line_2 = isDefined(data?.address_line_1)
    ? `${data?.address_line_2},`
    : "";

  let city = isDefined(data?.city) ? `${data?.city},` : "";
  let state = isDefined(data?.state) ? `${data?.state}` : "";
  let zip_code = isDefined(data?.zip_code) ? ` - ${data?.zip_code}.` : "";

  return address_line_1 + address_line_2 + city + state + zip_code;
}

export function getAddressHtmlString(data) {
  let address_line_1 = isDefined(data?.address_line_1)
    ? `${data?.address_line_1},`
    : "";
  let address_line_2 = isDefined(data?.address_line_1)
    ? `\n${data?.address_line_2},`
    : "";

  let city = isDefined(data?.city) ? `${data?.city},` : "";
  let state = isDefined(data?.state) ? `${data?.state}` : "";
  let zip_code = isDefined(data?.zip_code) ? ` - ${data?.zip_code}.` : "";

  return address_line_1 + address_line_2 + ("\n" + city + state + zip_code);
}

export function getTimeStamp() {
  var currentdate = new Date();
  var datetime =
    currentdate.getDate() +
    "-" +
    (currentdate.getMonth() + 1) +
    "-" +
    currentdate.getFullYear() +
    "-" +
    currentdate.getHours() +
    "-" +
    currentdate.getMinutes();
  return datetime;
}

export function getAdultMaxDate() {
  return new Date().setFullYear(new Date().getFullYear() - 18);
}

export function preventTabClosing(msg) {
  return window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    return (ev.returnValue = "Are you sure you want to close?");
  });
}

export function isDefined(data) {
  if (
    typeof data !== "undefined" &&
    data !== null &&
    data !== "" &&
    data !== "null"
  ) {
    return true;
  } else {
    return false;
  }
}

export function isEmpty(data) {
  if (
    typeof data === "undefined" ||
    data === "" ||
    data === " " ||
    data === null
  ) {
    return true;
  } else {
    return false;
  }
}

export function commaList(array, key) {
  let result = "";
  try {
    result = Array.prototype.map
      .call(array, function (item) {
        return item[key];
      })
      .join(",");
  } catch (e) {}
  return result;
}

export function navToPage(page) {
  window.location.href = page;
}

export function getColorPalettes() {
  const colors = [
    "#FF8B26",
    "#FFC533",
    "#23608f",
    "#ef5350",
    "#ec407a",
    "#ab47bc",
    "#7e57c2",
    "#5c6bc0",
    "#42a5f5",
    "#29b6f6",
    "#66bb6a",
    "#cddc39",
    "#ffeb3b",
  ];
  return colors;
}

export function getHoverColorPalettes() {
  const colors = [
    "#FF8B26",
    "#FFC533",
    "#23608f",
    "#ef5350",
    "#ec407a",
    "#ab47bc",
    "#7e57c2",
    "#5c6bc0",
    "#42a5f5",
    "#29b6f6",
    "#66bb6a",
    "#cddc39",
    "#ffeb3b",
  ];
  return colors;
}

export function stringToHTML(str) {
  var dom = document.createElement("div");
  dom.innerHTML = ReactDOMServer.renderToStaticMarkup(str);
  return dom;
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export function htmlToString(element) {
  return ReactDOMServer.renderToStaticMarkup(element);
}
export function kgToMTStr(string) {
  try {
    string = string / 1000;
    string = string + " MT";
  } catch (e) {}
  return string;
}

export function kgToMT(string) {
  try {
    string = string / 1000;
  } catch (e) {}
  return string;
}

export function capitalizeRmScore(string) {
  return string.toLowerCase().replace(/_/g, " ").capitalize();
}

export function capitalizeFirst(string) {
  return _.upperFirst(_.toLower(string));
}

export function toUpperRmScore(string) {
  return string.toUpperCase().replace(/_/g, " ");
}
export function toLowerScore(string) {
  return string.toLowerCase().replace(/ /g, "_");
}
export function formatConsStatus(data) {
  let output = <></>;
  let class_name = "";

  switch (data) {
    case "pending":
      class_name = "badge-secondary";
      break;
    case "in_progress":
      class_name = "badge-warning";
      break;

    case "completed":
      class_name = "badge-primary";
      break;

    case "acknowledged":
      class_name = "badge-success";
      break;

    default:
      class_name = "badge-dark";
  }
  output = (
    <span className={"badge " + class_name}>{toUpperRmScore(data)}</span>
  );
  return output;
}

export function toFloat(value, round = 2) {
  if (isNaN(value) || typeof value == "undefined") {
    value = 0;
  }
  let response = parseFloat(value || 0);
  // response = response.toFixed(2);
  // response = Math.round(response * 1e12) / 1e12;
  try {
    response = response.toFixed(round);
    response = Math.round(response * 1e12) / 1e12;
  } catch (e) {}

  return response;
}

export function formatUserAccess(access) {
  let accessData = {
    delete_consignment: false,
    create_consignment: false,
    approve_consignment: false,
    acknowledge_consignment: false,
    create_transits: false,
    create_invoice: false,
    approve_invoice: false,
    generate_invoice: false,
    sumbit_invoice: false,
    cancel_invoice: false,
    approve_master: false,
  };
  for (var i in access) {
    accessData[access[i]] = true;
  }
  return accessData;
}

export function formatModuleAccess(access) {
  let accessData = {
    master: false,
    accounts: false,
    business_party: false,
    user_management: false,
    branch_management: false,
    consignments: false,
    invoices: false,
  };
  for (var i in access) {
    accessData[access[i]] = true;
  }
  return accessData;
}

// TypeAhead

// Selection

export function formatTypeahead(inputList, key, value) {
  let obj = inputList.find((o) => o[key] === value);
  let response = [];
  if (typeof obj !== "undefined") {
    response.push({ id: obj._id, label: obj[key] });
  }
  return response;
}

export function formatTypeaheadCommon(value) {
  let response = [];
  if (typeof value !== "undefined" && value !== "") {
    response.push(value);
  }
  return response;
}

export function formatTypeSearch(data) {
  let response = [];
  if (typeof data == "object") {
    response.push(data);
  } else {
    response = [];
  }
  return response;
}

export function formatTypeaheadString(obj) {
  let response = [];
  if (typeof obj === "string") {
    response.push(obj);
  } else {
    response = [];
  }
  return response;
}

export function formatTypeahead2(obj) {
  let response = [];
  if (typeof obj !== "undefined") {
    response.push(obj);
  } else {
    response.push(obj);
  }
  return response;
}

export function toTypeAheadFormat(data, key) {
  let response = { id: "", label: "" };
  if (typeof data._id !== "undefined" && typeof data[key] !== "undefined") {
    response = { id: data?._id, label: data[key] };
  }
  return response;
}

//////////// VALIDATION ////////////
export function validateComplexForm(errors) {
  let valid = true;
  let keys = Object.keys(errors);
  for (var i in keys) {
    if (Array.isArray(errors[keys[i]])) {
      let array = errors[keys[i]];
      for (var i in array) {
        Object.values(array[i]).forEach(
          (val) => val.length > 0 && (valid = false)
        );
      }
    } else {
      errors[keys[i]].length > 0 && (valid = false);
    }
  }
  return valid;
}

export function validateForm(errors) {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
}

//////////// VALIDATION ////////////

export function checkUndefined(object, key) {
  if (object && object[key] && typeof object[key] !== "undefined") {
    return object[key];
  } else {
    return "";
  }
}

export function formatCurrency(data) {
  data = isNaN(data) || !isDefined(data) ? 0 : data;
  const currency = "₹ ";
  var output = "";
  try {
    output = currency + data.toLocaleString();
  } catch {
    output = currency + data;
  }
  return output;
}

export function formatLitres(data) {
  data = data || 0;
  return `${data} L`;
}

export function formatOdoReading(data) {
  data = data || 0;
  return `${data} KM`;
}

export function formatKm(data) {
  data = data || 0;
  return `${data} KM`;
}

export function formatMileage(data, unit_measure = "kmpl") {
  let response = "";
  if (isDefined(data)) {
    data = toFloat(data || 0).toFixed(1);
    response = `${data} kmpl`;
  }
  return response;
}
export function formatElementTH(object, key) {
  let response = [{ id: object._id, name: object[key] }];
  return response;
}

export function clearNullValues(data) {
  let keys = Object.keys(data);
  for (var i in keys) {
    data[keys[i]] = data[keys[i]] === null ? "" : data[keys[i]];
  }
  return data;
}

export function matchData(state, data) {
  let keys = Object.keys(state);
  for (var i in keys) {
    if (Array.isArray(data[keys[i]])) {
      let arr = data[keys[i]];
      if (arr.length !== 0) {
        state[keys[i]] = arr;
      }
    } else if (typeof data[keys[i]] !== "undefined") {
      state[keys[i]] = data[keys[i]];
    }
  }
  return state;
}

// export function matchData(state, data) {
//   let keys = Object.keys(state);
//   for (var i in keys) {
//     if (Array.isArray(data[keys[i]])) {
//       let arr = data[keys[i]];
//       if (arr.length !== 0) {
//         state[keys[i]] = data[keys[i]];
//       }
//     } else if (typeof data[keys[i]] !== "undefined") {
//       state[keys[i]] = data[keys[i]];
//     }
//   }
//   return state;
// }

export async function matchArrayObjects(state, data) {
  let keys = Object.keys(state[0]);

  for (var i in data) {
    if (typeof state[i] === "undefined") {
      const obj = await keys.reduce(
        (o, key) => Object.assign(o, { [key]: "" }),
        {}
      );
      state.push(obj);
    }

    for (var j in keys) {
      if (typeof data[i][keys[j]] !== "undefined") {
        if (Array.isArray(data[i][keys[j]])) {
          state[i][keys[j]] = await matchArrayObjects(
            state[i][keys[j]],
            data[i][keys[j]]
          );
        } else {
          state[i][keys[j]] = data[i][keys[j]];
        }
      }
    }
  }

  return state;
}

function getKey(item) {
  return Object.keys(item)[0];
}
export function convertUTC(date) {
  var local_date = date + " UTC";
  local_date = localTime(local_date);
  return local_date;
}

export function localTime(string) {
  if (string) {
    var d = new Date(string);
    d.setMilliseconds(0);
    d.setSeconds(0);
    var n = d.toLocaleString([], { dateStyle: "short", timeStyle: "short" });
    return n;
  } else {
    return string;
  }

  // return moment(string).format('DD-MM-YYYY, h:mm a');
}

export function localDate(string) {
  let response = "";
  if (
    string != "" &&
    typeof string != "undefined" &&
    string != null &&
    string != undefined
  ) {
    response = moment(string).format("DD-MM-YYYY");
  }
  return response;
}

export function localDateTime(string) {
  let response = "";
  if (
    string != "" &&
    typeof string != "undefined" &&
    string != null &&
    string != undefined
  ) {
    response = moment(string).format("DD-MM-YYYY h:mm a");
  }
  return response;
}

export function htmlDate(string) {
  if (typeof string !== "undefined" && string !== null && string !== "") {
    return moment(string).format("YYYY-MM-DD");
  } else {
    return "";
  }
}

export function htmlInputDateTime(string) {
  if (typeof string !== "undefined" && string) {
    return moment(string).format("YYYY-MM-DDTHH:mm");
  } else {
    return "";
  }
}

export function htmlDateTime(string) {
  if (typeof string !== "undefined" && string) {
    return moment(string).format("DD-MM-YYYY h:mm a");
  } else {
    return "";
  }
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function mongoUTC(string) {
  let newDate = moment(string);
  if (newDate.isValid()) {
    return newDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  } else {
    return "";
  }
}

export function formatDateToUTC(date) {
  // Converts to UTC with 0 set
  var string = new Date(date);
  string.setUTCHours(0, 0, 0, 0);
  return string;
}

export function getDateDiff(date1, date2) {
  let result = "";
  try {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    var Difference_In_Time = d2.getTime() - d1.getTime();
    result = Difference_In_Time / (1000 * 3600 * 24);
  } catch {
    console.log("Couldn't fidn difference between two days.");
  }
  return Math.round(result);
}

export function calculateTripDuration(start_time, end_time) {
  let result = "";

  if (start_time && end_time) {
    var startDate = new Date(start_time);
    var endDate = new Date(end_time);
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);

    // var dDisplay = d > 0 ? d + (d == 1 ? " d, " : " days, ") : "";
    // var hDisplay = h > 0 ? h + (h == 1 ? " h, " : " hrs, ") : "";
    // var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins") : "";

    var dDisplay = d > 0 ? d + "d " : "";
    var hDisplay = h > 0 ? h + "h " : 0 + "h ";
    var mDisplay = m > 0 ? m + "m" : "";

    result = dDisplay + hDisplay + mDisplay;
  }
  return result;
}

export function checkRunTime(date) {
  var current = new Date();
  current.setMinutes(current.getMinutes() - 1);
  var run_time = new Date(date);
  if (run_time > current) {
    return true;
  } else {
    return false;
  }
}

export function getTodayDate() {
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  today = mm + "/" + dd + "/" + yyyy;
  return today;
}

export function addDate(days) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  date = mm + "/" + dd + "/" + yyyy;
  return date;
}
export const convertArrayToObject = (array) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    var key = getKey(item);
    //var json_item = JSON.parse(item);
    return {
      ...obj,
      //[item[key]]: item,
      [key]: item[key],
    };
  }, initialValue);
};

export function extractDate(date) {
  if (typeof date != "undefined") {
    try {
      var pattern = /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/gi;
      var ext_date = [];
      ext_date = date.match(pattern);
      var new_date = "";
      if (ext_date) {
        new_date = ext_date[0];
      }
      return new_date;
    } catch (e) {
      console.log("exception:", e);
    }
  }
}

export function removePath(fullPath) {
  return fullPath.replace(/^.*[\\\/]/, "");
}

function isObject(item) {
  return typeof item === "object" && !Array.isArray(item) && item !== null;
}

export function JsonToFormData(json) {
  var form_data = new FormData();
  for (var key in json) {
    if (Array.isArray(json[key])) {
      let element = json[key];
      element.forEach((item) => form_data.append(`${key}[]`, item));

      // form_data.append(`${key}[]`, json[key]);
    } else {
      form_data.append(key, json[key]);
    }
  }
  return form_data;
}

export function JsonToFormDataModel(json) {
  var form_data = new FormData();
  for (var key in json) {
    if (Array.isArray(json[key])) {
      let element = json[key];
      // element.forEach((item) => form_data.append(`${key}[]`, item));

      form_data.append(`${key}[]`, json[key]);
    } else {
      form_data.append(key, json[key]);
    }
  }
  return form_data;
}

export function JsonToFormDataCustom(json) {
  var form_data = new FormData();
  for (var key in json) {
    if (Array.isArray(json[key])) {
      let element = json[key];
      element.forEach((item) => form_data.append(`${key}[]`, item));
      // form_data.append(`${key}[]`, json[key]);
    } else {
      form_data.append(key, json[key]);
    }
  }
  return form_data;
}

// export function JsonToFormData(json) {
//   var form_data = new FormData();
//   for (var key in json) {
//     if (Array.isArray(json[key])) {
//       form_data.append(`${key}[]`, json[key]);
//     } else if (isObject(json[key])) {
//       for (let previewKey in json[key]) {
//         form_data.append(`${key}[${previewKey}]`, json[key][previewKey]);
//       }
//     } else {
//       form_data.append(key, json[key]);
//     }
//   }
//   return form_data;
// }

export function JsonToFormDataObject(json) {
  var form_data = new FormData();
  for (var key in json) {
    if (Array.isArray(json[key])) {
      // form_data.append(`${key}[]`, json[key]);
      let element = json[key];
      element.forEach((item) => form_data.append(`${key}[]`, item));
    } else if (isObject(json[key])) {
      for (let previewKey in json[key]) {
        form_data.append(`${key}[${previewKey}]`, json[key][previewKey]);
      }
    } else {
      form_data.append(key, json[key]);
    }
  }
  return form_data;
}

export const getFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export function timeConversion(millisec) {
  var seconds = (millisec / 1000).toFixed(1);

  var minutes = (millisec / (1000 * 60)).toFixed(1);

  var hours = (millisec / (1000 * 60 * 60)).toFixed(1);

  var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

  if (seconds < 60) {
    return seconds + " Sec";
  } else if (minutes < 60) {
    return minutes + " Min";
  } else if (hours < 24) {
    return hours + " Hrs";
  } else {
    return days + " Days";
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function preparePiechartData(array, key_field) {
  var counts_data = {};
  var response = [];

  for (var i in array) {
    let attribute = array[i][key_field].split(" ", 1);
    counts_data[attribute] = counts_data[attribute] || {};
    counts_data[attribute].count = counts_data[attribute].count || 0;

    let old_value = counts_data[attribute].count || 0;
    counts_data[attribute].count = old_value + 1;
  }

  // Creating response
  for (var key in counts_data) {
    response.push({ name: key, value: counts_data[key].count });
  }

  return response;
}

export async function getId(inputList, key, value) {
  let obj = await inputList.find((o) => o[key] === value);
  return obj?._id;
}

export function randomNumber(number = 8) {
  //return Math.random().toString(number).slice(2);
  return Math.random().toFixed(number).split(".")[1];
}

export function calcFuelUsed(
  opening_value,
  fuel_filled,
  closing_value,
  unit_measure = "l"
) {
  let response = 0;
  if (opening_value && closing_value) {
    response =
      toFloat(opening_value || 0) +
      toFloat(fuel_filled || 0) -
      toFloat(closing_value || 0);
  }

  return response;
}

export function calcTripDistance(end_km, start_km, unit_measure = "km") {
  let response = "";
  if (isDefined(start_km) && isDefined(end_km) && end_km > 0) {
    response = toFloat(end_km || 0) - toFloat(start_km || 0);
  }
  return response;
}

export function getAcronym(string) {
  let response = "";
  if (string) {
    response = string
      .match(/\b(\w)/g)
      .join("")
      .toUpperCase();
  }
  return response;
}

export function calcMileage(distance, fuel_used, unit_measure = "kmpl") {
  let response = 0;
  if (isDefined(distance) && isDefined(fuel_used)) {
    if (fuel_used === 0) {
      response = 0;
    } else {
      response = (toFloat(distance || 0) / toFloat(fuel_used || 1)).toFixed(1);
    }
  }

  return response;
}

export function Modal() {
  return (
    <div
      className="modal show"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Modal title
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">...</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
