//Icons
import { ReactComponent as IconHome } from "../../assets/images/icons/home.svg";
import { ReactComponent as IconBox } from "../../assets/images/icons/box.svg";
import { ReactComponent as IconFileText } from "../../assets/images/icons/file-text.svg";
import { ReactComponent as IconPieChart } from "../../assets/images/icons/pie-chart.svg";
import { ReactComponent as IconDatabase } from "../../assets/images/icons/database.svg";
import { ReactComponent as IconDollarSign } from "../../assets/images/icons/dollar-sign.svg";
import { ReactComponent as IconActivity } from "../../assets/images/icons/activity.svg";
import { ReactComponent as IconUsers } from "../../assets/images/icons/users.svg";
import { ReactComponent as IconUser } from "../../assets/images/icons/user.svg";
import { ReactComponent as IconSettings } from "../../assets/images/icons/settings.svg";
import { ReactComponent as IconList } from "../../assets/images/icons/list.svg";
import { ReactComponent as IconGitMerge } from "../../assets/images/icons/git-merge.svg";
import { ReactComponent as IconSliders } from "../../assets/images/icons/sliders.svg";
import { ReactComponent as IconMapPin } from "../../assets/images/icons/map-pin.svg";
import { ReactComponent as IconTruck } from "../../assets/images/icons/truck.svg";
import { ReactComponent as IconBriefcase } from "../../assets/images/icons/briefcase.svg";
// Fleet
import { ReactComponent as IconWheel } from "../../assets/images/icons/wheel.svg";

export const MenuList = [
  {
    label: "Home",
    Icon: IconHome,
    url: "/home",
    requirePermission: false,
    checkPermission: "",
  },
  {
    label: "Consignments",
    Icon: IconBox,
    url: "/consignment",
    requirePermission: true,
    checkPermission: "consignments",
  },
  {
    label: "Invoices",
    Icon: IconFileText,
    url: "/invoices/view",
    requirePermission: true,
    checkPermission: "invoices",
  },

  {
    label: "Reports",
    Icon: IconPieChart,
    url: "/reports",
    requirePermission: true,
    checkPermission: "reports",
    children: [
      {
        label: "Templates",
        Icon: IconFileText,
        url: "/reports/workbench",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Data Export",
        Icon: IconDatabase,
        url: "/reports/data_export",
        requirePermission: false,
        checkPermission: "",
      },
    ],
  },

  {
    label: "Accounting",
    Icon: IconDollarSign,
    url: "/accounts",
    requirePermission: true,
    checkPermission: "accounts",
    children: [
      {
        label: "Ledger Group",
        Icon: IconActivity,
        url: "/accounts/ledger_group",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Ledger Master",
        Icon: IconActivity,
        url: "/accounts/ledger_master",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Vouchers",
        Icon: IconActivity,
        url: "/accounts/vouchers",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Payables",
        Icon: IconActivity,
        url: "/accounts/payables",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Receivables",
        Icon: IconActivity,
        url: "/accounts/recievables",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Income / Expense",
        Icon: IconActivity,
        url: "/accounts/income_expense",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Transaction History",
        Icon: IconActivity,
        url: "/accounts/transaction_history",
        requirePermission: false,
        checkPermission: "",
      },
    ],
  },

  {
    label: "Business Parties",
    Icon: IconBriefcase,
    url: "/business_party",
    requirePermission: true,
    checkPermission: "business_party",
    children: [
      {
        label: "Clients",
        Icon: IconFileText,
        url: "/master/client",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Suppliers",
        Icon: IconDatabase,
        url: "/master/supplier",
        requirePermission: false,
        checkPermission: "",
      },
    ],
  },
  {
    label: "Masters",
    Icon: IconList,
    url: "/master",
    requirePermission: true,
    checkPermission: "master",
    children: [
      {
        label: "Vehicle",
        Icon: IconTruck,
        url: "/master/vehicle",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Driver",
        Icon: IconUser,
        url: "/master/driver",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Location",
        Icon: IconMapPin,
        url: "/master/location",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Tyres",
        Icon: IconWheel,
        url: "/tyre/master",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Fuel",
        Icon: IconActivity,
        url: "/fuel",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Material",
        Icon: IconBox,
        url: "/master/load_material",
        requirePermission: false,
        checkPermission: "",
      },
      {
        label: "Accounting",
        Icon: IconDollarSign,
        url: "/master/accounts",
        requirePermission: true,
        checkPermission: "accounts",
        children: [
          {
            label: "Cash",
            Icon: IconActivity,
            url: "/master/cash",
            requirePermission: false,
            checkPermission: "",
          },
          {
            label: "Bank",
            Icon: IconActivity,
            url: "/master/bank",
            requirePermission: false,
            checkPermission: "",
          },
          {
            label: "Income Type",
            Icon: IconActivity,
            url: "/master/income",
            requirePermission: false,
            checkPermission: "",
          },
          {
            label: "Expense Type",
            Icon: IconActivity,
            url: "/master/expense",
            requirePermission: false,
            checkPermission: "",
          },
          {
            label: "Charges Type",
            Icon: IconActivity,
            url: "/master/charges",
            requirePermission: false,
            checkPermission: "",
          },
        ],
      },
    ],
  },

  {
    label: "Users",
    Icon: IconUsers,
    url: "/user_management/users",
    requirePermission: true,
    checkPermission: "user_management",
  },
  {
    label: "Branches",
    Icon: IconGitMerge,
    url: "/branch",
    requirePermission: true,
    checkPermission: "branch_management",
  },

  // {
  //   label: "Settings",
  //   Icon: IconSettings,
  //   url: "/settings",
  //   requirePermission: true,
  //   checkPermission: "settings",
  //   children: [
  //     {
  //       label: "Business Rules",
  //       Icon: IconSliders,
  //       url: "/business_rules",
  //       requirePermission: true,
  //       checkPermission: "business_rules",
  //     },
  //   ],
  // },
];
