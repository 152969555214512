import React, { useState, useEffect } from "react";
// Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import Form from "../../../components/form/custom";
import ClientsInList from "../../../components/consignment/clientsInList";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

// Functions
import * as api from "../../../api_service/api";
import {
  localDate,
  formatTypeSearch,
  formatCurrency,
  formatDateToUTC,
  mongoUTC,
} from "../../../functions/common";

// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
import { resp } from "../../../functions/responseHandler";

export default function Grid() {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [state, setState] = useState({
    date_filter: "",
    date_type: "",
    start_date: "",
    end_date: "",
    client: "",
    vehicle_type: "",
  });
  const [defaultState, setDefaultState] = useState({
    vehicle_type: [],
    client: [],
  });
  const [tableData, setTableData] = useState([]);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    setDefaults();
  }, []);

  function setDefaults() {
    let prevState = { ...state };
    const currentYear = new Date().getFullYear();
    const startData = new Date(currentYear, 0, 1);
    const endData = new Date(currentYear, 11, 31);

    prevState["date_filter"] = "this_year";
    prevState["date_type"] = "date";
    prevState["start_date"] = new Date(currentYear, 0, 1);
    prevState["end_date"] = new Date(currentYear, 11, 31);

    setState(prevState);
  }

  function getQuery(data) {
    let response = {};
    // Checking Date Filter

    response["start_date"] = mongoUTC(data?.start_date);
    response["end_date"] = mongoUTC(data?.end_date);

    // response["start_date"] = (data?.start_date);
    // response["end_date"] = (data?.end_date);

    if (data?.client) {
      response["client"] = data?.client?._id;
    }
    if (data?.vehicle_type) {
      response["vehicle_type"] = data?.vehicle_type?._id;
    }

    return response;
  }

  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    let query = getQuery({ ...state });
    console.log("query:", query);

    api
      .postService("client_agreements/price_history", query)
      .then(async (response) => {
        var data = response?.data?.data;
        console.log("response:", response);
        setRawData(data);
        await handlResponse(data);
        setIsProcessing(false);
        // RenderTemplate({ render: true, filter: query, data: data });
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function handlResponse(data) {
    let tableData = await mapTableData(data);
    console.log("tableData:", tableData);
    setTableData(tableData);
  }

  // Mapping Grid Data From API
  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Origin",
        field: "origin",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Destination",
        field: "destination",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Vehicle Type",
        field: "vehicle_type",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Effect From",
        field: "effect_from",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },

      {
        title: "Amount",
        field: "cost",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      groupBy:"vehicle_type",
      filter: true,
      filterFields: [],
    };
    return response;
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data

  ////// COMMON FUNCTIONS //////

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // Date Filter Changes
  function changeDateFilter(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState[name] = value;
    var date = new Date();

    let startDate = date;
    let endDate = date;
    let dateType = "date";

    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    switch (value) {
      case "this_year":
        startDate = new Date(currentYear, 0, 1);
        endDate = new Date(currentYear, 11, 31);
        dateType = "date";
        break;

      case "last_year":
        startDate = new Date(previousYear, 0, 1);
        endDate = new Date(previousYear, 11, 31);
        dateType = "date";
        break;

      case "this_month":
        startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        endDate = date;
        dateType = "date";
        break;

      case "last_month":
        startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "date";
        break;

      case "last_3_months":
        startDate = new Date(date.getFullYear(), date.getMonth() - 3, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "month";
        break;

      case "last_6_months":
        startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "month";
        break;

      // case "last_year":
      //   startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
      //   endDate = new Date(date.getFullYear(), date.getMonth(), 0);
      //   filter = { start_date: startDate, end_date: endDate };
      //   loadHighlights(filter);

      case "custom_date":
        break;

      default:
        break;
    }

    prevState.start_date = startDate;
    prevState.end_date = endDate;
    prevState.date_type = dateType;

    setState(prevState);
  }

  // TYPE AHEAD CHANGES
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  async function loadDefaultMetrics(event, key, reload = false) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (defaultState[key].length === 0 || reload) {
      switch (key) {
        case "client":
          url = "client/status/active";
          await fetchDefaultMetrics(url, key);
          break;

        case "vehicle_type":
          url = "master/vehicle_type";
          await fetchDefaultMetrics(url, key);
          break;

        default:
          break;
      }
    }
  }
  function mapDefaultStateData(key, data) {
    return data;
  }
  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  const NoDataPlaceHolderElement = () => {
    return (
      <div class="h-full flex items-center">
        <div class="mx-auto text-center">
          <div class="mt-3">
            <div class="font-medium">No records found.</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <>
        <Container>
          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title className="text-lg font-medium">
              Price History
            </Container.Title>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing && <ProcessLoader />}

            {/*  <!-- BEGIN: MAIN CONTENT -->  */}
            <div className="chat grid grid-cols-12 gap-5">
              {/* <!-- BEGIN: Filters --> */}
              <div className="col-span-12 lg:col-span-3 xxl:col-span-2">
                {/* <!--BEGIN: FILTERS --> */}
                <div id="filters" className="my-2">
                  {/* <!-- BEGIN: FILTER Form --> */}
                  <Form onSubmit={onSubmit}>
                    {/* <!-- BEGIN: Date Field --> */}
                    <Form.Col>
                      <Form.Label>Date</Form.Label>
                      <select
                        className="form-select"
                        name="date_filter"
                        value={state.date_filter}
                        onChange={changeDateFilter}
                      >
                        <option value="this_year" defaultValue>
                          Current Year
                        </option>
                        <option value="last_year">Previous Year</option>
                        <option value="this_month">This month</option>
                        <option value="last_month">Last month</option>
                        <option value="last_3_months">Last 3 months</option>
                        <option value="last_6_months">Last 6 months</option>
                        <option value="custom_date">Custom date</option>
                      </select>
                    </Form.Col>
                    {/* <!-- BEGIN:  Custom Date -->*/}
                    {state?.date_filter === "custom_date" && (
                      <>
                        <Form.Col>
                          <Form.Label>From</Form.Label>
                          <input
                            type="date"
                            className="form-control"
                            id="start_date"
                            name="start_date"
                            onChange={changeInput}
                            value={state?.start_date}
                            required
                          />
                        </Form.Col>

                        <Form.Col>
                          <Form.Label>To</Form.Label>
                          <input
                            type="date"
                            className="form-control"
                            id="end_date"
                            name="end_date"
                            onChange={changeInput}
                            value={state?.end_date}
                            required
                          />
                        </Form.Col>
                      </>
                    )}
                    {/* <!-- END:  Custom Date -->*/}
                    {/* <!-- END: Date Field --> */}

                    {/* <!--BEGIN : Conditional Fiels --> */}

                    <Form.Col>
                      <Form.Label>Client</Form.Label>
                      <Typeahead
                        clearButton
                        id="client"
                        name="client"
                        key="client"
                        // filterBy={["name", "contact_number"]}
                        // filterBy={"name"}
                        onFocus={(event) => {
                          loadDefaultMetrics(event, "client");
                        }}
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "client");
                        }}
                        options={defaultState?.client}
                        labelKey={"name"}
                        // labelKey={option => `${option?.name} ${option?.contact_number}`}
                        selected={formatTypeSearch(state?.client)}
                        inputProps={{ required: true }}
                        placeholder="Select Client"
                        renderMenu={(results, menuProps) => (
                          <Menu {...menuProps}>
                            {results.map((result, index) => (
                              <MenuItem
                                option={result}
                                position={index}
                                key={index}
                              >
                                <ClientsInList
                                  key={"client" + index}
                                  data={result}
                                  id={"client_" + index}
                                  display={["phone"]}
                                />
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      />
                    </Form.Col>

                    <Form.Col>
                      <Form.Label>Vehicle Type</Form.Label>
                      <Typeahead
                        clearButton
                        id="vehicle_type"
                        name="vehicle_type"
                        onFocus={(event) => {
                          loadDefaultMetrics(event, "vehicle_type");
                        }}
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "vehicle_type");
                        }}
                        labelKey={"vehicle_type"}
                        options={defaultState?.vehicle_type}
                        selected={formatTypeSearch(state?.vehicle_type)}
                        placeholder="Search vehicle type..."
                      />
                    </Form.Col>

                    {/* <!--END : Conditional Fiels --> */}
                    {/* <!-- END: 1st Row --> */}
                    <input
                      type="submit"
                      id="apply_filters"
                      className="hidden"
                    />
                  </Form>
                  {/* <!-- END: FILTER Form --> */}
                </div>
                <label
                  type="button"
                  className="btn btn-primary mt-2 w-full"
                  htmlFor="apply_filters"
                >
                  <i class="fa fa-refresh mr-2" aria-hidden="true"></i> View
                  History
                </label>
                {/* <!--END: FILTERS --> */}
              </div>
              {/* <!-- END: Filters --> */}

              {/* <!-- BEGIN: Table Content --> */}
              <div className="col-span-12 lg:col-span-9 xxl:col-span-10">
                <div className="chat__box box">
                  {rawData?.length > 0 ? (
                    <CustomDataTable
                      header={tableData?.header}
                      content={tableData?.content}
                      groupBy={tableData?.groupBy}
                      filter={true}
                      filterFields={tableData?.filterFields}
                    />
                  ) : (
                    <NoDataPlaceHolderElement />
                  )}
                </div>
              </div>
              {/* <!-- END: Table Content --> */}
            </div>
            {/*  <!-- END: MAIN CONTENT -->  */}
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
