import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import InLineLoader from "../../components/preloader/inLineLoader";
import PropTypes from "prop-types";
// HTML Components
import { Modal } from "react-bootstrap";
import ImageNFileInput from "../inputs/imagenfile";
import ProfileImage from "../inputs/profileImage";
import DividerText from "../htmlElements/dividerText";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeAhAddNewButton from "../htmlElements/typeAhAddNewButton";
import PhoneInput from "../htmlElements/phoneInput";
// External Components
import DesignationMaster from "./designation";
import UserRolesMaster from "./user_roles";

// Functions
import { resp } from "../../functions/responseHandler";
import { storageServices } from "../../functions/storage";
import {
  formatTypeSearch,
  formatTypeaheadCommon,
  JsonToFormData,
  htmlDate,
  matchData,
  getAdultMaxDate,
  preventTabClosing,
} from "../../functions/common";
import * as api from "../../api_service/api";

const _ = require("lodash");
const fileBaseURL = process.env.REACT_APP_IMAGE_URL;

const countryListFile = require("../../utils/country_state_list.json");
const countryStateList = countryListFile.countries;
const countryList = countryStateList.map((a) => a.country);

// State Model
const Model = {
  fullname: "",
  email: "",
  role: "",
  organization: "",
  activated: false,
  application_user: true,
  designation: "",
  status: "active",
  display_picture: "",
  phone: "",
  employee_type: "",

  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",

  is_admin: false,
  is_owner: false,
  enable_branch: true,

  dob: "",
  license_proof: "",
  valid_from: "",
  valid_to: "",
  license_no: "",
  approved: false,
  is_employee: true,
  new_user: false,
  driver_profile: {},
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);

  const [mode, setMode] = useState({ view: false, edit: false, create: false });
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [originalData, setOriginalData] = useState();
  const [record_id, setRecordId] = useState();
  const [localUser, setLocalUser] = useState();
  // Default State
  const [defaultState, setDefaultState] = useState({
    role: [],
    organization: [],
    designation: [],
    state: [],
    country: countryList,
  });

  // External Compoentnts / Modal State
  const [designationMasterModal, setDesignationMasterModal] = useState({
    show: false,
    action: "create",
    title: "Add Designation",
    id: "",
  });

  const [userRolesMasterModal, setUserRolesMasterModal] = useState({
    show: false,
    action: "create",
    title: "Add User Role",
    id: "",
  });

  const [loader, setLoader] = useState({
    create: false,
    update: false,
  });

  useEffect(() => {
    // preventTabClosing();
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    // setDefaults();
    handleUserAction(props);
  }, []);

  async function setDefaults() {
    const defaultCountry = "India";
    setState((prevState) => ({
      ...prevState,
      country: defaultCountry,
    }));

    let obj = await countryStateList.find((o) => o.country === defaultCountry);

    if (typeof obj !== "undefined" && obj.states.length > 0) {
      setDefaultState((prevState) => ({
        ...prevState,
        state: obj.states,
      }));
    }
  }

  // <!-- BEGIN : Designation Master Functions-- >
  function reloadDesignation() {
    closeDesignationMasterModal();
    loadDesignation(true);
  }

  function openDesignationMasterModal(event) {
    event.preventDefault();
    setDesignationMasterModal((prevState) => ({ ...prevState, show: true }));
  }

  const closeDesignationMasterModal = () =>
    setDesignationMasterModal((prevState) => ({ ...prevState, show: false }));

  // <!-- END : Designation Master Functions-- >

  // <!-- BEGIN : User Roles Master Functions-- >
  function reloadUserRoles() {
    closeUserRolesMasterModal();
    loadUserRoles(true);
  }

  function openUserRolesMasterModal(event) {
    event.preventDefault();
    setUserRolesMasterModal((prevState) => ({ ...prevState, show: true }));
  }

  function closeUserRolesMasterModal() {
    setUserRolesMasterModal((prevState) => ({ ...prevState, show: false }));
  }

  // <!-- END : User Roles Master Functions-- >

  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setState({ ...Model });
        setDefaults();
        setMode((prevState) => ({ ...prevState, create: true }));
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  // Default Metrics

  async function loadDesignation(reload = false) {
    // event.preventDefault();
    if (defaultState?.role?.length == 0 || reload) {
      setIsProcessing(true);
      await api
        .getService("designation/status/active")
        .then(async (response) => {
          await setDefaultState((prevState) => ({
            ...prevState,
            designation: response?.data?.data,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    }
  }

  async function loadUserRoles(reload = false) {
    // event.preventDefault();
    if (defaultState?.role?.length == 0 || reload) {
      setIsProcessing(true);
      await api
        .getService("user_role/status/active")
        .then(async (response) => {
          await setDefaultState((prevState) => ({
            ...prevState,
            role: response?.data?.data,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    }
  }

  async function loadOrganization() {
    // event.preventDefault();
    if (defaultState?.organization?.length == 0) {
      setIsProcessing(true);
      await api
        .getService("organization/status/active")
        .then(async (response) => {
          await setDefaultState((prevState) => ({
            ...prevState,
            organization: response?.data?.data,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    }
  }

  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`user/${id}`).then(
      async (result) => {
        const data = result.data;

        let mappedData = await mapData(data.data);
        setState(mappedData);
        setOriginalData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  async function mapData(data) {
    let matchedData = matchData(state, data);

    data.dob = htmlDate(data?.dob);

    if (
      typeof data.organization !== "undefined" &&
      data.organization.length > 0
    ) {
      matchedData.enable_branch = true;
    } else {
      matchedData.enable_branch = false;
    }

    // Setting States List
    let obj = await countryStateList.find((o) => o.country === data.country);
    if (typeof obj !== "undefined" && obj.states.length > 0) {
      setDefaultState((prevState) => ({
        ...prevState,
        state: obj.states,
      }));
    }

    if (data?.designation?.employee_type === "driver") {
      matchedData.dob = htmlDate(data?.driver_profile?.dob);
      matchedData.license_proof = data?.driver_profile?.license_proof;
      matchedData.valid_from = htmlDate(data?.driver_profile?.valid_from);
      matchedData.valid_to = htmlDate(data?.driver_profile?.valid_to);
      matchedData.license_no = data?.driver_profile?.license_no;
    }

    return matchedData;
  }

  async function prepareData(data, action) {
    // Cheking driver
    if (data?.designation?.employee_type === "driver") {
      data["employee_type"] = "driver";

      // Checking approval
      if (action === "create" && localUser?.access?.approve_master) {
        data["approved"] = true;
      }

      if (action === "update") {
        data.driver_profile = data?.driver_profile?._id;
      }
    } else {
      delete data.driver_profile;
    }

    data.designation = data?.designation?._id;

    // Deleting designation if not set
    if (typeof data?.designation === "undefined" || data?.designation === "") {
      delete data?.designation;
    }

    if (data?.application_user) {
      data.role = await data?.role.map((a) => a?._id);

      if (
        action === "create" ||
        (action === "update" && !originalData?.application_user)
      ) {
        data.new_user = true;
      }
    } else {
      delete data.role;
    }

    // Deleting Branch
    if (
      data?.enable_branch &&
      typeof data?.organization !== "undefined" &&
      data?.organization.length > 0
    ) {
      data.organization = await data?.organization.map((a) => a?._id);
    } else {
      delete data.organization;
    }
    return data;
  }
  async function onSubmit(event) {
    event.preventDefault();
    setLoader((prevState) => ({ ...prevState, create: true }));
    var data = await prepareData({ ...state }, "create");

    console.log("data:", data);

    // var query = await JsonToFormData(data);

    // api
    //   .DatapostService("user/create", query)
    //   .then((response) => {
    //     setLoader((prevState) => ({ ...prevState, create: false }));
    //     resp.Success(response?.data?.message);
    //     props.callBack();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoader((prevState) => ({ ...prevState, create: false }));
    //     resp.ErrorHandler(error);
    //   });
  }

  async function onUpdate(event) {
    event.preventDefault();
    setLoader((prevState) => ({ ...prevState, update: true }));
    var data = await prepareData({ ...state }, "update");
    var query = await JsonToFormData(data);

    api
      .DataputService(`user/${record_id}`, query)
      .then((response) => {
        setLoader((prevState) => ({ ...prevState, update: false }));
        console.log("response:", response.data);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setLoader((prevState) => ({ ...prevState, update: false }));
        resp.ErrorHandler(error);
      });
  }

  function changeInput(event) {
    const { name, value, checked } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name == "application_user") {
      setState((prevState) => ({ ...prevState, [name]: !state[name] }));
    }
  }

  // Input Change
  async function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));

      // ------ Loading States -------
      if (name === "country") {
        let obj = await countryStateList.find((o) => o.country === selected[0]);
        if (typeof obj !== "undefined" && obj.states.length > 0) {
          setDefaultState((prevState) => ({
            ...prevState,
            state: obj.states,
          }));
        }
      }
      // ------ Loading States -------
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function changeTypeHeadInputMultiple(selected, name) {
    setState((prevState) => ({ ...prevState, [name]: selected }));
  }

  async function changeTypeHeadInputUserRole(selected, name) {
    setState((prevState) => ({ ...prevState, [name]: selected }));

    let isAdmin = false;
    let isOwner = false;

    for (var i in selected) {
      // Checking Owner Access
      if (
        (typeof selected[i].is_owner !== "undefined" && selected[i].is_owner) ||
        isOwner
      ) {
        isOwner = true;
        setState((prevState) => ({ ...prevState, is_owner: true }));
        setState((prevState) => ({ ...prevState, enable_branch: false }));
        break;
      }

      // Checking Admin Access
      else if (
        (typeof selected[i].is_admin !== "undefined" && selected[i].is_admin) ||
        isAdmin
      ) {
        isAdmin = true;
        setState((prevState) => ({ ...prevState, is_admin: true }));
        setState((prevState) => ({ ...prevState, is_owner: false }));

        if (selected[i].module_access.length > 0) {
          let admin_modules = ["user_management", "branch_management"];
          // Checking array having other than user / branch management
          var difference = await _.xor(
            selected[i].module_access,
            admin_modules
          );

          if (difference.length > 0) {
            setState((prevState) => ({ ...prevState, enable_branch: true }));
            break;
          } else {
            setState((prevState) => ({ ...prevState, enable_branch: false }));
          }
        } else {
          setState((prevState) => ({ ...prevState, enable_branch: false }));
        }
      } else {
        setState((prevState) => ({ ...prevState, is_owner: false }));
        setState((prevState) => ({ ...prevState, is_admin: false }));
        setState((prevState) => ({ ...prevState, enable_branch: true }));
      }

      // Checking If user other module access if admin
    }
  }

  function deleteInputFile(event, name) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [name]: "" }));
  }

  async function changeInputFile(event) {
    const { name, files } = event.target;
    if (files[0]) {
      setState((prevState) => ({ ...prevState, [name]: files[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  // UI
  return (
    <>
      {/* <!-- BEGIN: Designation Modal --> */}
      {designationMasterModal.show && (
        <DesignationMaster
          show={designationMasterModal.show}
          action={designationMasterModal.action}
          title={designationMasterModal.title}
          onHide={closeDesignationMasterModal}
          callBack={reloadDesignation}
          id={designationMasterModal.id}
        />
      )}
      {/* <!-- END: Designation Modal --> */}

      {/* <!-- BEGIN: User Roles Modal --> */}
      {userRolesMasterModal.show && (
        <UserRolesMaster
          show={userRolesMasterModal.show}
          action={userRolesMasterModal.action}
          title={userRolesMasterModal.title}
          onHide={closeUserRolesMasterModal}
          callBack={reloadUserRoles}
          id={userRolesMasterModal.id}
        />
      )}
      {/* <!-- END: User Roles Modal --> */}

      <div
        key="supplier_modal"
        id="supplier_modal"
        // onClick={(e) => e.stopPropagation()}
      >
        {/* <!-- BEGIN: Modal Content --> */}
        <Modal
          {...props}
          scrollable={true}
          backdrop="static"
          keyboard={false}
          onHide={props.onHide}
          size="lg"
        >
          <Modal.Header closeButton>
            <h2 className="font-medium text-base mr-auto">{props.title}</h2>
          </Modal.Header>

          <Modal.Body>
            {isProcessing && <ProcessLoader />}
            <form
              className="grid grid-cols-12 gap-4 gap-y-3"
              onSubmit={
                userAction === "create"
                  ? onSubmit
                  : userAction === "edit"
                  ? onUpdate
                  : (e) => e.preventDefault()
              }
            >
              <div className="col-span-12 text-center">
                <ProfileImage
                  mode={mode}
                  state={state?.display_picture}
                  name="display_picture"
                  onDelete={deleteInputFile}
                  onChange={changeInputFile}
                  className="text-center mb-2"
                />

                <div className="mt-2">
                  <input
                    id="application_user"
                    name="application_user"
                    className="form-check-input"
                    type="checkbox"
                    onChange={changeInput}
                    checked={state.application_user}
                  />

                  <label
                    htmlFor="application_user"
                    className="form-check-label"
                  >
                    Application User
                  </label>
                </div>
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="name" className="form-label required">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  name="fullname"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.fullname}
                  required
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="dob" className="form-label">
                  D.O.B
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  name="dob"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.dob}
                  // max={htmlDate(new Date())}
                  // max={htmlDate(getAdultMaxDate())}
                  max={
                    state?.designation?.employee_type === "driver"
                      ? htmlDate(getAdultMaxDate())
                      : htmlDate(new Date())
                  }
                />
              </div>

              {state.application_user && (
                <>
                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="email" className="form-label required">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder=""
                      disabled={mode.view || mode?.edit}
                      onChange={changeInput}
                      value={state.email}
                      required
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="supplier_type"
                      className="form-label required"
                    >
                      User Role
                    </label>
                    <Typeahead
                      clearButton
                      multiple
                      id="role"
                      name="role"
                      key="role"
                      onFocus={loadUserRoles}
                      onChange={(selected) => {
                        changeTypeHeadInputUserRole(selected, "role");
                      }}
                      labelKey={(option) => `${option?.name}`}
                      options={defaultState?.role}
                      // inputProps={{ required: true }}
                      selected={state?.role}
                      placeholder="Select User Role"
                      disabled={mode.view}
                      renderMenu={(results, menuProps) => (
                        <Menu {...menuProps}>
                          {results.map((result, index) => (
                            <MenuItem
                              option={result}
                              position={index}
                              key={index}
                            >
                              {result?.name}
                            </MenuItem>
                          ))}
                          <TypeAhAddNewButton
                            onClick={openUserRolesMasterModal}
                          ></TypeAhAddNewButton>
                        </Menu>
                      )}
                    />
                  </div>
                </>
              )}

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="designation" className="form-label required">
                  Designation
                </label>
                <Typeahead
                  clearButton
                  id="role"
                  name="role"
                  key="role"
                  onFocus={loadDesignation}
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "designation");
                  }}
                  labelKey={(option) => `${option?.name}`}
                  options={defaultState?.designation}
                  inputProps={{ required: true }}
                  selected={formatTypeSearch(state?.designation)}
                  placeholder=""
                  disabled={mode.view}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                          {result?.name}
                        </MenuItem>
                      ))}
                      <TypeAhAddNewButton
                        onClick={openDesignationMasterModal}
                      ></TypeAhAddNewButton>
                    </Menu>
                  )}
                ></Typeahead>
              </div>

              {state?.enable_branch && (
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="organization" className="form-label">
                    Branch
                  </label>

                  <Typeahead
                    clearButton
                    multiple
                    id="organization"
                    name="organization"
                    key="organization"
                    onFocus={loadOrganization}
                    onChange={(selected) => {
                      changeTypeHeadInputMultiple(selected, "organization");
                    }}
                    labelKey={(option) => `${option?.name}`}
                    options={defaultState?.organization}
                    // inputProps={{ required: true }}
                    selected={state?.organization}
                    disabled={mode.view}
                  />
                </div>
              )}

              {/* <!-- BEGIN: Driver details -- > */}
              {state?.designation?.employee_type === "driver" && (
                <>
                  <div className="col-span-12">
                    <DividerText>License Information</DividerText>
                  </div>

                  <div className="col-span-12">
                    <label className="form-label required">
                      License number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="license_no"
                      name="license_no"
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.license_no}
                      required
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label className="form-label required">Valid From</label>
                    <input
                      type="date"
                      className="form-control"
                      id="valid_from"
                      name="valid_from"
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.valid_from}
                      max={htmlDate(new Date())}
                      required
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label className="form-label required">Valid To</label>
                    <input
                      type="date"
                      className="form-control"
                      id="valid_to"
                      name="valid_to"
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.valid_to}
                      min={htmlDate(state?.valid_from)}
                      required
                    />
                  </div>

                  {/* License Image */}
                  <div className="col-span-12 form-group w-full justify-center">
                    <label
                      className="form-label required"
                      htmlFor="license_proof"
                    >
                      License proof
                    </label>
                    <ImageNFileInput
                      mode={mode}
                      state={state?.license_proof}
                      name="license_proof"
                      onDelete={deleteInputFile}
                      onChange={changeInputFile}
                      baseURL={fileBaseURL}
                    />
                  </div>
                  {/* License Image */}
                </>
              )}

              {/* <!-- END: Driver details -- > */}

              <div className="col-span-12">
                <DividerText>Basic Profile</DividerText>
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="address_line_1" className="form-label">
                  Address Line 1
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address_line_1"
                  name="address_line_1"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.address_line_1}
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="address_line_2" className="form-label">
                  Address Line 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address_line_2"
                  name="address_line_2"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.address_line_2}
                />
              </div>

              <div className="col-span-12 sm:col-span-4">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.city}
                />
              </div>

              <div className="col-span-12 sm:col-span-4">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <Typeahead
                  id="city"
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "state");
                  }}
                  options={defaultState.state}
                  labelKey={(option) => `${option}`}
                  selected={formatTypeaheadCommon(state?.state)}
                  // inputProps={{ required: true }}
                  disabled={mode.view}
                />
              </div>

              <div className="col-span-12 sm:col-span-4">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <Typeahead
                  id="country"
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "country");
                  }}
                  options={defaultState.country}
                  labelKey={(option) => `${option}`}
                  selected={formatTypeaheadCommon(state?.country)}
                  // inputProps={{ required: true }}
                  disabled={mode.view}
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="zip_code" className="form-label">
                  Pin Code
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="zip_code"
                  name="zip_code"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.zip_code}
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <PhoneInput
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state?.phone}
                />
              </div>

              <input type="submit" id="save_user" className="hidden" />
              <input type="submit" id="update_user" className="hidden" />
            </form>
          </Modal.Body>
          {/* <!-- END: Modal Body --> */}
          {/* <!-- BEGIN: Modal Footer --> */}
          <Modal.Footer>
            {" "}
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-outline-secondary w-20 mr-1"
              onClick={props.onHide}
            >
              Cancel
            </button>{" "}
            {userAction === "create" && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="save_user"
              >
                Save {loader?.create ? <InLineLoader /> : null}
              </label>
            )}
            {userAction === "edit" && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="update_user"
              >
                Update {loader?.update ? <InLineLoader /> : null}
              </label>
            )}
          </Modal.Footer>
          {/* <!-- END: Modal Footer --> */}
        </Modal>

        {/* <!-- END: Modal Content --> */}
      </div>
    </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "User",
  action: "view",
  onSave() {},
  callBack() {},
};
