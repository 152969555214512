import React, { useState, useEffect } from "react";
// HTML Components
import Container from "../../components/container";
import Form from "../../components/form/custom";
import Card from "../../components/card/custom";
import CurrencyInput from "../../components/htmlElements/currencyInput";
import TruckNumberDisplay from "../../components/consignment/truckNumberDisplay";
import ClientsInList from "../../components/consignment/clientsInList";
import SuppliersInList from "../../components/consignment/suppliersInList";
import DriversInList from "../../components/consignment/driversInList";
import ArrayAddRemoveButtons from "../../components/htmlElements/arrayAddRemoveButtons";
import CallButton from "../../components/htmlElements/callButton";
import SavedConsignmentTable from "../../components/consignment/savedConsignmentTable";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeAhAddNewButton from "../../components/htmlElements/typeAhAddNewButton";

// Toaster & Notification
import { alert } from "../../components/notification/alert";
import { resp } from "../../functions/responseHandler";
// Loaders
import Preloader from "../../components/preloader/preloader";
import ProcessLoader from "../../components/preloader/processLoader";

// Add-on Components
import CityMaster from "../../components/master/location";
import ClientMaster from "../../components/master/client";
import VehicleMaster from "../../components/master/vehicle";
import DriverMaster from "../../components/master/driver";
import SupplierMaster from "../../components/master/supplier";

// Functions
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";
import {
  htmlDate,
  toFloat,
  mongoUTC,
  formatTypeSearch,
  randomNumber,
  isDefined,
  navToPage,
  formatCurrency,
} from "../../functions/common";

// Models
import { Model } from "./model";

const modelData = Model();
const fieldLabels = {
  unit_measure: {
    fixed: "",
    per_ton: "Ton",
    per_kg: "Kg",
    per_km: "Km",
    per_trip: "Trip",
    per_day: "Day",
    per_hour: "Hour",
    per_litre: "Litres",
    per_bag: "Bags",
  },
  billing_unit: {
    fixed: "Fixed",
    per_ton: "Per Ton",
    per_kg: "Per Kg",
    per_km: "Per Km",
    per_trip: "Per Trip",
    per_day: "Per Day",
    per_hour: "Per Hour",
    per_litre: "Per Litre",
    per_bag: "Per Bag",
  },
  unit_price: {
    fixed: "",
    per_ton: "Rate Per Ton",
    per_kg: "Rate Per Kg",
    per_km: "Rate Per Km",
    per_trip: "Rate Per Trip",
    per_day: "Rate Per Day",
    per_hour: "Rate Per Hour",
    per_litre: "Rate Per Litre",
    per_bag: "Rate Per Bag",
  },

  total_units: {
    fixed: "",
    per_ton: "Total Tons",
    per_kg: "Total Kg",
    per_km: "Total Km",
    per_trip: "Total Trips",
    per_day: "Total Days",
    per_hour: "Total Hours",
    per_litre: "Total Litres",
    per_bag: "Total Bags",
  },
};
export default function Component(props) {
  const [localUser, setLocalUser] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [state, setState] = useState([]);
  const [savedItems, setSavedItems] = useState([]);

  const [typeAheadFields, setTypeAheadFields] = useState({
    client: "",
    origin: "",
    destination: "",
    location: "",
    vehicle: "",
    driver: "",
    supplier: "",
  });

  const [defaultState, setDefaultState] = useState({
    city: [],
    location: [],
    user: [],
    client: [],
    driver: [],
    vehicle: [],
    supplier: [],
  });

  //////////// External Modal States   ////////////
  const [cityMasterModal, setCityMasterModal] = useState({
    show: false,
    title: "Add Place",
    action: "create",
    id: "",
    state_key: "",
  });

  const [clientMasterModal, setClientMasterModal] = useState({
    show: false,
    title: "Add Client",
    action: "create",
    id: "",
  });

  const [vehicleMasterModal, setVehicleMasterModal] = useState({
    show: false,
    title: "Add Vehicle",
    action: "create",
    id: "",
  });

  const [driverMasterModal, setDriverMasterModal] = useState({
    show: false,
    title: "Add Driver",
    action: "create",
    id: "",
  });

  const [supplierMasterModal, setSupplierMasterModal] = useState({
    show: false,
    title: "Add Vehicle Supplier",
    action: "create",
    id: "",
  });

  const [expenseFormModal, setExpenseFormModal] = useState({
    show: false,
    action: "",
    data: {},
    id: "",
  });

  const [paymentFormModal, setPaymentFormModal] = useState({
    show: false,
    action: "create",
    data: "",
    index: "",
    supplier_max_limit: 0,
    client_max_limit: 0,
    disableSupplierPayment: true,
    disableClientPayment: true,
  });

  const [advanceFormModal, setAdvanceFormModal] = useState({
    show: false,
    action: "create",
    id: "",
    disableSupplierPayment: true,
    disableClientPayment: true,
    supplier_amount: 0,
    client_amount: 0,
  });

  //////////// External Modal States   ////////////

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      setDefaults(localUserData);
      setIsLoaded(true);
    };
    getUser();
  }, []);

  function setDefaults(localUserData) {
    let newState = [getNewRecord(localUserData)];
    setState(newState);
  }

  function getNewRecord(localUserData = localUser) {
    let newState = Model();
    newState.consignment_date = htmlDate(new Date());
    newState.charges.client_billing_unit = "fixed";
    newState.charges.consignment_billing_unit = "fixed";
    newState.primary_lr_number = "LR-" + randomNumber(4);

    newState.vehicle_ownership_type = "supplier";
    newState.status = "drafted";
    // newState.created_business_type = "transporter";
    newState.created_business_type =
      localUserData?.customer_account?.business_type || "broker";

    return newState;
  }

  // <!-- BEGIN: City Master Modal -- >
  function openCityMasterModal(event, state_key) {
    let prevState = { ...cityMasterModal };
    prevState.show = true;
    prevState.state_key = state_key;
    setCityMasterModal(prevState);
  }

  const closeCityMasterModal = () =>
    setCityMasterModal((prevState) => ({ ...prevState, show: false }));

  function reloadCityMasterData(data) {
    closeCityMasterModal();
    let event = {};
    loadDefaultMetrics(
      event,
      "location",
      true,
      data,
      cityMasterModal?.state_key
    );
  }
  // <!-- END: City Master Modal -- >

  // <!-- BEGIN: Client Master Modal -- >

  const openClientMasterModal = () =>
    setClientMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeClientMasterModal = () =>
    setClientMasterModal((prevState) => ({ ...prevState, show: false }));

  function reloadClientMasterData(data) {
    closeClientMasterModal();
    let event = {};
    loadDefaultMetrics(event, "client", true, data, "client");
  }

  // <!-- END: City Master Modal -- >

  // <!-- BEGIN: Vehicle Master Modal -- >
  const openVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadVehicleMasterData(data) {
    closeVehicleMasterModal();
    let event = {};
    loadDefaultMetrics(event, "vehicle", true, data, "vehicle");
  }
  // <!-- END: Vehicle Master Modal -- >

  // <!-- BEGIN: Driver Master Modal -- >
  const openDriverMasterModal = () =>
    setDriverMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeDriverMasterModal = () =>
    setDriverMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadDriverMasterData(data) {
    closeDriverMasterModal();
    let event = {};
    loadDefaultMetrics(event, "driver", true, data, "driver");
  }
  // <!-- END: Driver Master Modal -- >

  // <!-- BEGIN: Supplier Master Modal -- >
  const openSupplierMasterModal = () =>
    setSupplierMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeSupplierMasterModal = () =>
    setSupplierMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadSupplierMasterData(data) {
    closeSupplierMasterModal();
    let event = {};
    loadDefaultMetrics(event, "supplier", true, data, "supplier");
  }
  // <!-- END: Supplier Master Modal -- >

  async function prepareData() {
    var list = [...state];
    for (var i in list) {
      var query = { ...list[i] };

      query.consignment_type = "single";

      // Checking vehicle type
      // if (query.vehicle?.ownership_type === "supplier") {
      //   query.supplier = query?.supplier;
      // } else {
      //   delete query.supplier;
      // }

      query.created_by = localUser?.userId;
      query.consignment_date = await mongoUTC(query.consignment_date);
      delete query.load_receipts;
      list[i] = query;
    }

    return list;
  }

  async function saveForm(event) {
    event.preventDefault();
    let submit_action = event.nativeEvent.submitter.name;
    switch (submit_action) {
      case "save_form":
        onCreate(event, false);
        break;

      case "save_and_create_form":
        onCreate(event, true);
        break;
      default:
        break;
    }
  }

  async function onCreate(event, createNew = false) {
    setIsProcessing(true);

    var query = await prepareData();

    api
      .postService("consignment", query)
      .then((response) => {
        resp.SuccessResponse(response);
        reloadPage(createNew, query);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function reloadPage(createNew, data) {
    if (createNew) {
      setSavedItems((oldArray) => [...oldArray, ...data]);

      // let prevItems = [...savedItems];
      // prevItems.push(data);
      // setSavedItems(prevItems);
      setState([getNewRecord()]);
    } else {
      navToPage("/admin#/consignment/drafted");
    }
  }

  const changeInput = (event, index) => {
    const { name, value } = event.target;
    const list = [...state];
    list[index][name] = value;
    setState(list);
  };

  const changeInputCharges = (event, index) => {
    const { name, value } = event.target;
    const list = [...state];
    list[index]["charges"][name] = value;
    setState(list);
  };

  const changeInputFreightSupplier = (event, index) => {
    const { name, value } = event.target;
    const list = [...state];
    list[index]["charges"][name] = value;
    list[index]["charges"]["consignment_total_amount"] = value;
    setState(list);
  };

  const changeInputFreightClient = (event, index) => {
    const { name, value } = event.target;
    const list = [...state];
    list[index]["charges"][name] = value;
    list[index]["charges"]["client_total_amount"] = value;
    setState(list);
  };

  async function loadDefaultMetrics(
    event,
    key,
    reload = false,
    data = {},
    state_key
  ) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      let mappedData = await mapDefaultStateData(key, prevDefState);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: mappedData,
      }));
      // setState((prevState) => ({
      //   ...prevState,
      //   [state_key]: data,
      // }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "client":
            url = "client/status/active";
            await fetchDefaultMetrics(url, key);
            break;
          case "location":
            url = "master/location/status/active";
            await fetchDefaultMetrics(url, key);
            break;
          case "vehicle":
            url = "vehicle";
            await fetchDefaultMetrics(url, key);
            break;
          case "driver":
            url = "driver/valid";
            await fetchDefaultMetrics(url, key);
            break;
          case "supplier":
            url = "master/supplier/type/vehicle_supplier/active";
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        let data = response?.data?.data;
        if (data) {
          let mappedData = await mapDefaultStateData(key, data);
          await setDefaultState((prevState) => ({
            ...prevState,
            [key]: mappedData,
          }));
        }
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function mapDefaultStateData(key, data) {
    // Re-constructing Vehicle Data
    if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
    }
    return data;
  }

  const changeTypeHeadInput = (selected, index, name) => {
    if (selected.length != 0) {
      const list = [...state];
      list[index][name] = selected[0];

      if (name === "vehicle") {
        list[index]["vehicle_ownership_type"] =
          selected[0]?.ownership_type || "";
      }
      setState(list);
    } else {
      const list = [...state];
      list[index][name] = "";
      setState(list);
    }
  };

  const changeTypeHeadInputVehicle = async (selected, index, name) => {
    if (selected.length != 0) {
      const list = [...state];
      // Checking if already allotted for same date
      let currentConsignmentDate = list[index]["consignment_date"];
      let arrayIndex = await list.findIndex(
        (o) => o.consignment_date === currentConsignmentDate
      );

      if ((arrayIndex || arrayIndex === 0) && arrayIndex !== index) {
        alert.confirmation({
          title: "Do you want to continue?",
          text: "This vehicle is already allotted for the same date.",

          onConfirm: function () {
            onVehicleConfirm(selected, index);
          },
        });
      } else {
        list[index][name] = selected[0];
        list[index]["vehicle_ownership_type"] =
          selected[0]?.ownership_type || "";
        setState(list);
      }
    } else {
      const list = [...state];
      list[index][name] = "";
      setState(list);
    }
  };

  function onVehicleConfirm(selected, index) {
    const list = [...state];

    list[index]["vehicle"] = selected[0];
    list[index]["vehicle_ownership_type"] = selected[0]?.ownership_type || "";

    setState(list);
  }

  const changeTypeHeadInputDriver = async (selected, index, name) => {
    if (selected.length != 0) {
      const list = [...state];
      // Checking if already allotted for same date
      let currentConsignmentDate = list[index]["consignment_date"];
      let arrayIndex = await list.findIndex(
        (o) => o.consignment_date === currentConsignmentDate
      );

      if ((arrayIndex || arrayIndex === 0) && arrayIndex !== index) {
        alert.confirmation({
          title: "Do you want to continue?",
          text: "This Driver is already allotted for the same date.",

          onConfirm: function () {
            onDriverConfirm(selected, index);
          },
        });
      } else {
        list[index][name] = selected[0];
        setState(list);
      }
    } else {
      const list = [...state];
      list[index][name] = "";
      setState(list);
    }
  };

  function onDriverConfirm(selected, index) {
    const list = [...state];

    list[index]["driver"] = selected[0];

    setState(list);
  }

  function changeInputTypeaheadFields(text, field) {
    setTypeAheadFields((prevState) => ({ ...prevState, [field]: text }));
  }

  function changeInputSupplierUnitFields(event, index) {
    const { name, value } = event.target;
    let prevState = [...state];
    prevState[index]["charges"][name] = value;

    let charges = prevState[index]["charges"];
    let total =
      toFloat(charges?.consignment_unit_price || 0) *
      toFloat(charges?.consignment_total_units || 0);
    total = total.toFixed(2);

    prevState[index]["charges"]["consignment_freight"] = total;
    prevState[index]["charges"]["consignment_total_amount"] = total;
    setState(prevState);
  }

  function changeInputClientUnitFields(event, index) {
    const { name, value } = event.target;
    let prevState = [...state];
    prevState[index]["charges"][name] = value;

    let charges = prevState[index]["charges"];
    let total =
      toFloat(charges?.client_unit_price || 0) *
      toFloat(charges?.client_total_units || 0);
    total = total.toFixed(2);

    prevState[index]["charges"]["client_freight"] = total;
    prevState[index]["charges"]["client_total_amount"] = total;
    setState(prevState);
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...state];
    list.splice(index, 1);
    setState(list);
  };

  // handle click event of the Add button
  const handleAddClick = (event) => {
    event.preventDefault();
    let newState = [...state];
    newState.unshift(getNewRecord());
    setState(newState);
  };

  function onCancel(event) {
    event.preventDefault();
    alert.confirmActivation({
      title: "Are you sure?",
      text: "Changes may not be saved.",
      onConfirm: function () {
        onConfirmCancel();
      },
    });
  }

  function onConfirmCancel() {
    navToPage("/admin#/consignment");
  }
  if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <Container>
        {/* <!--BEGIN:EXTERNAL COMPONENTS -->*/}
        <>
          {cityMasterModal.show && (
            <CityMaster
              show={cityMasterModal.show}
              action={cityMasterModal.action}
              title={cityMasterModal.title}
              onHide={closeCityMasterModal}
              // callBack={(e, data) => reloadCityMasterData(e, data)}
              callBack={reloadCityMasterData}
              saveToDB={false}
              name={typeAheadFields?.location}
            />
          )}

          {clientMasterModal.show && (
            <ClientMaster
              show={clientMasterModal.show}
              action={clientMasterModal.action}
              title={clientMasterModal.title}
              onHide={closeClientMasterModal}
              callBack={reloadClientMasterData}
              name={typeAheadFields?.client}
              saveToDB={false}
            />
          )}

          {vehicleMasterModal.show && (
            <VehicleMaster
              show={vehicleMasterModal.show}
              action={vehicleMasterModal.action}
              title={vehicleMasterModal.title}
              onHide={closeVehicleMasterModal}
              callBack={reloadVehicleMasterData}
              saveToDB={false}
              registration_number={typeAheadFields?.vehicle}
            />
          )}

          {driverMasterModal.show && (
            <DriverMaster
              show={driverMasterModal.show}
              action={driverMasterModal.action}
              title={driverMasterModal.title}
              onHide={closeDriverMasterModal}
              callBack={reloadDriverMasterData}
              saveToDB={false}
              name={typeAheadFields?.driver}
            />
          )}

          {supplierMasterModal.show && (
            <SupplierMaster
              show={supplierMasterModal.show}
              action={supplierMasterModal.action}
              title={supplierMasterModal.title}
              supplier_type={"vehicle_supplier"}
              onHide={closeSupplierMasterModal}
              callBack={reloadSupplierMasterData}
              saveToDB={false}
              name={typeAheadFields?.supplier}
            />
          )}
        </>
        {/* <!--END:EXTERNAL COMPONENTS -->*/}

        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title className="text-lg font-medium">
            Trip Planning
          </Container.Title>

          <Container.HeaderActions className="ml-auto">
            <button
              id="consignment_add_button"
              className="btn btn-primary"
              onClick={handleAddClick}
            >
              <i className="fa fa-plus mr-2" aria-hidden="true"></i> Add New
            </button>
          </Container.HeaderActions>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/*  <!-- BEGIN:  Body -->  */}
        <Container.Body>
          {/* <!-- BEGIN: Card --> */}
          <Card className="mt-5">
            {isProcessing && <ProcessLoader />}
            {/* <!-- BEGIN: Card Body --> */}
            <Card.Body>
              {/* <!-- BEGIN: Form --> */}
              <Form id="consignment-form-multiple" onSubmit={saveForm}>
                {state.map((item, index) => (
                  <>
                    {/* <!-- BEGIN: Card --> */}
                    <div
                      className="border box shadow mb-2"
                      id={"consignment_" + index}
                    >
                      {/* <!-- BEGIN: Card Header--> */}
                      <div className="flex flex-col sm:flex-row items-center border-b border-gray-200 dark:border-dark-5 p-2 bg-gray-200">
                        <h2 className="font-medium text-theme-1">
                          Consignment {index + 1}
                        </h2>
                        <div id="action_buttons" className="ml-auto">
                          <ArrayAddRemoveButtons
                            id={"action-buttons-" + index}
                            addFunc={handleAddClick}
                            removeFunc={(e) => handleRemoveClick(index)}
                            array={state}
                            index={index}
                            showAdd={false}
                          />
                        </div>
                      </div>
                      {/* <!-- END: Card Header--> */}

                      {/* <!-- BEGIN: Card Body--> */}
                      <div className="p-2 grid grid-cols-12 gap-3 gap-y-3 ">
                        <div className="col-span-6 md:col-span-3 lg:col-span-2">
                          <Form.Label className="required">
                            Consignment date
                          </Form.Label>
                          <Form.Input
                            className="form-control"
                            type="date"
                            name="consignment_date"
                            id="consignment_date"
                            onChange={(event) => changeInput(event, index)}
                            value={item?.consignment_date}
                            required
                          />
                        </div>

                        <div className="col-span-6 md:col-span-3 lg:col-span-2">
                          <Form.Label className="">Client</Form.Label>
                          <Typeahead
                            clearButton
                            filterBy={["name", "contact_number"]}
                            id="client"
                            name="client"
                            onFocus={(event) => {
                              loadDefaultMetrics(event, "client");
                            }}
                            onChange={(selected) => {
                              changeTypeHeadInput(selected, index, "client");
                            }}
                            onInputChange={(text) => {
                              changeInputTypeaheadFields(text, "client");
                            }}
                            options={defaultState?.client}
                            labelKey={(option) => `${option?.name}`}
                            selected={formatTypeSearch(item?.client)}
                            placeholder="Search client..."
                            renderMenu={(results, menuProps) => (
                              <Menu {...menuProps}>
                                {localUser?.module_access?.business_party && (
                                  <TypeAhAddNewButton
                                    onClick={openClientMasterModal}
                                  ></TypeAhAddNewButton>
                                )}
                                {results.map((result, index) => (
                                  <MenuItem
                                    option={result}
                                    position={index}
                                    key={index}
                                  >
                                    <ClientsInList
                                      data={result}
                                      id={"client_" + index}
                                      display={["phone"]}
                                    />
                                  </MenuItem>
                                ))}
                              </Menu>
                            )}
                          />
                          {/* <ValidationError msg={validationError.client} /> */}
                        </div>

                        {/* <!-- BEGIN: Route -- > */}
                        <>
                          <div className="col-span-6 md:col-span-3 lg:col-span-2">
                            <Form.Label className="">Origin</Form.Label>
                            <Typeahead
                              clearButton
                              id="origin"
                              filterBy={["name"]}
                              onFocus={(event) => {
                                loadDefaultMetrics(event, "location");
                              }}
                              onChange={(selected) => {
                                changeTypeHeadInput(selected, index, "origin");
                              }}
                              onInputChange={(text) => {
                                changeInputTypeaheadFields(text, "location");
                              }}
                              options={defaultState?.location}
                              labelKey={(option) => `${option?.name}`}
                              selected={formatTypeSearch(item?.origin)}
                              placeholder="Search origin..."
                              renderMenu={(results, menuProps) => (
                                <Menu {...menuProps}>
                                  {localUser?.module_access?.master && (
                                    <TypeAhAddNewButton
                                      onClick={(e) =>
                                        openCityMasterModal(e, "origin")
                                      }
                                    ></TypeAhAddNewButton>
                                  )}
                                  {results.map((result, index) => (
                                    <MenuItem
                                      option={result}
                                      position={index}
                                      key={index}
                                    >
                                      {result?.name}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              )}
                            />
                            {/* <ValidationError msg={validationError.origin} /> */}
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-2">
                            <Form.Label className="">Destination</Form.Label>
                            <Typeahead
                              clearButton
                              id="destination"
                              filterBy={["name"]}
                              onFocus={(event) => {
                                loadDefaultMetrics(event, "location");
                              }}
                              onChange={(selected) => {
                                changeTypeHeadInput(
                                  selected,
                                  index,
                                  "destination"
                                );
                              }}
                              onInputChange={(text) => {
                                changeInputTypeaheadFields(text, "location");
                              }}
                              options={defaultState?.location}
                              labelKey={(option) => `${option?.name}`}
                              selected={formatTypeSearch(item?.destination)}
                              placeholder="Search destination..."
                              renderMenu={(results, menuProps) => (
                                <Menu {...menuProps}>
                                  {localUser?.module_access?.master && (
                                    <TypeAhAddNewButton
                                      onClick={(e) =>
                                        openCityMasterModal(e, "destination")
                                      }
                                    ></TypeAhAddNewButton>
                                  )}
                                  {results.map((result, index) => (
                                    <MenuItem
                                      option={result}
                                      position={index}
                                      key={index}
                                    >
                                      {result?.name}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              )}
                            />
                            {/* <ValidationError msg={validationError.destination} /> */}
                          </div>
                        </>
                        {/* <!-- BEGIN: Route -- > */}

                        {/* <!--BEGIN: Load Details --> */}
                        <>
                          <div className="col-span-12 md:col-span-3 lg:col-span-2">
                            <label htmlFor="material" className="form-label">
                              Load Description
                            </label>

                            <input
                              className="form-control"
                              aria-label="material"
                              name="material"
                              onChange={(event) => changeInput(event, index)}
                              value={item?.material}
                            />
                          </div>

                          <div className="col-span-12 md:col-span-3 lg:col-span-2">
                            <label
                              htmlFor="total_invoice_weight"
                              className="form-label"
                            >
                              Load Weight
                            </label>

                            <div className="input-group">
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                name="total_invoice_weight"
                                aria-label="Weight"
                                aria-describedby="total_invoice_weight"
                                onChange={(event) => changeInput(event, index)}
                                value={item?.total_invoice_weight}
                              />
                              <div
                                id="total_invoice_weight"
                                className="input-group-text w-16"
                                style={{ padding: 0 }}
                              >
                                <select
                                  className="w-full h-full"
                                  aria-label="total_invoice_weight_unit"
                                  name="total_invoice_weight_unit"
                                  onChange={(event) =>
                                    changeInput(event, index)
                                  }
                                  value={item?.total_invoice_weight_unit}
                                >
                                  <option value="kg">Kg</option>
                                  <option value="ton">MT</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </>
                        {/* <!--END: Load Details --> */}

                        <div className="col-span-12 md:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="vehicle_ownership_type"
                            className="form-label"
                          >
                            Vehicle Ownership
                          </label>

                          <select
                            className="form-select"
                            aria-label="vehicle_ownership_type"
                            name="vehicle_ownership_type"
                            onChange={(event) => changeInput(event, index)}
                            value={item?.vehicle_ownership_type}
                          >
                            <option value="own">Own</option>
                            <option value="supplier">Supplier</option>
                          </select>
                        </div>

                        {(item?.vehicle?.ownership_type === "supplier" ||
                          item?.vehicle_ownership_type === "supplier") && (
                          <div className="col-span-12 md:col-span-3 lg:col-span-2">
                            <Form.Label className="flex items-center">
                              Vehicle Supplier{" "}
                              {item?.supplier && (
                                <CallButton
                                  id={"call-button-supplier-" + index}
                                  phoneNumber={item?.supplier?.contact_number}
                                  tooltipText="Call Supplier"
                                  className="ml-2"
                                  size="sm"
                                >
                                  Call
                                </CallButton>
                              )}
                            </Form.Label>
                            <Typeahead
                              clearButton
                              filterBy={["name", "contact_number"]}
                              id="supplier"
                              name="supplier"
                              onFocus={(event) => {
                                loadDefaultMetrics(event, "supplier");
                              }}
                              onChange={(selected) => {
                                changeTypeHeadInput(
                                  selected,
                                  index,
                                  "supplier"
                                );
                              }}
                              onInputChange={(text) => {
                                changeInputTypeaheadFields(text, "supplier");
                              }}
                              options={defaultState?.supplier}
                              labelKey={(option) => `${option?.name}`}
                              selected={formatTypeSearch(item?.supplier)}
                              placeholder="Search..."
                              renderMenu={(results, menuProps) => (
                                <Menu {...menuProps}>
                                  {localUser?.module_access?.business_party && (
                                    <TypeAhAddNewButton
                                      onClick={openSupplierMasterModal}
                                    ></TypeAhAddNewButton>
                                  )}
                                  {results.map((result, index) => (
                                    <MenuItem
                                      option={result}
                                      position={index}
                                      key={index}
                                    >
                                      <SuppliersInList
                                        data={result}
                                        id={"vehicle_supplier" + index}
                                        display={["phone"]}
                                      />
                                    </MenuItem>
                                  ))}
                                </Menu>
                              )}
                            />
                            {/* <ValidationError msg={validationError.supplier} /> */}
                          </div>
                        )}

                        <div className="col-span-6 md:col-span-3 lg:col-span-2">
                          <Form.Label className="">Vehicle</Form.Label>
                          <Typeahead
                            clearButton
                            id="vehicle"
                            name="vehicle"
                            filterBy={[
                              "registration_number",
                              "vehicleType",
                              "ownership_type",
                            ]}
                            onFocus={(event) => {
                              loadDefaultMetrics(event, "vehicle");
                            }}
                            onChange={(selected) => {
                              changeTypeHeadInputVehicle(
                                selected,
                                index,
                                "vehicle"
                              );
                            }}
                            onInputChange={(text) => {
                              changeInputTypeaheadFields(text, "vehicle");
                            }}
                            options={defaultState?.vehicle}
                            labelKey={(option) =>
                              `${option?.registration_number}`
                            }
                            selected={formatTypeSearch(item?.vehicle)}
                            placeholder="Search vehicle..."
                            renderMenu={(results, menuProps) => (
                              <Menu {...menuProps}>
                                {localUser?.module_access?.master &&
                                  localUser?.access?.approve_master && (
                                    <TypeAhAddNewButton
                                      onClick={openVehicleMasterModal}
                                    ></TypeAhAddNewButton>
                                  )}

                                {results.map((result, index) => (
                                  <MenuItem
                                    option={result}
                                    position={index}
                                    key={index}
                                  >
                                    <TruckNumberDisplay
                                      data={result}
                                      id={"vehicle_" + result?._id}
                                    />
                                  </MenuItem>
                                ))}
                              </Menu>
                            )}
                          />
                          {/* <ValidationError msg={validationError.vehicle} /> */}
                        </div>

                        <div className="col-span-12 md:col-span-3 lg:col-span-2">
                          <Form.Label className="flex items-center">
                            Driver
                            {item?.driver && (
                              <CallButton
                                id={"call-button-driver-" + index}
                                phoneNumber={item?.driver?.contact_number}
                                tooltipText="Call Driver"
                                className="ml-2"
                                size="sm"
                              >
                                Call
                              </CallButton>
                            )}
                          </Form.Label>
                          <Typeahead
                            clearButton
                            filterBy={["name", "license_no", "contact_number"]}
                            id="driver"
                            onFocus={(event) => {
                              loadDefaultMetrics(event, "driver");
                            }}
                            onChange={(selected) => {
                              changeTypeHeadInputDriver(
                                selected,
                                index,
                                "driver"
                              );
                            }}
                            onInputChange={(text) => {
                              changeInputTypeaheadFields(text, "driver");
                            }}
                            labelKey={(option) => `${option?.name}`}
                            options={defaultState?.driver}
                            selected={formatTypeSearch(item?.driver)}
                            placeholder="Search driver..."
                            className="text-uppercase"
                            renderMenu={(results, menuProps) => (
                              <Menu {...menuProps}>
                                {localUser?.module_access?.master &&
                                  localUser?.access?.approve_master && (
                                    <TypeAhAddNewButton
                                      onClick={openDriverMasterModal}
                                    ></TypeAhAddNewButton>
                                  )}
                                {results.map((result, index) => (
                                  <MenuItem
                                    option={result}
                                    position={index}
                                    key={index}
                                  >
                                    <DriversInList
                                      id={"driver_" + index}
                                      data={result}
                                      display={[
                                        "phone",
                                        "license_no",
                                        "license_expiry",
                                      ]}
                                    />
                                  </MenuItem>
                                ))}
                              </Menu>
                            )}
                          />
                          {/* <ValidationError msg={validationError?.driver} /> */}
                        </div>

                        {/* <!-- BEGIN: Consignment Billing Type -- > */}
                        <>
                          <div className="col-span-12 md:col-span-3 lg:col-span-2">
                            <label
                              htmlFor="consignment_billing_unit"
                              className="form-label"
                            >
                              {item?.created_business_type === "broker"
                                ? "Supplier Billing Type"
                                : "Billing Type"}
                            </label>

                            <select
                              className="form-select"
                              aria-label="consignment_billing_unit"
                              name="consignment_billing_unit"
                              onChange={(event) =>
                                changeInputCharges(event, index)
                              }
                              value={item?.charges?.consignment_billing_unit}
                            >
                              <option value="fixed">Fixed</option>
                              <option value="per_ton">Per Ton</option>
                              <option value="per_kg">Per Kg</option>
                              <option value="per_km">Per Km</option>
                              <option value="per_trip">Per Trip</option>
                              <option value="per_day">Per Day</option>
                              <option value="per_hour">Per Hour</option>
                              <option value="per_litre">Per Litre</option>
                              <option value="per_bag">Per Bag</option>
                            </select>
                          </div>

                          {/* <!-- BEGIN: Billing Type Amount Fields-- > */}
                          {item?.charges?.consignment_billing_unit !== "" &&
                            item?.charges?.consignment_billing_unit !==
                              "fixed" && (
                              <>
                                <div className="col-span-6 md:col-span-3 lg:col-span-2">
                                  <label
                                    htmlFor="unit_price"
                                    className="form-label"
                                  >
                                    {
                                      fieldLabels?.unit_price[
                                        item?.charges?.consignment_billing_unit
                                      ]
                                    }
                                  </label>
                                  <CurrencyInput
                                    type="number"
                                    className="form-control"
                                    id="consignment_unit_price"
                                    name="consignment_unit_price"
                                    placeholder=""
                                    onChange={(event) =>
                                      changeInputSupplierUnitFields(
                                        event,
                                        index
                                      )
                                    }
                                    value={
                                      item?.charges?.consignment_unit_price
                                    }
                                  />
                                </div>

                                <div className="col-span-6 md:col-span-3 lg:col-span-2">
                                  <label
                                    htmlFor="consignment_total_units"
                                    className="form-label"
                                  >
                                    {
                                      fieldLabels?.total_units[
                                        item?.charges?.consignment_billing_unit
                                      ]
                                    }
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="consignment_total_units"
                                    name="consignment_total_units"
                                    placeholder=""
                                    onChange={(event) =>
                                      changeInputSupplierUnitFields(
                                        event,
                                        index
                                      )
                                    }
                                    value={
                                      item?.charges?.consignment_total_units
                                    }
                                  />
                                </div>
                              </>
                            )}
                          {/* <!-- END: Billing Type Amount Fields-- > */}
                          <div className="col-span-6 md:col-span-3 lg:col-span-2">
                            <label
                              htmlFor="consignment_freight"
                              className="form-label"
                            >
                              {item?.created_business_type === "broker"
                                ? "Supplier Freight Amount"
                                : "Freight Amount"}
                            </label>
                            <CurrencyInput
                              type="number"
                              className="form-control"
                              id="consignment_freight"
                              name="consignment_freight"
                              placeholder=""
                              onChange={(e) =>
                                changeInputFreightSupplier(e, index)
                              }
                              value={item?.charges?.consignment_freight}
                            />
                          </div>
                        </>
                        {/* <!-- END: Consignment Billing Type -- > */}

                        {/* <!-- BEGIN: Client Billing Type -- > */}
                        {item?.created_business_type === "broker" && (
                          <>
                            <div className="col-span-12 md:col-span-3 lg:col-span-2">
                              <label
                                htmlFor="client_billing_unit"
                                className="form-label"
                              >
                                Client Billing Type
                              </label>

                              <select
                                className="form-select"
                                aria-label="client_billing_unit"
                                name="client_billing_unit"
                                onChange={(event) =>
                                  changeInputCharges(event, index)
                                }
                                value={item?.charges?.client_billing_unit}
                              >
                                <option value="fixed">Fixed</option>
                                <option value="per_ton">Per Ton</option>
                                <option value="per_kg">Per Kg</option>
                                <option value="per_km">Per Km</option>
                                <option value="per_trip">Per Trip</option>
                                <option value="per_day">Per Day</option>
                                <option value="per_hour">Per Hour</option>
                                <option value="per_litre">Per Litre</option>
                                <option value="per_bag">Per Bag</option>
                              </select>
                            </div>

                            {/* <!-- BEGIN: Billing Type Amount Fields-- > */}
                            {item?.charges?.client_billing_unit !== "" &&
                              item?.charges?.client_billing_unit !==
                                "fixed" && (
                                <>
                                  <div className="col-span-6 md:col-span-3 lg:col-span-2">
                                    <label
                                      htmlFor="client_unit_price"
                                      className="form-label"
                                    >
                                      {
                                        fieldLabels?.unit_price[
                                          item?.charges?.client_billing_unit
                                        ]
                                      }
                                    </label>
                                    <CurrencyInput
                                      type="number"
                                      className="form-control"
                                      id="client_unit_price"
                                      name="client_unit_price"
                                      placeholder=""
                                      onChange={(event) =>
                                        changeInputClientUnitFields(
                                          event,
                                          index
                                        )
                                      }
                                      value={item?.charges?.client_unit_price}
                                    />
                                  </div>

                                  <div className="col-span-6 md:col-span-3 lg:col-span-2">
                                    <label
                                      htmlFor="client_total_units"
                                      className="form-label"
                                    >
                                      {
                                        fieldLabels?.total_units[
                                          item?.charges
                                            ?.consignment_billing_unit
                                        ]
                                      }
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="client_total_units"
                                      name="client_total_units"
                                      placeholder=""
                                      onChange={(event) =>
                                        changeInputClientUnitFields(
                                          event,
                                          index
                                        )
                                      }
                                      value={item?.charges?.client_total_units}
                                    />
                                  </div>
                                </>
                              )}
                            {/* <!-- END: Billing Type Amount Fields-- > */}
                            <div className="col-span-6 md:col-span-3 lg:col-span-2">
                              <label
                                htmlFor="client_freight"
                                className="form-label"
                              >
                                Freight Amount
                              </label>
                              <CurrencyInput
                                type="number"
                                className="form-control"
                                id="client_freight"
                                name="client_freight"
                                placeholder=""
                                onChange={(e) =>
                                  changeInputFreightClient(e, index)
                                }
                                value={item?.charges?.client_freight}
                              />
                            </div>
                          </>
                        )}
                        {/* <!-- END: Client Billing Type -- > */}
                      </div>
                      {/* <!-- END: Card Body--> */}
                    </div>
                    {/* <!-- END: Card --> */}
                  </>
                ))}
                <input
                  type="submit"
                  id="save_form"
                  name="save_form"
                  className="hidden"
                />
                <input
                  type="submit"
                  id="save_and_create_form"
                  name="save_and_create_form"
                  className="hidden"
                />
              </Form>
              {/* <!-- END: Form --> */}
            </Card.Body>
            {/* <!-- END: Card Body --> */}

            {/* <!-- BEGIN: Card Footer --> */}
            <Card.Footer>
              <div className="w-full flex items-center justify-center">
                <button
                  type="button"
                  id="consignment_cance_button"
                  className="btn btn-secondary mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <label
                  type="button"
                  className="btn btn-primary mr-2"
                  htmlFor="save_form"
                  name="save_form"
                >
                  Save Plan
                </label>

                <label
                  type="button"
                  className="btn btn-success"
                  htmlFor="save_and_create_form"
                  name="save_and_create_form"
                >
                  Save & Create New
                </label>
              </div>
            </Card.Footer>
            {/* <!-- END: Card Footer --> */}
          </Card>
          {/* <!-- END: Card --> */}

          {/* <!-- BEGIN: Saved Items --> */}
          {savedItems.length > 0 && (
            <div className="mt-5">
              <Card>
                <Card.Header>Recently Saved Consignments</Card.Header>
                <Card.Body>
                  <SavedConsignmentTable data={savedItems} />
                </Card.Body>
              </Card>
            </div>
          )}
          {/* <!-- END: Saved Items --> */}
        </Container.Body>
        {/*  <!-- END:  Body -->  */}
      </Container>
    );
  }
}
