import React, { Component } from "react";
// HTML Components

import Container from "../../components/container";
import Card from "../../components/card/custom";

import Error from "../../components/error/error";
import ProcessLoader from "../../components/preloader/processLoader";
import InLineLoader from "../../components/preloader/inLineLoader";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { matchData, JsonToFormData } from "../../functions/common";
import { storageServices } from "../../functions/storage";
import { formatTypeaheadCommon } from "../../functions/common";

// Models
import { Model } from "./model";
import ImageInput from "../../components/inputs/image";

const fileBaseURL = process.env.REACT_APP_FILE_URL;

const countryListFile = require("../../utils/country_state_list.json");
const country_state_list = countryListFile.countries;

export default class InitialSetup extends Component {
  constructor(props) {
    super(props);
    this.state = { ...Model };
    this.setDefaultCountry();
  }

  async componentDidMount() {
    var localUser = await storageServices.getCurrentUser();
    const customerId = localUser?.customer_id;

    //console.log("customerId:", customerId);

    this.loadDataById(customerId);
  }

  setDefaultCountry = async () => {
    const defaultCountry = "India";
    this.setState({ country: defaultCountry });

    let obj = await country_state_list.find(
      (o) => o.country === defaultCountry
    );

    if (typeof obj !== "undefined" && obj.states.length > 0) {
      this.setState({ stateList: obj.states });
    }
  };

  loadDataById = async (id) => {
    api.getService(`customer_accounts/${id}`).then(
      async (result) => {
        console.log("result:", result);
        const data = result.data;
        let matchedData = await matchData(this.state, data.data);
        let newData = await this.mapData({ ...matchedData });
        this.setState(matchedData);
        this.setState({ isLoaded: true });
      },
      (error) => {
        console.log("error", error);
        this.setState({ isLoaded: true });
        resp.ErrorHandler(error);
        this.setState({ error: true });
      }
    );
  };

  mapData = async (data) => {
    if (data.country !== "") {
      // Loading States
      let obj = await this.state.countryStateList.find(
        (o) => o.country === data.country
      );

      if (typeof obj !== "undefined" && obj.states.length > 0) {
        data.stateList = obj.states;
      }
    }
    return data;
  };

  reconstructLocalUserData = async (data) => {
    this.setState({ isLoaded: false });

    var localUser = await storageServices.getCurrentUser();

    // To local format
    localUser["customer_account"] = data;

    await storageServices.setCurrentUser(localUser);
    this.setState({ isLoaded: true });
  };

  navToHome = () => {
    // window.location.href = "/admin#/dashboard/operational";
    window.location.href = "/admin#/home";
  };

  handleNextPrev = (val) => {
    const { activeStep } = this.state;
    let updatedActiveStep = activeStep + val;
    updatedActiveStep =
      updatedActiveStep < 1 ? 1 : updatedActiveStep > 3 ? 3 : updatedActiveStep;
    this.setState({ activeStep: updatedActiveStep });
  };

  handleChange = (event) => {
    try {
      const { name, value } = event.target;
      this.setState({ [name]: value });
    } catch (error) {
      console.log(error);
    }
  };

  changeTypeHeadInput = async (selected, name) => {
    if (selected.length != 0) {
      let value = selected[0];
      this.setState({ [name]: value });
      if (name === "country") {
        // Loading States
        let obj = await this.state.countryStateList.find(
          (o) => o.country === value
        );
        if (typeof obj !== "undefined" && obj.states.length > 0) {
          this.setState({ stateList: obj.states });
        }
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  prepareData(data) {
    delete data._id;
    delete data.stateList;
    delete data.countryStateList;
    delete data.countryList;
    delete data.activeStep;
    delete data.isLoaded;
    delete data.loading;
    delete data.error;

    return data;
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    let query = await this.prepareData({ ...this.state });

    console.log("query:", query);

    let formData = await JsonToFormData(query);
    api
      .DataputService(`customer_accounts/${this.state?._id}`, formData)
      .then(async (response) => {
        console.log("response:", response);

        this.setState({ loading: false });
        resp.Success(response.data.message);
        const data = response.data;
        this.reconstructLocalUserData(data.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        resp.ErrorHandler(error);
      });
  };

  changeInputImage = (event) => {
    const { name, files } = event.target;
    if (files[0]) {
      this.setState({ [name]: files[0] });
    } else {
      this.setState({ [name]: "" });
    }
  };

  deleteInputImage = (event) => {
    const { name, files } = event.target;
    event.preventDefault();
    this.setState({ [name]: "" });
  };

  render() {
    const {
      name,
      business_type,
      email,
      phone,
      website,
      contact_person,
      logo,
      gst,
      pan_number,
      address_line_1,
      address_line_2,
      city,
      state,
      country,
      zip_code,

      stateList,
      countryStateList,
      countryList,

      loading,
      isLoaded,
      error,
    } = this.state;
    if (error) {
      return <Error />;
    } else {
      return (
        <Container>
          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title>My Organization</Container.Title>
          </Container.Header>
          {/* <!-- END: Header --> */}
          {/* <!-- BEGIN: Container Body --> */}
          <Container.Body>
            {/* <!-- BEGIN: Card --> */}
            <Card className="mt-5">
              {!isLoaded && <ProcessLoader />}
              {/* <!-- BEGIN: Card Body --> */}
              <Card.Body>
                {/* <!-- BEGIN: Form --> */}
                <form onSubmit={this.handleSubmit}>
                  <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 sm:col-span-3">
                      <label htmlFor="name" className="form-label required">
                        Organization Name
                      </label>
                      <input
                        id="name"
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Enter organization name"
                        value={name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-3">
                      <label htmlFor="business_type" className="form-label required">
                        Business Type
                      </label>
                      <select
                        id="business_type"
                        className="form-select"
                        name="business_type"
                        value={business_type}
                        onChange={this.handleChange}
                        required
                      >
                        <option value=""></option>
                        <option value="transporter">Transportation</option>
                        <option value="broker">Brokerage</option>
                      </select>
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-3">
                      <label htmlFor="website" className="form-label">
                        Website
                      </label>
                      <input
                        id="website"
                        className="form-control"
                        type="text"
                        name="website"
                        placeholder="Enter website"
                        value={website}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-3">
                      <label htmlFor="email" className="form-label required">
                        Primary Email
                      </label>
                      <input
                        id="email"
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    {/* <!--BEGIN: Address -- > */}
                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="address_line_1" className="form-label required">
                        Address Line 1
                      </label>
                      <input
                        id="address_line_1"
                        className="form-control"
                        type="text"
                        name="address_line_1"
                        placeholder=""
                        value={address_line_1}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="address_line_2" className="form-label">
                        Address Line 2
                      </label>
                      <input
                        id="address_line_2"
                        className="form-control"
                        type="text"
                        name="address_line_2"
                        placeholder=""
                        value={address_line_2}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="city" className="form-label required">
                        City
                      </label>
                      <input
                        id="city"
                        className="form-control"
                        type="text"
                        name="city"
                        placeholder=""
                        value={city}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="state" className="form-label required">
                        State
                      </label>
                      <Typeahead
                        id="state"
                        onChange={(selected) => {
                          this.changeTypeHeadInput(selected, "state");
                        }}
                        options={stateList}
                        labelKey={(option) => `${option}`}
                        selected={formatTypeaheadCommon(state)}
                        inputProps={{ required: true }}
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="country" className="form-label required">
                        Country
                      </label>
                      <Typeahead
                        id="country"
                        onChange={(selected) => {
                          this.changeTypeHeadInput(selected, "country");
                        }}
                        options={countryList}
                        labelKey={(option) => `${option}`}
                        selected={formatTypeaheadCommon(country)}
                        inputProps={{ required: true }}
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="zip_code" className="form-label required">
                        Pin code
                      </label>
                      <input
                        id="zip_code"
                        className="form-control"
                        type="number"
                        name="zip_code"
                        placeholder=""
                        value={zip_code}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    {/* <!--END: Address -- > */}

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="phone" className="form-label required">
                        Phone
                      </label>
                      <input
                        id="phone"
                        className="form-control"
                        type="tel"
                        name="phone"
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="contact_person" className="form-label">
                        Contact person
                      </label>
                      <input
                        id="contact_person"
                        className="form-control"
                        type="text"
                        name="contact_person"
                        placeholder="Enter contact person"
                        value={contact_person}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="gst" className="form-label">
                        GST Number
                      </label>
                      <input
                        id="gst"
                        className="form-control"
                        type="text"
                        name="gst"
                        placeholder="Enter GST Number"
                        value={gst}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="pan_number" className="form-label">
                        PAN Number
                      </label>
                      <input
                        id="pan_number"
                        className="form-control"
                        type="text"
                        name="pan_number"
                        placeholder="Enter PAN Number"
                        value={pan_number}
                        onChange={this.handleChange}
                        
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-4">
                      <label htmlFor="logo" className="form-label">
                        Logo
                      </label>
                      <ImageInput
                        state={logo}
                        name="logo"
                        error_msg={""}
                        onDelete={this.deleteInputImage}
                        onChange={this.changeInputImage}
                        baseURL={fileBaseURL}
                      />
                    </div>
                  </div>
                  <input
                    type="submit"
                    id="save_my_organization"
                    className="hidden"
                  />
                </form>
                {/* <!-- END: Form --> */}
              </Card.Body>
              {/* <!-- END: Card Body --> */}

              {/* <!-- BEGIN: Card Footer --> */}

              <Card.Footer className="justify-end">
                <label
                  type="button"
                  htmlFor="save_my_organization"
                  className="btn btn-primary w-24 ml-2"
                >
                  Save&nbsp; {loading && <InLineLoader />}
                </label>
              </Card.Footer>
              {/* <!-- END: Card Footer --> */}
            </Card>
            {/* <!-- END: Card --> */}
          </Container.Body>
          {/* <!-- END: Container Body --> */}
        </Container>
      );
    }
  }
}
