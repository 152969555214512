import React, { useState, useEffect, useNavigate } from "react";
import { formatCurrency, toFloat } from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import { storageServices } from "../../functions/storage";

export default function Template(props) {
  const Title = "Report";
  const tableRef = React.createRef();
  const { data, token, filter } = props;
  const [state, setState] = useState({
    own_vehicle_profit: 0,
    supplier_vehicle_profit: 0,
    total_income: 0,
    total_expense: 0,
    total_profit: 0,
    docs: [],
  });
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    // const getUser = async () => {
    //   // Getting Local User Data
    //   let localUserData = await storageServices.getCurrentUser();
    //   setLocalUser(localUserData);
    // };
    // getUser();

    loadData(data);
  }, [props]);

  async function loadData(data) {
    setIsProcessing(true);
    let mappedData = await mapData(data);
    let tableData = await mapTableData(mappedData?.docs);
    setTableData(tableData);
    setIsProcessing(false);
  }

  function mapData(data) {
    // let ownVehicleProfit = 0;
    // let supplierVehicleProfit = 0;
    // for (var i in data) {
    //   let profit =
    //     toFloat(data[i]?.client_total) - toFloat(data[i]?.consignment_total);

    //   data[i]["profit"] = profit;

    //   if (data[i]["vehicle_ownership_type"] === "own") {
    //     ownVehicleProfit += profit;
    //   }
    //   if (data[i]["vehicle_ownership_type"] === "supplier") {
    //     supplierVehicleProfit += profit;
    //   }
    // }

    // let prevState = { ...state };
    // prevState.own_vehicle_profit = ownVehicleProfit;
    // prevState.supplier_vehicle_profit = supplierVehicleProfit;
    setState(data);
    return data;
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Vehicle",
        field: "registration_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vehicle Type",
        field: "vehicle_type",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Consignments Count",
        field: "consignments_count",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Income",
        field: "total_income",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Expense",
        field: "total_expense",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Profit",
        field: "profit",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(row) {
    //navigate("/reports#/truck_revenue_report_by_vehicle", row);
    //    history.push(`/truck_revenue_report_by_vehicle?token=${token}`, row);

    const rowData = row?._row?.data;

    let filters = props?.filter;
    console.log("filters:", filters);
    const url = `/reports#/truck_revenue_report_by_vehicle?vehicle=${rowData?._id}&start_date=${filters?.start_date}&end_date=${filters?.end_date}&token=${token}`;
    window.location.href = url;

    // props.history.push({
    //   pathname: '/register',
    //   state: row
    //  });

    // router.push({
    //   pathname: url,
    //   query: { row },
    // });
  }

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Sum Values --> */}
        <div id="sum_values" className="w-full flex justify-end space-x-4">
          {/* <!-- BEGIN: Total Income --> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-gray-200 w-1/3">
            <div class="flex-none image-fit rounded-md overflow-hidden">
              {/* <OwnTruckIcon width="36" height="36" className="inline" /> */}
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Total Income</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.total_income)}
            </div>
          </div>
          {/* <!-- END: Total Income --> */}

          {/* <!-- BEGIN: Total Expense--> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-gray-200 w-1/3">
            <div class="flex-none image-fit rounded-md overflow-hidden">
              {/* <SupplierTruckIcon width="42" height="42" className="inline" /> */}
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Total Expense</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.total_expense)}
            </div>
          </div>
          {/* <!-- END: Total Expense --> */}

          {/* <!-- BEGIN: Total Expense--> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-gray-200 w-1/3">
            <div class="flex-none image-fit rounded-md overflow-hidden">
              {/* <SupplierTruckIcon width="42" height="42" className="inline" /> */}
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Profit</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.total_profit)}
            </div>
          </div>
          {/* <!-- END: Total Expense --> */}
        </div>

        {/* <!-- END: Sum Values --> */}

        {/* <!-- BEGIN: Data Table --> */}
        <ReportsDataTable
          title={Title}
          header={tableData.header}
          content={tableData.content}
          onRowClick={(e, row) => {
            viewRecord(row);
          }}
          downloadFileName={Title}
          tableRef={tableRef}

          // groupBy="vehicle_ownership_type"
        />
        {/* <!-- END: Data Table --> */}
      </div>
    </>
  );
}
